// @flow
import { S3 } from "aws-sdk";

export type Credentials = {
    accessKey: string,
    secretKey: string,
    sessionToken: string,
    region: string,
    bucket: string,
    key: string,
};
export default (credentials: Credentials): * =>
    new S3({
        useAccelerateEndpoint: true,
        accessKeyId: credentials.accessKey,
        secretAccessKey: credentials.secretKey,
        sessionToken: credentials.sessionToken,
        region: credentials.region,
        key: credentials.key,
        httpOptions: {
            timeout: 60 * 60 * 1000, // 1 hour
        },
    });
