//@flow

import type { Node } from "react";
import React from "react";
import { Col, Input } from "reactstrap";

type Props = {
    set: {
        [string]: any,
    },
    editMode: boolean,
    error?: string,
    onUpdateTitle: string => void,
};

const TitleInput = ({
    set,
    editMode,
    onUpdateTitle: handleUpdateTitle,
    error,
}: Props): Node => (
    <Col>
        {editMode ? (
            <Input
                onChange={event => handleUpdateTitle(event.target.value)}
                value={set.materialSetName}
            />
        ) : (
            set.materialSetName
        )}
        {error && <small className="text-danger">{error}</small>}
    </Col>
);

export default TitleInput;
