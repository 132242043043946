//@flow

import { getBadge } from "../../../utils";
import { dateFormatter } from "../../../utils/formatter";
import { type NoiseMaskDataTableElement } from "../types/noisemask-data-table-element.d";

export default (
    results: NoiseMaskDataTableElement[],
): NoiseMaskDataTableElement[] =>
    results.map(result => ({
        ...result,
        published: getBadge.publish(!!result.published),
        modificationTime: dateFormatter(result.modificationTime),
    }));
