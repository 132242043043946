// @flow
import {
    ArrowLeftDown,
    ArrowRightUp,
    Download,
} from "@brutextiles/web-component-library";

import type { Action } from "./types/action.d";

export const archiveFilters = [
    { label: "Active", value: { field: "archived", value: "false" } },
    { label: "Archived", value: { field: "archived", value: "true" } },
    { label: "All", value: { field: "all", value: "true" } },
];

export const downloadAction = (canDownload: boolean): Action => ({
    label: "Download",
    action: "download",
    icon: Download,
    enable: {
        condition: selected => selected?.length === 1 && canDownload,
    },
});

export const publishAction = (canPublish: boolean): Action => ({
    label: "Publish",
    action: "publish",
    icon: ArrowRightUp,
    enable: {
        condition: selected =>
            canPublish &&
            selected?.length === 1 &&
            selected[0].published?.props.children === "unpublished",
    },
});

export const unpublishAction = (canPublish: boolean): Action => ({
    label: "Unpublish",
    action: "unpublish",
    icon: ArrowLeftDown,
    enable: {
        condition: selected =>
            canPublish &&
            selected?.length === 1 &&
            selected[0].published?.props.children === "published",
    },
});

export default (
    canRead: boolean,
    canManage: boolean,
    canPublish: boolean,
    canDownload: boolean,
): Action[] => [
    downloadAction(canDownload),
    publishAction(canPublish),
    unpublishAction(canPublish),
];
