// @flow

import React, { Fragment } from "react";
import { UncontrolledTooltip } from "reactstrap";

type Props = {
    content: any,
    context: string,
    maxLength?: number,
    index: string,
    formatter?: (value: any) => string,
};

const MAX_TEXT_LENGTH = 16;

const apply = (formatter?: (value: any) => string, content: any) => {
    return formatter ? formatter(content) : content;
};

const TableCell = ({ formatter, content, index, maxLength }: Props): any => {
    const formattedContent = apply(formatter, content);

    if (formattedContent?.length > (maxLength || MAX_TEXT_LENGTH)) {
        return (
            <Fragment>
                <UncontrolledTooltip placement="top" target={`tt_${index}`}>
                    {formattedContent}
                </UncontrolledTooltip>
                <span id={`tt_${index}`}>
                    {formattedContent.slice(0, maxLength || MAX_TEXT_LENGTH) +
                        "..."}
                </span>
            </Fragment>
        );
    }

    if (typeof content === "number") {
        return <Fragment>{formattedContent}</Fragment>;
    }

    return <Fragment>{formattedContent || ""}</Fragment>;
};

export default TableCell;
