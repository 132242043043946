//@flow

import { Datatable, Icon, Plus } from "@brutextiles/web-component-library";
import type { Node } from "react";
import React from "react";
import { Button, Collapse } from "reactstrap";

import { SetType } from "../../../../hooks/use-material-sets";
import type { PageInfo } from "../../../../hooks/use-table-data";
import type { Actions } from "../../index";
import settings from "../../settings/material-set-table-settings";
import type { MaterialVersion } from "../../types/material-version.d";
import MaterialSetTableBody from "../MaterialSetTableBody";
import QueryInput from "../QueryInput";

type Props = {
    set: {
        [string]: any,
    },
    open: boolean,
    order: number,
    editMode: boolean,
    queryTitle?: string,
    actions: Actions[],
    onAddItem: ({
        [string]: any,
    }) => void,
    onUpdateQuery?: string => void,
    tableDataLoading: boolean,
    suggestionsLoading: boolean,
    changeSorting: (fields: { [field: string]: string }) => void,
    changePageInfo: (pageInfo: PageInfo) => void,
    changePage?: (page: number) => void,
    suggestions?: {
        [string]: any,
    },
    setFilterQuery: string => void,
    search: () => void,
    setFilters: (filters: Array<{ [string]: string }>) => void,
    tableData: any,
    page: number,
    addedMaterials: {
        [string]: any,
    }[],
    readOnly?: boolean,
    onAction: (action: string, row: MaterialVersion) => void,
    onChangeRotation: (materialVersionId: string, rotation: number) => void,
    onQuery: string => void,
    errors?: {
        [string]: any,
    }[],
};

const MaterialSetBody = ({
    set,
    open,
    order,
    editMode,
    queryTitle,
    onUpdateQuery: handleUpdateQuery,
    tableDataLoading,
    suggestionsLoading,
    changeSorting: handleChangeSorting,
    changePageInfo: handleChangePageInfo,
    changePage: handleChangePage,
    setFilterQuery: handleSetFilterQuery,
    search: handleSearch,
    setFilters: handleSetFilters,
    suggestions,
    tableData,
    page,
    addedMaterials,
    onAction: handleAction,
    onChangeRotation: handleChangeRotation,
    readOnly,
    onQuery: handleQuery,
    onAddItem: handleAddItem,
    actions,
}: Props): Node => (
    <Collapse isOpen={open}>
        <QueryInput
            set={set}
            editMode={editMode}
            queryTitle={queryTitle}
            onUpdateQuery={handleUpdateQuery}
        />
        <Datatable
            settings={settings}
            dataIsLoading={tableDataLoading}
            onMultiSortChange={handleChangeSorting}
            onPageChange={handleChangePage}
            onPageInfoChange={handleChangePageInfo}
            searchResults={suggestions?.result}
            onSearchChange={handleSetFilterQuery}
            onRefresh={handleSearch}
            onFilterChange={handleSetFilters}
            searchIsLoading={suggestionsLoading}
            totalResults={tableData?.totalResults}
            onQuery={handleQuery}
            page={page}
            disableRowSelect
            multiSort
        >
            <MaterialSetTableBody
                setIndex={order}
                setType={set.type}
                removedIds={set.removedIds}
                addedIds={set.addedIds}
                changedVersionIds={set.changedVersionIds}
                changedRotations={set.changedRotations}
                addedMaterials={addedMaterials}
                settings={settings}
                loading={tableDataLoading}
                data={tableData?.results || []}
                editMode={editMode && set.type === SetType.SELECTION}
                actions={actions}
                onAction={handleAction}
                onChangeRotation={handleChangeRotation}
                readOnly={readOnly}
            />
        </Datatable>
        {editMode && set.type === SetType.SELECTION && (
            <Button
                className={`w-100 text-secondary py-2`}
                color="light-grey"
                onClick={() => handleAddItem(set)}
            >
                <Icon icon={Plus} className="mr-2" variant="secondary" />
                Add items
            </Button>
        )}
    </Collapse>
);

export default MaterialSetBody;
