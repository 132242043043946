import { useContext, useEffect } from "react";

import { ErrorHandlingContext } from "../providers/error-handling-provider";

export default function useErrorHandlingWithForm(formKey, dependencies = []) {
    const { putFormMessages, clearErrorMessages } =
        useContext(ErrorHandlingContext);
    useEffect(() => {
        if (dependencies && dependencies.length > 0) {
            putFormMessages(formKey, dependencies);
        } else {
            clearErrorMessages(formKey);
        }
    }, [dependencies]);
}
