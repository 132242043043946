// @flow
import React, { type Node, createContext, useState } from "react";

// $FlowFixMe
export const FormContext: React$Context<{|
    dirty: boolean,
    setDirty: (dirty: boolean) => void,
|}> = createContext({
    dirty: false,
    setDirty: () => {},
});

const FormProvider = ({ children }: *): Node => {
    const [dirty, setDirty] = useState(false);

    return (
        <FormContext.Provider
            value={{
                dirty,
                setDirty,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
