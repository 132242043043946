//@flow

import { getBadge } from "../../../utils";
import { dateFormatter } from "../../../utils/formatter";
import { type ProjectDataTableElement } from "../types/project-data-table-element.d";

export default (
    results: ProjectDataTableElement[],
): ProjectDataTableElement[] =>
    results.map(result => ({
        ...result,
        modificationTime: dateFormatter(result.modificationTime),
        projectCost:
            Number.parseFloat(result.projectCost || "0").toFixed(2) + "$",
        status: getBadge.publish(
            !result.archived,
            result.archived ? "archived" : "active",
        ),
    }));
