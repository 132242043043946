// @flow
import React, { type Node, createContext, useCallback } from "react";
import { useImmer } from "use-immer";

import type { ErrorData } from "../components/ErrorHandling/types/error-handling-response.d";

// $FlowFixMe
export const ErrorHandlingContext: React$Context<{|
    putErrorMessages: (key: string, data: ErrorData) => any,
    putFormMessages: (
        key: string,
        messages: { [field: string]: string },
    ) => any,
    errorData: { [key: string]: ErrorData },
    clearErrorMessages: (key: string) => any,
    clearAllMessages: () => any,
|}> = createContext({
    putErrorMessages: () => {},
    putFormMessages: () => {},
    errorData: {},
    clearErrorMessages: () => {},
    clearAllMessages: () => {},
});

const ErrorHandlingProvider = ({ children }: *): Node => {
    const [errorData, setErrorData] = useImmer({});

    const contextValue = {
        errorData,
        putErrorMessages: useCallback(
            (key, data) =>
                setErrorData(draft => {
                    draft[key] = data;
                    return draft;
                }),
            [setErrorData],
        ),
        putFormMessages: useCallback(
            (key, data) =>
                setErrorData(draft => {
                    draft[key] = {
                        allErrors: Object.entries(data).map(
                            (entry: [string, any]) => {
                                return {
                                    field: entry[0],
                                    message: entry[1],
                                };
                            },
                        ),
                    };
                    return draft;
                }),
            [setErrorData],
        ),
        clearErrorMessages: useCallback(
            key =>
                setErrorData(draft => {
                    if (draft[key]) {
                        delete draft[key];
                    }
                    return draft;
                }),
            [setErrorData],
        ),
        clearAllMessages: useCallback(() =>
            setErrorData(() => {
                return {};
            }),
        ),
    };

    return (
        <ErrorHandlingContext.Provider value={contextValue}>
            {children}
        </ErrorHandlingContext.Provider>
    );
};

export default ErrorHandlingProvider;
