// @flow

import {
    Button,
    Card,
    DetailsForm,
    Heading,
    LoadIndicator,
    Spinner,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Button as Btn, Container } from "reactstrap";
import * as Yup from "yup";

import { ErrorHandling } from "../../components";
import { useErrorHandlingWithForm, useUpdate } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import sharedStyle from "../../styles/shared.module.scss";
import detailSettings from "./detail-settings";

type Props = {
    readOnly?: boolean,
    companyId: string,
};

const UpdateCompany = ({ companyId, readOnly = false }: Props): Node => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const [{ loading, data, error }, load] = useAxios(
        `/ams-api/company/${companyId}`,
        {
            useCache: false,
        },
    );

    const [{ updateLoading }, update] = useUpdate({
        overviewUrl: "/companies",
    });

    const formik = useFormik({
        validationSchema: Yup.object({
            companyName: Yup.string().required(
                "Company name is a required field",
            ),
            description: Yup.string().required(
                "Description is a required field",
            ),
        }),
        onSubmit: () =>
            update({
                url: `/ams-api/company/${formik.values["companyId"]}`,
                data: {
                    companyName: formik.values["companyName"],
                    description: formik.values["description"],
                },
            }),
    });

    const { setDirty } = useContext(FormContext);
    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm("update-company-form", formik.errors);

    useEffect(() => {
        if (data) {
            /* Workaround for not being able to async set initial values. Makes sure formik is not dirty from the get go */
            formik.resetForm({ values: data });
        }
    }, [data]);

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/companies")}
            >
                {readOnly ? "Back" : "Cancel"}
            </Button>
            {!readOnly && (
                <Button
                    onClick={() => {
                        setShouldValidateOnChange();
                        formik.handleSubmit();
                    }}
                    disabled={!formik.dirty || !formik.isValid}
                >
                    {updateLoading ? <Spinner size="sm" /> : "Update"}
                </Button>
            )}
        </Fragment>
    );

    return (
        <Container fluid className={sharedStyle.wizard}>
            <Heading
                title={readOnly ? "Company " + companyId : "Update Company"}
                rightContent={actionButtons}
            />
            <ErrorHandling />
            <Heading title={"Company details"} level={5} />
            <Card>
                {loading ? (
                    <LoadIndicator cols={2} rows={3} />
                ) : error ? (
                    <span className="text-danger">
                        Error while retrieving the data
                        <div>
                            <Btn onClick={load} className="mt-2">
                                Try loading data again
                            </Btn>
                        </div>
                    </span>
                ) : (
                    <DetailsForm
                        settings={detailSettings(readOnly)}
                        data={formik.values}
                        onChange={data =>
                            formik.setFieldValue(
                                data.key,
                                data.value,
                                shouldValidateOnChange,
                            )
                        }
                        errors={formik.errors}
                    />
                )}
            </Card>
        </Container>
    );
};

export default UpdateCompany;
