// @flow
import { DetailsForm, Modal } from "@brutextiles/web-component-library";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, { type Node, useContext, useEffect, useState } from "react";

import { ErrorHandling } from "../../../../components";
import { useErrorHandlingWithForm, useSettings } from "../../../../hooks";
import { FormContext } from "../../../../providers/form-provider";
import createFormSettings from "../../settings/create-form";
import validationSettings from "../../settings/validations";

const CREATE_PROJECT_URL = "/ams-api/project";

const formKey = "create-project-form";

type Props = {
    show: boolean,
    toggle: () => void,
    createLoading: boolean,
    create: any => Promise<any>,
    usersOptions: { value: string, label: string }[],
    companiesOptions: { value: string, label: string }[],
};

const CreateProject = (props: Props): Node => {
    const {
        show,
        toggle,
        usersOptions,
        companiesOptions,
        createLoading,
        create,
    } = props;
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const { settings, updateSelectOptions } = useSettings(createFormSettings());

    useEffect(() => {
        updateSelectOptions("company", companiesOptions);
    }, [companiesOptions]);

    useEffect(() => {
        updateSelectOptions("projectManager", usersOptions);
    }, [usersOptions]);

    const formik = useFormik({
        ...validationSettings(),
        enableReinitialize: true,
        onSubmit: () => {
            !createLoading &&
                create({
                    url: CREATE_PROJECT_URL,
                    data: {
                        companyId: formik.values.company,
                        projectName: formik.values.projectName,
                        projectDescription: formik.values.description,
                        projectManager: formik.values.projectManager,
                        publishToAprimo: formik.values.publishToAprimo,
                        isQcProject: formik.values.isQcProject,
                        costCenter: formik.values.costCenter,
                        renderFilenameTemplate:
                            formik.values.renderFilenameTemplate,
                        priority: formik.values.priority,
                    },
                }).then(response => {
                    const projectId = response?.data?.projectId;

                    if (projectId) {
                        toggle();
                        navigate(`/projects/update/${projectId}`);
                    }
                    return response;
                });
        },
    });

    const { setDirty } = useContext(FormContext);
    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm(formKey, formik.errors);

    const submit = () => {
        setShouldValidateOnChange(true);
        formik.handleSubmit();
    };

    return (
        <>
            {show && (
                <Modal
                    size={"lg"}
                    title={"Create Project"}
                    open={show}
                    onCancel={() => {
                        toggle();
                        formik.resetForm();
                    }}
                    actions={[
                        {
                            type: "primary",
                            label: "create",
                            action: submit,
                            disabled: createLoading,
                        },
                        {
                            type: "secondary",
                            label: "cancel",
                            action: () => {
                                toggle();
                                formik.resetForm();
                            },
                        },
                    ]}
                    fullWidthActions
                >
                    <ErrorHandling showOnly={[CREATE_PROJECT_URL, formKey]} />
                    <DetailsForm
                        settings={settings}
                        data={formik.values}
                        onChange={data =>
                            formik.setFieldValue(
                                data.key,
                                data.value,
                                shouldValidateOnChange,
                            )
                        }
                        errors={formik.errors}
                    />
                </Modal>
            )}
        </>
    );
};

export default CreateProject;
