//@flow

import type { Node } from "react";
import React, { Fragment } from "react";
import { Input } from "reactstrap";

import { SetType } from "../../../../hooks/use-material-sets";
import style from "./style.module.scss";

type Props = {
    set: {
        [string]: any,
    },
    editMode: boolean,
    queryTitle?: string,
    onUpdateQuery?: string => void,
};

const QueryInput = ({
    set,
    editMode,
    queryTitle,
    onUpdateQuery: handleUpdateQuery,
}: Props): Node => (
    <Fragment>
        {set.type === SetType.SUBSCRIPTION && editMode && (
            <div className={style.queryArea}>
                <div className={style.entry}>{queryTitle || "Query"}</div>
                <Input
                    className={style.query}
                    value={set.query}
                    type="textarea"
                    onChange={({ target: { value } }) => {
                        handleUpdateQuery && handleUpdateQuery(value);
                    }}
                />
            </div>
        )}
        {set.type === SetType.SUBSCRIPTION && !editMode && (
            <div className={style.queryArea}>
                <div className={style.entry}>{queryTitle || "Query"}</div>
                <div className={style.entry}>{set.query}</div>
            </div>
        )}
    </Fragment>
);

export default QueryInput;
