//@flow
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

type Props = {
    data: { [string]: string | {} }[],
    title: string,
    onSave: () => Promise<void>,
    nameField?: string,
};

const useValidation = ({
    title,
    data,
    onSave: handleSave,
    nameField,
}: Props): * => {
    const formik = useFormik({
        initialValues: {
            title: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is a required field"),
        }),
        onSubmit: handleSave,
    });

    const [validateOnChange, setValidateOnChange] = useState(false);

    const resetForm = () => {
        data.forEach((element, index) => {
            if (nameField) {
                const fieldName = `${nameField}_${index}`;
                formik.registerField(fieldName, {
                    validate: value => {
                        if (!value) {
                            return `Name field is required`;
                        }
                        if (
                            data.filter(
                                row => row[nameField] === element[nameField],
                            ).length > 1
                        ) {
                            return `Name field must be unique`;
                        }
                        return null;
                    },
                });
                formik.setFieldValue(fieldName, element[nameField], false);
            }
        });
    };

    useEffect(() => {
        if (data?.length) {
            resetForm();
        }
    }, [data]);

    useEffect(() => {
        formik.setFieldValue("title", title, validateOnChange);
    }, [title]);

    useEffect(() => {
        if (validateOnChange) {
            formik.validateForm();
        }
    }, [formik.values, validateOnChange]);

    const unregisterFields = (): void => {
        Object.keys(formik.values).forEach(field => {
            if (field !== "title") {
                formik.unregisterField(field);
                delete formik.values[field];
                delete formik.errors[field];
            }
        });
    };

    const submit = () => {
        setValidateOnChange(true);
        formik.handleSubmit();
    };

    return [{ formik }, submit, unregisterFields, setValidateOnChange];
};

export default useValidation;
