// @flow

import useAxios from "axios-hooks";
import React, { type Node, useEffect, useState } from "react";

import { SceneEdit } from "..";
import { Permissions } from "../../api/permissions";
import { usePermissions, useUpdate } from "../../hooks";
import formSettings from "./settings/form-settings";
import validationSettings from "./settings/validation-settings";

type Props = {
    sceneId: string,
};

const UpdateScene = ({ sceneId }: Props): Node => {
    const [canManage] = usePermissions([Permissions.SCENE_MANAGE]);

    const [sceneData, setSceneData] = useState();
    const [{ loading, data }] = useAxios(`/ams-api/scene/${sceneId}/latest`, {
        useCache: false,
    });
    const [{ updateLoading }, update] = useUpdate({
        overviewUrl: "/scenes",
    });

    const submit = (
        values: { [string]: string },
        frameDefinitions: { [string]: string },
        componentDefinitions: [],
        s3FileUrl: string,
    ) => {
        const commonFields = {
            comment: values["comment"],
            sceneType: "VRSCENE4MAX",
            materialCount: 1,
            companyId: values["companyId"],
            sceneName: values["sceneName"],
            sceneDescription: {
                assetType: values["assetType"],
                roomType: values["roomType"],
                floorType: values["floorType"],
                wallColour: values["wallColour"],
                lightSetting: values["lightSetting"],
            },
            frameDefinitions,
            componentDefinitions,
        };
        if (s3FileUrl) {
            update({
                url: `/ams-api/scene/${sceneId}`,
                data: {
                    ...commonFields,
                    s3FileUrl: s3FileUrl,
                },
            });
        } else {
            update({
                url: `/ams-api/scene/${sceneId}/metadata`,
                data: {
                    ...commonFields,
                },
            });
        }
    };

    useEffect(() => {
        if (data) {
            setSceneData({
                companyId: data.companyId,
                floorType: data.sceneDescription?.floorType,
                sceneName: data.sceneName,
                wallColour: data.sceneDescription?.wallColour,
                assetType: data.sceneDescription?.assetType,
                lightSetting: data.sceneDescription?.lightSetting,
                roomType: data.sceneDescription?.roomType,
                componentDefinitions:
                    data.componentDefinitions &&
                    Object.values(data.componentDefinitions),
                frames:
                    data.frameDefinitions &&
                    Object.values(data.frameDefinitions),
                availableCompositingTemplateFilePaths:
                    data.availableCompositingTemplateFilePaths &&
                    Object.values(data.availableCompositingTemplateFilePaths),
            });
        }
    }, [data]);

    return (
        <SceneEdit
            title={
                canManage
                    ? "Update Scene"
                    : `Scene ${
                          sceneData?.sceneName ? sceneData?.sceneName : ""
                      }`
            }
            type="Update"
            readOnly={!canManage}
            onSubmit={submit}
            submitLoading={updateLoading}
            dataLoading={loading}
            validationSettings={validationSettings}
            data={sceneData}
            formSettings={formSettings(!canManage)}
        />
    );
};

export default UpdateScene;
