//@flow

import { dateFormatter } from "../../../utils/formatter";
import { type CompanyDataTableElement } from "../types/company-data-table-element.d";

export default (
    results: CompanyDataTableElement[],
): CompanyDataTableElement[] =>
    results.map(result => ({
        ...result,
        modificationTime: dateFormatter(result.modificationTime),
    }));
