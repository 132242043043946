// @flow

import React, { type Node, Fragment } from "react";
import { Helmet } from "react-helmet";

import { Permissions } from "../../api/permissions";
import { ErrorHandling } from "../../components";
import { usePermissions } from "../../hooks";
import OverviewTable from "../../templates/OverviewTable";
import defaultActions from "../../templates/OverviewTable/actions";
import tableSettings from "./table-settings";
import { mapResults } from "./utils";

const NoiseMaskOverview = (): Node => {
    const [canManage, canRead, canDownload, canPublish] = usePermissions([
        Permissions.NOISEMASK_MANAGE,
        Permissions.NOISEMASK_READ,
        Permissions.NOISEMASK_DOWNLOAD,
        Permissions.NOISEMASK_PUBLISH,
    ]);

    const props = {
        title: "Noise Mask Overview",
        apiBase: "dap-search-service/noisemask",
        actionApiBase: "ams-api/noisemask",
        canManage,
        createPageLink: "/noise-masks/create",
        createButtonLabel: "create noise mask",
        tableSettings,
        mapResults,
        actions: defaultActions(canRead, canManage, canPublish, canDownload),
        identifiers: ["companyId", "designId"],
        customFilter: { latestVersion: "true" },
        updateLinkBase: "noise-masks",
        refresh: true,
    };

    return (
        <Fragment>
            <Helmet>
                <title>Twinbru Engine - Noise Masks</title>
            </Helmet>
            <ErrorHandling />
            {canRead && <OverviewTable {...props} />}
        </Fragment>
    );
};

export default NoiseMaskOverview;
