export default [
    {
        key: "componentDescription",
        label: "Description",
    },
    {
        key: "brand",
        label: "Brand",
    },
    {
        key: "collection",
        label: "Collection",
    },
    {
        key: "design",
        label: "Design",
    },
    {
        key: "colour",
        label: "Colour",
    },
    {
        key: "noiseAddFactor",
        label: "Noise add factor",
    },
    {
        key: "exposureCorrectionFactor",
        label: "Exp. corr. factor",
    },
    {
        key: "materialType",
        label: "Material type",
    },
];
