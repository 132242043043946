//@flow
import {
    Autocomplete,
    Datatable,
    LoadIndicator,
} from "@brutextiles/web-component-library";
import React, { type Node, Fragment, useEffect, useState } from "react";

import { useSelect, useSuggestions, useTableData } from "../../hooks";
import { type Action } from "../../templates/OverviewTable/types/action.d";
import { RenderSelection, RendersGallery } from "../index";
import styles from "./render-table.module.scss";

const DEFAULT_PAGE_SIZE = 24;

const extraSortingOptions = [
    { value: "colour_asc", label: "Material colour A -> Z" },
    { value: "colour_desc", label: "Material colour Z -> A" },
    { value: "skuId_asc", label: "SKU ID 0 -> 9" },
    { value: "skuId_desc", label: "SKU ID 9 -> 0" },
    { value: "renditionTime_asc", label: "Render date 0 -> 9" },
    { value: "renditionTime_desc", label: "Render date 9 -> 0" },
];

export type RenderTableActionsProps = {
    itemsCount: number,
};

type Props = {
    settings: { [string]: string },
    actions: (context: RenderTableActionsProps) => Action[],
    onAction: (string, string[]) => Promise<void>,
    apiBase: string,
    idField: string,
    fileInfoLoading: boolean,
    actionLoading: boolean,
    renderDetails?: (render: { [key: string]: string }) => Node,
    onSelectDownload: (type: string, render: { [key: string]: string }) => void,
};

const RenderTable = ({
    settings,
    actions,
    onAction: handleAction,
    onSelectDownload: handleSelectDownload,
    apiBase,
    idField,
    fileInfoLoading,
    actionLoading,
    renderDetails,
}: Props): Node => {
    const [pageSize, setPageSize] = useState(
        +settings.itemsPerPage || DEFAULT_PAGE_SIZE,
    );
    const [sorting, setSorting] = useState(
        settings.sortField &&
            settings.sortOrder &&
            `${settings.sortField}_${settings.sortOrder}`,
    );
    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        setSearchFilters,
        search,
        ,
        ,
        changePageInfo,
        setFullTextSearchQuery,
    ] = useTableData(
        { size: pageSize, sortField: "renditionTime" },
        apiBase,
        60,
    );

    const [galleryState, setGalleryState] = useState({
        isOpen: false,
        showDetails: false,
        position: 0,
    });

    const [{ selected }, setData, selectItem, selectPage, setSelected] =
        useSelect({
            idField,
        });

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
    ] = useSuggestions(apiBase);

    const setFilters = (filters: Array<{ [string]: string[] }>): void => {
        setSearchFilters(filters);
        setSuggestionFilters(filters);
    };

    useEffect(() => {
        if (tableData?.results?.length) {
            setData(tableData.results);
        }
    }, [tableData]);

    useEffect(() => {
        if (!actionLoading) {
            search();
        }
    }, [actionLoading]);

    const hidePrev = tableDataLoading || page === 0;
    const hideNext =
        tableDataLoading ||
        page >= Math.ceil(tableData?.totalResults / pageSize) - 1;

    return (
        <Fragment>
            <div className={styles.rendersOverviewTableWrapper}>
                <Datatable
                    hideTableHeader
                    settings={{
                        ...settings,
                    }}
                    data={tableData?.results || []}
                    onPageInfoChange={pageInfo => {
                        setPageSize(pageInfo.size);
                        changePageInfo(pageInfo);
                    }}
                    actions={actions({
                        itemsCount: tableData?.results?.length || 0,
                    })}
                    onAction={action => {
                        handleAction(
                            action,
                            selected.map(render => render[idField]),
                        );
                        setSelected(() => []);
                    }}
                    onRefresh={search}
                    searchResults={suggestions?.result}
                    onSearchChange={setFilterQuery}
                    onFilterChange={setFilters}
                    searchIsLoading={suggestionsLoading}
                    dataIsLoading={tableDataLoading}
                    totalResults={tableData?.totalResults}
                    page={page}
                    multiSelect
                    multiSuggestion
                    selected={selected}
                    onSelectAll={selectPage}
                    actionLoading={actionLoading}
                    onQuery={setFullTextSearchQuery}
                    cacheSearchFilters
                    externalAction={
                        <div className={styles.customSortingDropdown}>
                            <Autocomplete
                                readOnly
                                items={extraSortingOptions}
                                value={sorting}
                                onChange={selected => {
                                    const criteria = selected.split("_");
                                    setSorting(selected);
                                    if (criteria && criteria.length === 2) {
                                        changeSorting({
                                            [criteria[0]]: criteria[1],
                                        });
                                    }
                                }}
                            />
                        </div>
                    }
                >
                    <tbody>
                        <tr>
                            <td>
                                <div className="mx-4">
                                    {!tableData ? (
                                        <LoadIndicator cols="1" rows={3} />
                                    ) : tableData?.results?.length ? (
                                        <RenderSelection
                                            idField={idField}
                                            imageUrlField="thumbnailUrl"
                                            onItemSelect={selectItem}
                                            selected={selected}
                                            renders={tableData.results}
                                            onShowInfo={(render, index) => {
                                                setGalleryState(() => ({
                                                    isOpen: true,
                                                    showDetails: true,
                                                    position: index,
                                                }));
                                            }}
                                            onShowGallery={(render, index) => {
                                                setGalleryState(() => ({
                                                    isOpen: true,
                                                    showDetails: false,
                                                    position: index,
                                                }));
                                            }}
                                        />
                                    ) : (
                                        "No renders available"
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Datatable>
            </div>
            <RendersGallery
                {...galleryState}
                loading={tableDataLoading}
                renders={tableData?.results}
                showNext={!hideNext}
                showPrev={!hidePrev}
                renderDetails={renderDetails}
                onSelectDownload={handleSelectDownload}
                fileInfoLoading={fileInfoLoading}
                setShowDetails={show =>
                    setGalleryState(state => ({
                        ...state,
                        showDetails: show,
                    }))
                }
                onHide={() =>
                    setGalleryState(() => ({
                        isOpen: false,
                        showDetails: false,
                        position: 0,
                    }))
                }
                onPositionChange={position => {
                    if (position === -1) {
                        changePageInfo({
                            size: pageSize,
                            page: page - 1,
                        });
                        setGalleryState(state => ({
                            ...state,
                            position: pageSize - 1,
                        }));
                    } else if (position === tableData?.results.length) {
                        changePageInfo({
                            size: pageSize,
                            page: page + 1,
                        });
                        setGalleryState(state => ({
                            ...state,
                            position: 0,
                        }));
                    } else {
                        setGalleryState(state => ({
                            ...state,
                            position: position,
                        }));
                    }
                }}
            />
        </Fragment>
    );
};

export default RenderTable;
