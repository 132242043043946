//@flow
import {
    Icon,
    Information,
    Radio,
    Search,
} from "@brutextiles/web-component-library";
import React, { type Node, useState } from "react";
import { Badge, Button, Col, Row } from "reactstrap";

import defaultThumbnail from "../../images/default_render_thumbnail.jpg";
import statuses from "./status";
import style from "./style.module.scss";

type Props = {
    idField: string,
    imageUrlField: string,
    renders: {
        imageRequestDefinitionId: string,
        status: "TO_DO" | "PREPARING" | "IN_PROGRESS" | "FULFILLED" | "FAILED",
        thumbnailUrl: string,
    }[],
    selected: { [string]: string }[],
    onItemSelect: ({ [string]: any }) => void,
    onShowInfo: ({ [string]: any }, number) => void,
    onShowGallery: ({ [string]: any }, number) => void,
};

const RenderSelection = ({
    idField,
    imageUrlField,
    renders,
    selected,
    onItemSelect: handleItemSelect,
    onShowGallery: handleShowGallery,
    onShowInfo: handleShowInfo,
}: Props): Node => (
    <Row>
        {!!renders.length &&
            renders.map((render, index) => (
                <RenderSelectionItem
                    key={index}
                    id={render[idField]}
                    url={render[imageUrlField] || defaultThumbnail}
                    hasRender={
                        render[imageUrlField] !== undefined &&
                        render[imageUrlField].trim().length > 0
                    }
                    checked={
                        selected.findIndex(
                            selectedRow =>
                                selectedRow[idField] === render[idField],
                        ) > -1
                    }
                    onClickInfo={() => handleShowInfo(render, index)}
                    onClickGallery={() => handleShowGallery(render, index)}
                    onItemSelect={() => handleItemSelect(render)}
                    status={render.status}
                />
            ))}
    </Row>
);

type RenderSelectionProps = {
    id: string,
    url: string,
    checked: boolean,
    onClickInfo: () => void,
    onClickGallery: () => void,
    onItemSelect: () => void,
    hasRender?: boolean,
    status: string,
};

export const RenderSelectionItem = ({
    id,
    url,
    checked,
    hasRender,
    onClickInfo: handleClickInfo,
    onClickGallery: handleClickGallery,
    onItemSelect: handleItemSelect,
    status,
}: RenderSelectionProps): Node => {
    const [isShown, setIsShown] = useState(false);
    return (
        <Col
            key={id}
            xs="2"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            className={style.tileWrapper}
        >
            <div
                className={style.tile}
                style={{
                    backgroundImage: `url(${url})`,
                }}
            />
            {isShown && (
                <div className={style.tileActionButtons}>
                    <Button
                        className={style.tileActionButtonsItem}
                        onClick={handleClickInfo}
                    >
                        <Icon
                            className={style.tileActionButtonsItemIcon}
                            icon={Information}
                        />
                    </Button>
                    {hasRender && (
                        <Button
                            className={style.tileActionButtonsItem}
                            onClick={handleClickGallery}
                        >
                            <Icon
                                className={style.tileActionButtonsItemIcon}
                                icon={Search}
                            />
                        </Button>
                    )}
                </div>
            )}
            <div className={style.radio}>
                <Radio id={id} checked={checked} onClick={handleItemSelect} />
            </div>
            <div className={style.badge}>
                {statuses[status] ? (
                    <Badge color={statuses[status].color}>
                        {statuses[status].label}
                    </Badge>
                ) : (
                    <Badge color={"light-grey"}>{status}</Badge>
                )}
            </div>
        </Col>
    );
};

export default RenderSelection;
