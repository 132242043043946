// @flow

import { Check, Clone, Close } from "@brutextiles/web-component-library";

import type { Action } from "../../../templates/OverviewTable/types/action.d";

export default (canManage: boolean, canArchive: boolean): Action[] => [
    {
        label: "Archive",
        action: "archive",
        icon: Close,
        show: selected =>
            canManage && selected?.length === 1 && !selected[0].archived,
        enable: {
            key: "archived",
            condition: (archived: boolean): boolean => canArchive && !archived,
        },
    },
    {
        label: "Unarchive",
        action: "unarchive",
        icon: Check,
        show: selected =>
            canManage && selected?.length === 1 && selected[0].archived,
        enable: {
            key: "archived",
            condition: (archived: boolean): boolean => canArchive && archived,
        },
    },
    {
        label: "Clone",
        action: "clone",
        icon: Clone,
        show: selected => canManage && selected?.length === 1,
    },
];
