//@flow
import { DropdownList, Thumbnail } from "@brutextiles/web-component-library";
import classNames from "classnames";
import React, { type Node } from "react";
import { Input } from "reactstrap";

import defaultThumbnail from "../../../../images/default_render_thumbnail.jpg";
import type { Actions, Settings } from "../../index";
import TableCell from "../TableCell";
import style from "./style.module.scss";

type Props = {
    data: {
        [string]: any,
    }[],
    setIndex: number,
    settings: Settings,
    editMode: boolean,
    onUpdateValue: (number, string, string) => void,
    actions: Actions,
    onActionSelect: (string, number, any) => void,
    errors: {} | { [string]: string },
    readOnly?: boolean,
};

const ViewSetTableBody = ({
    data,
    settings,
    editMode,
    setIndex,
    onUpdateValue: handleUpdateValue,
    actions,
    onActionSelect: handleActionSelect,
    errors,
    readOnly,
}: Props): Node => (
    <tbody>
        {data?.map((element, index) => (
            <tr
                key={index}
                className={classNames(
                    style.tableRow,
                    element.updated && style.versionChanged,
                )}
            >
                {settings.columns.map((column, columnIndex) => (
                    <td
                        key={column.key}
                        className={classNames(
                            editMode && column.editable && style.inputCell,
                            column.thumbnail && style.thumbnail,
                        )}
                    >
                        {column.thumbnail ? (
                            <div className="mr-4">
                                <Thumbnail
                                    size="xl"
                                    imageUrl={
                                        element[column.key] || defaultThumbnail
                                    }
                                />
                            </div>
                        ) : editMode && column.editable ? (
                            <Input
                                onChange={event =>
                                    handleUpdateValue(
                                        index,
                                        column.key,
                                        event.target.value,
                                    )
                                }
                                value={
                                    element[column.key] ||
                                    element.metadata?.[column.key]
                                }
                            />
                        ) : (
                            <TableCell
                                content={
                                    element[column.key] ||
                                    element.metadata?.[column.key]
                                }
                                maxLength={element[column.key] ? 16 : 5}
                                formatter={column.formatter}
                                context={column.key}
                                index={`${setIndex}_${columnIndex}_${index}`}
                            />
                        )}
                        {errors[`${column.key}_${index}`] && (
                            <small className="text-danger">
                                {errors[`${column.key}_${index}`]}
                            </small>
                        )}
                    </td>
                ))}
                {!readOnly && (
                    <td className={style.actionCell}>
                        {filterActions(actions).length > 0 && (
                            <DropdownList
                                listItems={filterActions(actions)}
                                onSelect={action =>
                                    handleActionSelect(action, index, element)
                                }
                            />
                        )}
                    </td>
                )}
            </tr>
        ))}
    </tbody>
);

const filterActions = actions =>
    actions.filter(a => (a.enable ? a.enable.condition() : true));

export default ViewSetTableBody;
