import * as Yup from "yup";

export default {
    initialValues: {
        projectName: "",
        projectManager: "",
        projectDescription: "",
    },
    validationSchema: Yup.object({
        projectName: Yup.string().required("Project name field is required"),
        projectManager: Yup.string().required(
            "Project manager field is required",
        ),
        projectDescription: Yup.string().required(
            "Description type field is required",
        ),
    }),
};
