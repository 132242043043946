//@flow

import { useMemo } from "react";

import useAuth from "./use-auth";

const usePermissions = (permissions: string[]): * => {
    const {
        state: { user },
        hasPermission,
    } = useAuth();

    const resolved: boolean[] = useMemo(
        () => permissions.map(permission => hasPermission(permission)),
        [user],
    );

    return resolved;
};

export default usePermissions;
