export default {
    columns: [
        { key: "companyId", label: "Company", width: "5%" },
        { key: "skuId", label: "SKU ID", width: "5%" },
        { key: "launch", label: "Launch", width: "10%" },
        { key: "brand", label: "Brand", width: "15%" },
        { key: "collection", label: "Collection", width: "12%" },
        { key: "design", label: "Design", width: "12%" },
        { key: "colour", label: "Colour", width: "12%" },
        { key: "endUse", label: "End use", width: "10%" },
        { key: "version", label: "Version", width: "10%" },
        {
            key: "rotation",
            label: "Rotation",
            width: "10%",
            formatter: value => value && `${value}°`,
        },
    ],
    rowId: "versionId",
    itemsPerPage: 5,
    pageSizeOptions: [
        { label: "5", value: "5" },
        { label: "10", value: "10" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    searchCategoryLimits: { min: 5, max: 50 },
};
