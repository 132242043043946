// @flow
import { Download } from "@brutextiles/web-component-library";

export default [
    {
        label: "Download Selected",
        action: "download",
        icon: Download,
    },
];
