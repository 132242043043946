//@flow

import { useEffect, useState } from "react";

import { axiosInstance } from "../api";
import s3Upload from "../utils/aws-helpers/s3Upload";

export type ContentLocation = {
    bucket: string,
    fileName?: string,
    key: string,
};

const UseUpload = (apiBase: string, initialFile?: ContentLocation): * => {
    const [loading, setLoading] = useState(false);
    const [uploadData, setUploadData] = useState();
    const [progress, setProgress] = useState(0);
    const [done, setDone] = useState(false);
    const [fileName, setFileName] = useState();
    const [errorOnUpload, setErrorOnUpload] = useState(false);

    useEffect(() => {
        if (initialFile) {
            setDone(true);
            setFileName(initialFile.fileName);
        }
    }, [initialFile]);

    const uploadFile = async (file: File) => {
        setLoading(true);
        setErrorOnUpload(false);
        try {
            const { data: credentials } = await axiosInstance.post(
                `${apiBase}/aws/upload/credentials`,
                {
                    filename: file.name,
                },
            );
            const { result } = s3Upload(file, credentials, loaded => {
                setProgress((loaded / file.size) * 100);
            });
            const response = await result;
            setFileName(file?.name);
            setUploadData(response);
        } catch (e) {
            setProgress(0);
            setLoading(false);
            setErrorOnUpload(true);
            return;
        }
        setProgress(0);
        setLoading(false);
        setDone(true);
    };

    const resetUpload = (): void => {
        setDone(false);
        setFileName(uploadFile?.fileName);
        setUploadData({});
    };

    return [
        { progress, done, loading, errorOnUpload, uploadData, fileName },
        uploadFile,
        resetUpload,
    ];
};

export default UseUpload;
