//@flow

import useAxios from "axios-hooks";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useState } from "react";

const useSuggestions = (
    apiBase: string,
    _customFilter?: { [string]: string },
): * => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [filterQuery, setFilterQuery] = useState(null);
    const [filters, setSuggestionFilters] = useState(null);
    const [customFilter, setCustomFilter] = useState<{ [string]: string } | {}>(
        _customFilter || {},
    );
    const [{ loading: suggestionsLoading, data: suggestions }, search] =
        useAxios(
            {
                method: "POST",
                url: `/${apiBase}/suggestion`,
                // $FlowFixMe
                params: { query: filterQuery, ...filters, ...customFilter },
                data: { versionIds: selectedItems },
            },
            { manual: true, useCache: false },
        );

    const delayedSearch = useCallback(debounce(search, 300), [filterQuery]);

    useEffect(() => {
        /* By checking if the filterQuery don't equals null we avoid
        the search query being called on initial render */
        if (filterQuery !== null) {
            delayedSearch();
        }
        // Cancel the debounce on useEffect cleanup.
        return delayedSearch.cancel;
    }, [filterQuery, delayedSearch]);

    return [
        { filterQuery, suggestionsLoading, suggestions },
        setSuggestionFilters,
        setFilterQuery,
        setCustomFilter,
        setSelectedItems,
    ];
};

export default useSuggestions;
