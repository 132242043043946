import type {
    ReportData,
    ReportEntry,
    ReportTableRow,
} from "../types/report.d";

//@flow

export default (rawData: ReportData): ReportTableRow[] => {
    const reportData = [];

    if (rawData && Array.isArray(rawData)) {
        rawData.forEach((entry: ReportEntry) => {
            reportData.push({
                "View Set Name": entry.viewSetName,
                "View Name": entry.viewName,
                Status: entry.status,
                "Material Set Name": entry.materialSetName,
                "Sku Id": entry.skuId,
                Launch: entry.launch,
                Brand: entry.brand,
                Collection: entry.collection,
                Colour: entry.colour,
                Design: entry.design,
                "Material Type": entry.materialType,
                "Pairing Label": entry.pairingLabel ? entry.pairingLabel : "",
            });
        });
    }

    return reportData;
};
