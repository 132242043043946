//@flow

import {
    Datatable,
    DetailsForm,
    Heading,
    LoadIndicator,
} from "@brutextiles/web-component-library";
import React, { type Node, Fragment } from "react";
import { Col, Row } from "reactstrap";

import style from "./style.module.scss";

type Props = {
    formSettings: {
        label: string,
        key: string,
        inputType: string,
        disabled: boolean,
        width: number,
    }[],
    tableSettings: {
        columns: { key: string, label: string }[],
    },
    formData: { [string]: string },
    tableData: { [string]: string },
    thumbnailUrl: string,
    loading: boolean,
    canSeeErrorCause: boolean,
};

const RenderDetails = ({
    formSettings,
    tableSettings,
    formData,
    tableData,
    thumbnailUrl,
    loading,
    canSeeErrorCause,
}: Props): Node => (
    <Fragment>
        {loading ? (
            <LoadIndicator cols={2} rows={3} />
        ) : (
            <>
                <Row>
                    {thumbnailUrl && (
                        <Col>
                            <div className={style.thumbnailWrapper}>
                                <div className={style.thumbnail}>
                                    {
                                        <img
                                            src={thumbnailUrl}
                                            alt="render thumbnail"
                                        />
                                    }
                                </div>
                            </div>
                        </Col>
                    )}
                    <Col>
                        {formData.error && (
                            <Row>
                                <div className={style.errorBlock}>
                                    <div>Error: {formData.error}</div>
                                    {canSeeErrorCause && formData.cause && (
                                        <Fragment>
                                            <div>Cause:</div>
                                            <textarea rows={5} readOnly={true}>
                                                {formData.cause}
                                            </textarea>
                                        </Fragment>
                                    )}
                                </div>
                            </Row>
                        )}
                        <Row>
                            <DetailsForm
                                data={formData}
                                settings={formSettings}
                            />
                        </Row>
                    </Col>
                </Row>
                <div className="mt-4">
                    <Heading title={"Materials"} level={5} />
                    <Datatable
                        settings={tableSettings}
                        data={tableData}
                        disableRowSelect
                        hideNavigation
                        disableSort
                    />
                </div>
            </>
        )}
    </Fragment>
);

export default RenderDetails;
