//@flow

import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import { useEffect } from "react";

type Props = {
    overviewUrl?: string,
    excludeErrors?: number[],
};

const useUpdate = ({ overviewUrl, excludeErrors }: Props): * => {
    const { addNotification } = useNotifications();
    const [
        { loading: updateLoading, data: updateData, error: updateError },
        update,
    ] = useAxios(
        {
            method: "PUT",
        },
        { manual: true, useCache: false },
    );

    useEffect(() => {
        if (updateData) {
            addNotification({
                type: "success",
                body: "Update successful",
                autoHide: true,
                timeout: 5000,
            });
            if (overviewUrl) {
                navigate(overviewUrl);
            }
        }
        if (
            updateError &&
            !excludeErrors?.includes(updateError?.response?.status)
        ) {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
        }
    }, [updateData, updateError]);

    return [{ updateLoading, updateData, updateError }, update];
};

export default useUpdate;
