//@flow
import { dateFormatter } from "../../../utils/formatter";
import { getStateBadge } from "../../MaterialWorkflowForm/type/material-state.d";

export default (results: { [string]: any }[]): { [string]: any }[] =>
    results.map(result => ({
        ...result,
        modificationTime: dateFormatter(result.modificationTime),
        state: getStateBadge(result.state),
    }));
