// @flow
import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, Fragment, useState } from "react";
import { Helmet } from "react-helmet";

import { Permissions } from "../../api/permissions";
import { ErrorHandling } from "../../components";
import { usePermissions } from "../../hooks";
import OverviewTable from "../../templates/OverviewTable";
import MaterialWorkflowForm from "../MaterialWorkflowForm";
import RenderSlider from "../RenderSlider";
import actions from "./actions";
import tableSettings from "./table-settings";
import { mapResults } from "./utils";

const MaterialOverview = (): Node => {
    const { addNotification } = useNotifications();
    const [
        canManage,
        canRead,
        canDownload,
        canDownloadRender,
        canApprove,
        canPublish,
        canSeeErrorCause,
    ] = usePermissions([
        Permissions.MATERIAL_MANAGE,
        Permissions.MATERIAL_READ,
        Permissions.MATERIAL_DOWNLOAD,
        Permissions.RENDER_DOWNLOAD,
        Permissions.MATERIAL_APPROVAL,
        Permissions.MATERIAL_PUBLISH,
        Permissions.ERROR_CAUSE,
    ]);

    const [workflowAction, setWorkflowAction] = useState();

    const [, convert] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    const props = {
        title: "Material Overview",
        apiBase: "dap-search-service/material",
        multiSelect: true,
        actionApiBase: "ams-api/material",
        canManage,
        createPageLink: "/materials/create",
        createButtonLabel: "create material",
        tableSettings,
        mapResults,
        identifiers: ["companyId", "skuId"],
        updateLinkBase: "materials",
        customFilter: { latestVersion: "true" },
        refresh: true,
        actions: actions(
            canRead,
            canManage,
            canDownload,
            canApprove,
            canPublish,
        ),
    };

    const onExpandRow = ({ versionId }) => {
        if (versionId && typeof versionId === "string") {
            return (
                <RenderSlider
                    rendersUrl={`dap-search-service/rendition/versionedMaterial/${versionId}`}
                    canDownload={canDownloadRender}
                    isAdmin={canSeeErrorCause}
                />
            );
        } else {
            return <div>something went wrong</div>;
        }
    };

    const handleMaterialTableAction = (
        action: string,
        selected: { [string]: any } | { [string]: any }[],
        fetchResults: () => void,
        executeDefaultTableAction: (
            action: string,
            data: { [string]: any } | { [string]: any }[],
        ) => void,
    ): void => {
        if (["edit", "download", "history"].includes(action)) {
            executeDefaultTableAction(action, selected);
        } else if ("convert" === action) {
            convert({
                url: `/ams-api/material/converted/content`,
                method: "POST",
                data: Array.isArray(selected)
                    ? selected.map(m => m.versionId)
                    : [selected.versionId],
            }).then(() => {
                addNotification({
                    type: "success",
                    body: "You will soon receive a mail with a download link for the selected materials.",
                    autoHide: true,
                    timeout: 5000,
                });
            });
        } else {
            setWorkflowAction({
                type: action,
                data: selected,
                fetchResults: () => setTimeout(fetchResults, 500),
            });
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>Twinbru Engine - Materials</title>
            </Helmet>
            <ErrorHandling />
            {canRead && (
                <Fragment>
                    <OverviewTable
                        {...props}
                        onExpandRow={onExpandRow}
                        onCustomDataTableAction={handleMaterialTableAction}
                    />
                    {workflowAction && (
                        <MaterialWorkflowForm
                            onHide={setWorkflowAction}
                            action={workflowAction}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default MaterialOverview;
