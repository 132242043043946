import {
    ArrowRightUp,
    ArrowUp,
    Refresh,
    Trash,
} from "@brutextiles/web-component-library";

export default [
    { label: "Remove", value: "DELETE", icon: Trash },
    { label: "Relink", value: "RELINK", icon: Refresh },
    {
        label: "Update to latest",
        value: "UPDATE_TO_LATEST",
        icon: ArrowUp,
    },
    {
        label: "Update to latest published",
        value: "UPDATE_TO_LATEST_PUBLISHED",
        icon: ArrowRightUp,
    },
];
