export default [
    {
        label: "Created",
        key: "imageRequestDefinitionCreationTime",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "View set",
        key: "viewSetName",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "View",
        key: "viewName",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Resolution",
        key: "resolution",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Pairing label",
        key: "pairingLabel",
        inputType: "text",
        disabled: true,
        width: 12,
    },
];
