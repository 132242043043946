// @flow

import {
    Button,
    Card,
    DetailsForm,
    Heading,
    Spinner,
} from "@brutextiles/web-component-library";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Container } from "reactstrap";
import * as Yup from "yup";

import { ErrorHandling } from "../../components";
import { useCreate, useErrorHandlingWithForm } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import sharedStyle from "../../styles/shared.module.scss";
import detailSettings from "./detail-settings";

const CreateCompany = (): Node => {
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [companyIdIsUnique, setCompanyIdIsUnique] = useState(true);

    const [{ createLoading, createError }, create] = useCreate({
        overviewUrl: "/companies",
        excludeErrors: [409],
    });

    const formik = useFormik({
        initialValues: {
            companyId: "",
            companyName: "",
            description: "",
        },
        validationSchema: Yup.object({
            companyId: Yup.string()
                .required("Company ID is a required field")
                .test(
                    "length",
                    "The company ID length can't be greater than 4 characters",
                    val => val.length < 5,
                )
                .test(
                    "unique",
                    "This company ID is already in use, please choose another ID",
                    () => companyIdIsUnique,
                )
                .test(
                    "validChars",
                    "The company ID contains invalid characters, only use the following characters: [0-9][A-Z][a-z][-_.]",
                    value => /^[0-9A-Za-z-_.]+$/.test(value),
                ),
            companyName: Yup.string().required(
                "Company name is a required field",
            ),
        }),
        onSubmit: () =>
            create({
                url: `/ams-api/company/${formik.values.companyId}`,
                data: {
                    companyName: formik.values.companyName,
                    description: formik.values.description,
                },
            }),
    });

    const { setDirty } = useContext(FormContext);
    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm("create-company-form", formik.errors);

    useEffect(() => {
        if (formik.values.companyId && shouldValidateOnChange) {
            setCompanyIdIsUnique(true);
        }
    }, [formik.values.companyId]);

    useEffect(() => {
        if (shouldValidateOnChange) {
            formik.validateForm();
        }
    }, [companyIdIsUnique]);

    useEffect(() => {
        if (createError?.response?.status === 409) {
            setCompanyIdIsUnique(false);
            formik.validateForm();
        }
    }, [createError]);

    const actionButtons = (
        <Fragment>
            <Button
                className="mr-3"
                outline
                onClick={() => navigate("/companies")}
            >
                Cancel
            </Button>
            <Button
                disabled={!!Object.keys(formik.errors).length}
                onClick={() => {
                    formik.handleSubmit();
                    setShouldValidateOnChange(true);
                }}
            >
                {createLoading ? <Spinner /> : "Create"}
            </Button>
        </Fragment>
    );

    return (
        <Container fluid className={sharedStyle.wizard}>
            <Heading title="Create Company" rightContent={actionButtons} />
            <ErrorHandling />
            <Heading title={"Company details"} level={5} />
            <Card>
                <DetailsForm
                    settings={detailSettings}
                    data={formik.values}
                    onChange={data =>
                        formik.setFieldValue(
                            data.key,
                            data.value,
                            shouldValidateOnChange,
                        )
                    }
                    errors={formik.errors}
                />
            </Card>
        </Container>
    );
};

export default CreateCompany;
