import moment from "moment";

export const intFormatter = value =>
    value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";

export const dateFormatter = value =>
    value ? moment(value).format("DD/MM/YYYY") : "";

export const dateTimeFormatter = value =>
    value ? moment(value).format("DD/MM/YYYY HH:mm") : "";
