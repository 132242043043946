//@flow

import useAxios from "axios-hooks";
import { useEffect, useState } from "react";

type Props = {
    user: string,
    fetchResults: () => void,
};

const useTableActions = ({ fetchResults }: Props): * => {
    const [actionParameters, setActionParameters] = useState({});
    const [actionLoading, setActionLoading] = useState(null);
    const [{ loading, data: actionData }, load] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        if (actionParameters.action === "download") {
            confirmAction();
        }
    }, [actionParameters]);

    useEffect(() => {
        const action = actionParameters?.action;
        if (
            action === "download" &&
            actionData.url &&
            typeof window !== "undefined"
        ) {
            window.open(actionData.url);
        } else if (
            ["publish", "unpublish", "archive", "unarchive"].includes(action)
        ) {
            fetchResults();
        }
        setActionParameters({});
    }, [actionData]);

    useEffect(() => {
        if (!loading) {
            setActionLoading(null);
        }
    }, [loading]);

    const confirmAction = () => {
        const { baseUrl, action } = actionParameters;
        setActionLoading(action);
        if (action === "download") {
            load({
                url: `${baseUrl}/latest/content`,
                method: "GET",
            });
        } else if (
            ["publish", "unpublish", "archive", "unarchive"].includes(action)
        ) {
            load({
                url: `${baseUrl}/${action}`,
                method: "PUT",
            });
        }
    };

    return [
        { actionLoading, actionData, actionParameters },
        setActionParameters,
        confirmAction,
    ];
};

export default useTableActions;
