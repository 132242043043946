//@flow

import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import { useEffect } from "react";

type Props = {
    overviewUrl: string,
    excludeErrors?: number[],
};

const useCreate = ({ overviewUrl, excludeErrors }: Props): * => {
    const { addNotification } = useNotifications();
    const [
        { loading: createLoading, data: createData, error: createError },
        create,
    ] = useAxios(
        {
            method: "POST",
        },
        { manual: true, useCache: false },
    );

    useEffect(() => {
        if (
            createError &&
            !excludeErrors?.includes(createError?.response?.status)
        ) {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
        }
        if (createData) {
            addNotification({
                type: "success",
                body: "Creation successful",
                autoHide: true,
                timeout: 5000,
            });
            navigate(overviewUrl);
        }
    }, [createData, createError]);

    return [{ createLoading, createData, createError }, create];
};

export default useCreate;
