//@flow

export default (combinations: any): Set<string> => {
    return combinations.reduce((acc, combination) => {
        combination.components.forEach(component =>
            acc.add(`${component.componentId}:${component.sceneVersionId}`),
        );
        return acc;
    }, new Set());
};
