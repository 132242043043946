// @flow

import { LoadIndicator } from "@brutextiles/web-component-library";
import classNames from "classnames";
import React, { type Node } from "react";
import { Form } from "react-bootstrap";
import { Col, Row } from "reactstrap";

import style from "./style.module.scss";

type Props = {
    level?: 1 | 2 | 3 | 4 | 5 | 6,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    projectName?: string,
    rightContent?: any,
    readOnly: boolean,
};

const UpdateProjectHeading = ({
    level = 1,
    projectName,
    rightContent,
    isAutomaticRendering,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
    readOnly,
}: Props): Node => {
    if (!projectName) {
        return (
            <div className="mb-5 w-25">
                <LoadIndicator cols={1} rows={1} />
            </div>
        );
    }

    const HeadingTag = `h${level}`;

    return (
        <Row
            className={classNames(
                style.updateProjectHeading,
                style[`heading${level}`],
            )}
        >
            <Col>
                <Form inline>
                    <HeadingTag>{projectName}</HeadingTag>
                    <Form.Switch
                        className={style.switch}
                        onChange={() =>
                            handleToggleAutomaticRendering(
                                !isAutomaticRendering,
                            )
                        }
                        disabled={readOnly}
                        id="custom-switch"
                        label="Automatic rendering"
                        checked={isAutomaticRendering}
                    />
                </Form>
            </Col>
            {rightContent && !readOnly && (
                <Col className={style.rightContent} xs={"auto"}>
                    {rightContent}
                </Col>
            )}
        </Row>
    );
};

export default UpdateProjectHeading;
