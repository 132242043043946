// @flow
import { Tabs } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";

import { ErrorHandlingContext } from "../../providers/error-handling-provider";
import sharedStyle from "../../styles/shared.module.scss";
import ProjectMaterials from "../ProjectMaterials";
import ProjectPairings from "../ProjectPairings";
import ProjectRenders from "../ProjectRenders";
import ProjectReport from "../ProjectReport";
import ProjectSummary from "../ProjectSummary";
import ProjectViews from "../ProjectViews";
import tabs from "./settings/tabs";
import style from "./update-project.module.scss";

type Props = {
    projectId: string,
    readOnly?: boolean,
};

const UpdateProject = ({ projectId, readOnly = false }: Props): Node => {
    const { clearAllMessages } = useContext(ErrorHandlingContext);
    const [activeTab, setActiveTab] = useState(1);
    const [allowNavigation, setAllowNavigation] = useState(true);
    const [isAutomaticRendering, setAutomaticRendering] = useState(false);

    const [{ loading, data: projectMetaData, error }, reloadMetaData] =
        useAxios(`/ams-api/project/${projectId}/metadata`, {
            useCache: false,
        });

    useEffect(() => {
        if (projectMetaData) {
            setAutomaticRendering(projectMetaData.isAutomaticRendering);
        }
    }, [projectMetaData]);

    const [, putAutomaticRendering] = useAxios(
        {
            url: `/ams-api/project/${projectId}/automaticRendering`,
            method: "PUT",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const commonProps = {
        projectId,
        readOnly,
        ...projectMetaData,
        isAutomaticRendering: isAutomaticRendering,
        toggleAutomaticRendering: value => {
            setAutomaticRendering(value);
            putAutomaticRendering({
                data: JSON.stringify({ isAutomaticRendering: value }),
            }).then(() => reloadMetaData());
        },
    };

    return (
        <Container fluid className={sharedStyle.wizard}>
            <div className={sharedStyle.stepper}>
                <div className={style.stepperWrapper}>
                    <Tabs
                        tabs={tabs}
                        onTabChange={tab => {
                            reloadMetaData();
                            setActiveTab(tab.id);
                            clearAllMessages();
                        }}
                        activeTab={1}
                        disabled={allowNavigation}
                    />
                </div>
            </div>
            {activeTab === 0 && (
                <ProjectSummary
                    error={error}
                    loading={loading}
                    metaData={commonProps}
                    reloadMetaData={reloadMetaData}
                    onEnableNavigation={setAllowNavigation}
                    readOnly={readOnly}
                />
            )}
            {activeTab === 1 && <ProjectViews {...commonProps} />}
            {activeTab === 2 && <ProjectMaterials {...commonProps} />}
            {activeTab === 3 && <ProjectPairings {...commonProps} />}
            {activeTab === 4 && <ProjectRenders {...commonProps} />}
            {activeTab === 5 && <ProjectReport {...commonProps} />}
        </Container>
    );
};

export default UpdateProject;
