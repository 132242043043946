//@flow
import { useImmer } from "use-immer";

const useCollapse = (): * => {
    const [collapseState, setCollapseState] = useImmer({});

    const collapseAll = (): * => {
        setCollapseState(draft => {
            Object.keys(draft).map(id => {
                if (!draft[id]) {
                    draft[id] = true;
                }

                return draft[id];
            });
            return draft;
        });
    };

    const expandAll = () => {
        setCollapseState(draft => {
            Object.keys(draft).map(id => {
                if (draft[id]) {
                    draft[id] = false;
                }

                return draft[id];
            });
            return draft;
        });
    };

    const toggleCollapse = (id: string): * =>
        setCollapseState(draft => {
            draft[id] = !draft[id];
            return draft;
        });

    const addCollapsible = (id: string, value: boolean): * =>
        setCollapseState(draft => {
            draft[id] = value;
            return draft;
        });

    const removeCollapsible = (id: string): * =>
        setCollapseState(draft => {
            delete draft[id];
            return draft;
        });

    const collapsed = (id: string): boolean => collapseState[id];

    return {
        addCollapsible,
        collapseAll,
        expandAll,
        collapsed,
        toggleCollapse,
        removeCollapsible,
    };
};

export default useCollapse;
