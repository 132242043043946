// @flow
import {
    Button,
    Heading,
    Icon,
    LoadIndicator,
    Modal,
    Plus,
    Radio,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, Fragment, useEffect, useState } from "react";
import { ButtonGroup } from "reactstrap";

import { ErrorHandling } from "../../components";
import { useCollapse, usePairings } from "../../hooks";
import sharedStyles from "../../styles/shared.module.scss";
import { intFormatter } from "../../utils/formatter";
import style from "../ProjectMaterials/style.module.scss";
import UpdateProjectHeading from "../UpdateProjectHeading";
import PairingRow from "./subComponents/PairingRow";
import { PairingType } from "./types/pairing.d";

type Props = {
    projectId: string,
    projectName: string,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    readOnly: boolean,
};

const ProjectPairings = ({
    projectId,
    readOnly,
    projectName,
    isAutomaticRendering = false,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
}: Props): Node => {
    const [showSelectionType, setShowSelectionType] = useState(false);
    const [type, setType] = useState(PairingType.SINGLE);
    const toggleType = () =>
        setType(
            type === PairingType.SINGLE
                ? PairingType.MULTI
                : PairingType.SINGLE,
        );

    const { addCollapsible, collapsed, toggleCollapse } = useCollapse();
    const [pairingToDelete, setPairingToDelete] = useState();

    const [
        {
            pairings,
            viewSetOptions,
            materialSetOptions,
            resolutionOptions,
            outputFormatOptions,
            dataLoading,
            totalRenders,
        },
        saveChanges,
        addPairing,
        deletePairing,
        getPairings,
    ] = usePairings({
        apiBase: `/ams-api/project/${projectId}`,
    });

    const [, enablePairing] = useAxios(
        {
            method: "PUT",
        },
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        pairings.forEach(pairing =>
            addCollapsible(
                pairing.pairingId,
                collapsed(pairing.pairingId) !== undefined
                    ? collapsed(pairing.pairingId)
                    : pairing.pairingId !== undefined,
            ),
        );
    }, [pairings]);

    return (
        <Fragment>
            <ErrorHandling />
            <UpdateProjectHeading
                readOnly={readOnly}
                toggleAutomaticRendering={handleToggleAutomaticRendering}
                isAutomaticRendering={isAutomaticRendering}
                projectName={projectName}
                rightContent={
                    <Button
                        onClick={() => {
                            setShowSelectionType(true);
                        }}
                        color="light-blue"
                    >
                        ADD PAIRING
                    </Button>
                }
            />
            <Heading
                title={"Pairings"}
                rightContent={
                    !!totalRenders && (
                        <span>
                            Result:{" "}
                            <strong className="text-secondary">
                                {intFormatter(totalRenders)} renders
                            </strong>
                        </span>
                    )
                }
                level={5}
            />
            {dataLoading ? (
                <LoadIndicator cols={4} rows={4} />
            ) : (
                !pairings?.length &&
                !dataLoading && (
                    <div className={sharedStyles.addContainer}>
                        <Button
                            color="link"
                            onClick={() => {
                                setShowSelectionType(true);
                            }}
                        >
                            <Icon
                                className="mb-1 mr-2"
                                variant="secondary"
                                icon={Plus}
                            />
                            <span className="text-secondary">Add pairing</span>
                        </Button>
                    </div>
                )
            )}
            {!!pairings.length && !dataLoading && (
                <div className="position-relative">
                    {pairings.map((pairing, index) => (
                        <PairingRow
                            key={index}
                            loading={dataLoading}
                            projectId={projectId}
                            isOpen={collapsed(pairing.pairingId) === false}
                            onToggle={() => toggleCollapse(pairing.pairingId)}
                            readOnly={readOnly}
                            offset={index}
                            pairing={pairing}
                            resolutionOptions={resolutionOptions}
                            viewSetOptions={viewSetOptions}
                            materialSetOptions={materialSetOptions}
                            outputFormatOptions={outputFormatOptions}
                            onSave={saveChanges}
                            onRemove={() => setPairingToDelete(index)}
                            onEnablePairing={pairing => {
                                const pairingId = pairing.pairingId;
                                if (pairingId) {
                                    enablePairing({
                                        url: `/ams-api/project/${projectId}/pairing/${pairingId}/enable`,
                                    }).then(() => getPairings());
                                }
                            }}
                        />
                    ))}
                    <Modal
                        size={"lg"}
                        confirmation
                        open={pairingToDelete !== undefined}
                        title={"confirmation"}
                        onCancel={() => setPairingToDelete()}
                        actions={[
                            {
                                type: "primary",
                                label: "Yes",
                                action: () => {
                                    deletePairing(pairingToDelete);
                                    setPairingToDelete();
                                },
                            },
                            {
                                type: "secondary",
                                label: "No",
                                action: () => setPairingToDelete(),
                            },
                        ]}
                    >
                        Do you want to remove the pairing?
                    </Modal>
                </div>
            )}
            <Modal
                size={"lg"}
                open={showSelectionType}
                title={"Choose pairing type"}
                onCancel={() => {
                    setShowSelectionType();
                    setType(PairingType.SINGLE);
                }}
            >
                <div>
                    Do you want to create a single material pairing or multiple?
                </div>
                <div className={style.selectionContainer}>
                    <div className={style.radioGroup}>
                        <div className={style.entry}>
                            <Radio
                                checked={type === PairingType.SINGLE}
                                onClick={toggleType}
                                id="materialSet"
                                type="radio"
                                label="Single material pairing (default)"
                            />
                        </div>
                        <div className={style.entry}>
                            <Radio
                                checked={type === PairingType.MULTI}
                                onClick={toggleType}
                                id="materialSet"
                                type="radio"
                                label="Multi material pairing"
                            />
                        </div>
                    </div>
                    <ButtonGroup size={"lg"} className={style.selectionButtons}>
                        <Button
                            color={"light"}
                            onClick={() => {
                                setType(PairingType.SINGLE);
                                setShowSelectionType();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                addPairing(type);
                                setShowSelectionType(false);
                            }}
                        >
                            Ok
                        </Button>
                    </ButtonGroup>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ProjectPairings;
