//@flow

import type { MaterialDataTableElement } from "../types/material-data-table-element.d";

export default function chain(
    data: MaterialDataTableElement | MaterialDataTableElement[],
    apply: (row: MaterialDataTableElement) => Promise<any>,
): Promise<any> {
    if (Array.isArray(data)) {
        let cursor = apply(data[0]);

        if (data.length > 1) {
            for (let i = 1; i < data.length; i++) {
                cursor = cursor.then(() => apply(data[i]));
            }
        }

        return cursor;
    } else {
        return apply(data);
    }
}
