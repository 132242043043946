import * as Yup from "yup";

export default {
    initialValues: {
        companyId: "",
        floorType: "",
        sceneName: "",
        wallColour: "",
        assetType: "",
        lightSetting: "",
        roomType: "",
        comment: "Initial upload",
        fileUrl: "",
        uploadStatus: "",
    },
    validationSchema: Yup.object({
        companyId: Yup.string().required("Company field is required"),
        floorType: Yup.string().required("Floor type field is required"),
        sceneName: Yup.string().required("Scene name field is required"),
        wallColour: Yup.string().required("Wall colour field is required"),
        assetType: Yup.string().required("Asset type field is required"),
        lightSetting: Yup.string().required("Light setting field is required"),
        roomType: Yup.string().required("Room type field is required"),
        comment: Yup.string().required("Comment field is required"),
        fileUrl: Yup.string().required("File upload is required"),
        uploadStatus: Yup.string().test(
            "valid",
            "Please upload a valid file",
            value => value === "VALID",
        ),
    }),
};
