import settings from "../CreateCompany/detail-settings";

export default readOnly =>
    settings.map(({ ...setting }) => {
        if (setting.key === "companyId" || readOnly) {
            setting.disabled = true;
        }

        return setting;
    });
