// @flow

import React, { type Node, Fragment } from "react";
import { Helmet } from "react-helmet";

import { Permissions } from "../../api/permissions";
import { ErrorHandling } from "../../components";
import { usePermissions } from "../../hooks";
import OverviewTable from "../../templates/OverviewTable";
import tableSettings from "./table-settings";
import { mapResults } from "./utils";

const CompaniesOverview = (): Node => {
    const [canManage, canRead] = usePermissions([
        Permissions.COMPANY_MANAGE,
        Permissions.COMPANY_READ,
    ]);

    const props = {
        title: "Companies Overview",
        apiBase: "dap-search-service/company",
        canManage,
        createPageLink: "/companies/create",
        createButtonLabel: "create company",
        tableSettings,
        mapResults,
        identifiers: ["companyId"],
        updateLinkBase: "companies",
        refresh: true,
        disableRowSelect: true,
    };

    return (
        <Fragment>
            <Helmet>
                <title>Twinbru Engine - Companies</title>
            </Helmet>
            <ErrorHandling />
            {canRead && <OverviewTable {...props} />}
        </Fragment>
    );
};

export default CompaniesOverview;
