import { archiveFilters } from "../../../templates/OverviewTable/actions";
import style from "../projects.module.scss";

export default withCosts => {
    const columns = [
        {
            key: "projectNumber",
            label: "Project number",
            width: withCosts ? "7%" : "10%",
        },
        {
            key: "companyId",
            label: "Company ID",
            width: "10%",
        },
        {
            key: "projectName",
            label: "Project name",
            width: "20%",
            multiline: true,
        },
        {
            key: "projectDescription",
            label: "Description",
            width: "20%",
            multiline: true,
        },
        {
            key: "projectManager",
            label: "Project manager",
            width: "15%",
        },
        {
            key: "modificationTime",
            label: "Last modified",
            width: "8%",
        },
    ];

    if (withCosts) {
        columns.push(
            {
                key: "projectCost",
                label: "Cost",
                width: "8%",
                headerClassName: style.columnRight,
                cellClassName: style.cellRight,
            },
            {
                key: "status",
                label: "Status",
                width: "9%",
            },
        );
    } else {
        columns.push({
            key: "status",
            label: "Status",
            width: "10%",
        });
    }

    return {
        columns,
        rowId: "projectId",
        searchLabel: "Search project name, etc.",
        customFilters: archiveFilters,
        itemsPerPage: 50,
        searchCategoryLimits: { min: 5, max: 50 },
        pageSizeOptions: [
            { label: "10", value: "10" },
            { label: "25", value: "25" },
            { label: "50", value: "50" },
            { label: "100", value: "100" },
        ],
    };
};
