//@flow

import {
    ChevronDown,
    ChevronUp,
    Icon,
    Trash,
} from "@brutextiles/web-component-library";
import React, { type Node, useContext, useEffect, useState } from "react";

import { ErrorHandlingContext } from "../../providers/error-handling-provider";
import style from "./style.module.scss";

type Props = {
    exclude?: string[],
    showOnly?: string[],
};

const ErrorHandling = (props: Props): Node => {
    const { errorData, clearAllMessages } = useContext(ErrorHandlingContext);
    const [collapsed, setCollapsed] = useState(true);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        return () => {
            clearAllMessages();
        };
    }, []);

    useEffect(() => {
        if (errorData) {
            setErrors(() => {
                const draft = [];

                Object.keys(errorData).forEach(key => {
                    if (
                        (props.showOnly && props.showOnly.includes(key)) ||
                        (props.exclude && !props.exclude.includes(key)) ||
                        (!props.showOnly && !props.exclude)
                    ) {
                        draft.push(...errorData[key].allErrors);
                    }
                });

                return draft;
            });
        }
    }, [errorData]);

    if (!errors || errors.length === 0) {
        return <div />;
    }

    return (
        <div className={style.errorHandling}>
            <div className={style.header}>
                <div className={style.errorsCount}>{errors.length}</div>
                <button
                    className={style.expandButton}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <Icon icon={collapsed ? ChevronDown : ChevronUp} />
                </button>
                Errors on this page
                <button
                    className={style.cancelButton}
                    onClick={() => clearAllMessages()}
                >
                    <Icon className={style.icon} icon={Trash} />
                </button>
            </div>
            {!collapsed && (
                <div className={style.messages}>
                    {errors.map(({ field, message }, idx) => (
                        <div className={style.entry} key={idx}>
                            {field} - {message}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ErrorHandling;
