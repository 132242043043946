import { useState } from "react";

function useTenant() {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window !== "undefined" && window) {
            return window.localStorage.getItem("tenant");
        } else {
            return undefined;
        }
    });

    const setValue = value => {
        if (typeof window !== "undefined" && window) {
            setStoredValue(value);
            window.localStorage.setItem("tenant", value);
        } else {
            setStoredValue(value);
        }
    };

    return [storedValue, setValue];
}

export const findTenantId = tenants => {
    if (typeof window !== "undefined" && window) {
        const entry = tenants.find(
            item =>
                item.hostName === window.location.origin ||
                window.location.origin.startsWith(item.hostName),
        );

        return entry?.tenantId;
    }
};

export const buildFrontendUrl = () => {
    return (
        typeof window !== "undefined" &&
        `${window.location.protocol}//${window.location.host}`
    );
};

export default useTenant;
