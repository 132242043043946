export default [
    {
        label: "Company ID (max. 4 characters)",
        key: "companyId",
        inputType: "text",
        required: true,
    },
    {
        label: "Company name",
        key: "companyName",
        inputType: "text",
        required: true,
    },
    {
        label: "Description",
        key: "description",
        inputType: "textarea",
        width: 12,
        placeholder: "Type description here",
    },
];
