import settings from "../../CreateScene/settings/form-settings";

export default readOnly => {
    return settings.map(setting =>
        setting.key === "companyId"
            ? {
                  ...setting,
                  inputType: "text",
                  disabled: true,
              }
            : {
                  ...setting,
                  disabled: readOnly,
                  inputType: readOnly ? "text" : setting.inputType,
              },
    );
};
