//@flow
import {
    Autocomplete,
    Button,
    Icon,
    InlineEdit,
    Plus,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import classnames from "classnames";
import { useFormik } from "formik";
import React, { type Node, useContext, useEffect, useState } from "react";
import { Badge, Col, Collapse, Input } from "reactstrap";
import { useImmer } from "use-immer";

import type { Pairing } from "../../../../components/Pairing/types/pairing.d";
import Header from "../../../../components/ViewSet/subComponents/Header";
import { useErrorHandlingWithForm } from "../../../../hooks";
import { ErrorHandlingContext } from "../../../../providers/error-handling-provider";
import CombinationsTable from "../CombinationsTable";
import type { Option } from "../MultiSelectDropdown/types/option.d";
import styles from "./pairingrow.module.scss";
import mapInitialValues from "./utils/map-initial-values";
import mapResults from "./utils/map-results";
import mapUsedComponents from "./utils/map-used-components";

type Props = {
    projectId: string,
    pairing: Pairing,
    offset: number,
    resolutionOptions: Option[],
    materialSetOptions: Option[],
    outputFormatOptions: Option[],
    viewSetOptions: [],
    isOpen: boolean,
    onToggle: () => void,
    onSave: (pairing: Pairing) => Promise<boolean>,
    onRemove: () => void,
    onEnablePairing: (pairing: Pairing) => void,
    loading: boolean,
    readOnly?: boolean,
};

const PairingRow = ({
    projectId,
    pairing,
    offset,
    resolutionOptions,
    materialSetOptions,
    outputFormatOptions,
    viewSetOptions,
    isOpen,
    onToggle: handleToggle,
    onSave: handleSave,
    onRemove: handleRemove,
    onEnablePairing: handleEnablePairing,
    readOnly = false,
    loading,
}: Props): Node => {
    const [submitting, setSubmitting] = useState(false);
    const [editMode, setEditMode] = useState(pairing?.editMode || false);
    const [viewSetsToComponents, setViewSetsToComponents] = useImmer({});

    const { clearErrorMessages } = useContext(ErrorHandlingContext);

    const [{ data: componentsData }, fetchComponentsData] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        if (componentsData) {
            const { viewSetId, componentsPerScene } = componentsData;
            setViewSetsToComponents(draft => {
                draft[viewSetId] = mapResults(componentsPerScene);
                return draft;
            });
        }
    }, [componentsData]);

    const formik = useFormik({
        initialValues: mapInitialValues(pairing),
        validateOnChange: false,
        validateOnBlur: false,
        validate: values => {
            const errors = {};

            if (!values.resolution) {
                errors["resolution"] = "Resolution is mandatory";
            }

            if (!values.viewSetId) {
                errors["viewSetId"] = "ViewSet is mandatory";
            }

            if (values.componentsMaterialSetsCombinations) {
                values.componentsMaterialSetsCombinations.forEach(
                    (combination, idx) => {
                        let materialSetsValid = true;
                        if (
                            !combination.materialSets ||
                            combination.materialSets.length === 0
                        ) {
                            materialSetsValid = false;
                        }

                        if (!materialSetsValid) {
                            errors[
                                `combination-${idx}-materialSets`
                            ] = `The material set for combination ${idx} isn't valid`;
                        }

                        let componentsValid = true;

                        if (
                            !pairing.singleMaterial &&
                            (!combination.components ||
                                combination.components.length === 0)
                        ) {
                            componentsValid = false;
                        }

                        if (!componentsValid) {
                            errors[
                                `combination-${idx}-components`
                            ] = `Components for combination ${idx} aren't valid`;
                        }
                    },
                );
            }

            return errors;
        },
        onSubmit: (values, { validateForm }) => {
            validateForm(values);
            setSubmitting(true);
            if (formik.isValid) {
                handleSave(formik.values).then(() => {
                    setSubmitting(false);
                    setEditMode(false);
                });
            }
        },
    });

    useEffect(() => {
        if (pairing && !pairing.editMode) {
            formik.resetForm();
            formik.setValues(mapInitialValues(pairing));
        }
    }, [pairing]);

    useErrorHandlingWithForm("edit-pairing-form", formik.errors);

    useEffect(() => {
        if (
            isOpen &&
            !componentsData &&
            (pairing?.viewSetId || formik?.values?.viewSetId)
        ) {
            fetchComponentsData(
                `/ams-api/project/${projectId}/viewSet/${
                    pairing?.viewSetId || formik?.values?.viewSetId
                }/components`,
            );
        }
    }, [pairing?.viewSetId, formik?.values?.viewSetId, isOpen]);

    return (
        <div
            className={classnames("px-0", editMode ? styles.edit : styles.read)}
        >
            <InlineEdit
                isSubmitting={submitting}
                active={editMode}
                onCancel={() => {
                    clearErrorMessages("edit-pairing-form");

                    if (pairing.pairingId) {
                        formik.setValues(mapInitialValues(pairing));
                    } else {
                        handleRemove();
                    }

                    setEditMode(false);
                }}
                onSave={() => formik.submitForm()}
                isOpen={isOpen}
            >
                <Header
                    order={offset}
                    onToggle={handleToggle}
                    editMode={editMode}
                    isOpen={isOpen}
                    onDelete={() => {
                        setEditMode(false);
                        handleRemove();
                    }}
                    readOnly={readOnly}
                    onEnableEditMode={() => setEditMode(true)}
                    loading={loading}
                    disabled={!pairing.enabled}
                    rightAction={
                        !pairing.enabled &&
                        pairing?.pairingId && (
                            <Button
                                id={offset}
                                onClick={() => handleEnablePairing(pairing)}
                                size={"sm"}
                                color={"light-blue"}
                            >
                                Enable
                            </Button>
                        )
                    }
                >
                    <Col>
                        {pairing.isInvalid && (
                            <Badge color={"danger"}>Invalid</Badge>
                        )}
                        {!pairing.isInvalid && pairing.singleMaterial && (
                            <Badge color={"light"}>Single</Badge>
                        )}
                        {!pairing.isInvalid && !pairing.singleMaterial && (
                            <Badge color={"success"}>Multi</Badge>
                        )}
                    </Col>
                    <Col>
                        {editMode ? (
                            <Input
                                placeholder={"Pairing label"}
                                value={formik.values.label}
                                onChange={({ target: { value } }) =>
                                    formik.setFieldValue("label", value)
                                }
                            />
                        ) : (
                            pairing.label
                        )}
                        {formik.errors["label"] && (
                            <small className="text-danger">
                                {formik.errors["label"]}
                            </small>
                        )}
                    </Col>
                    <Col>
                        {editMode && !pairing.pairingId ? (
                            <Autocomplete
                                readOnly
                                value={formik.values.viewSetId}
                                items={viewSetOptions}
                                placeholder={"Viewset"}
                                onChange={viewSetId => {
                                    formik.setFieldValue(
                                        "viewSetId",
                                        viewSetId,
                                    );

                                    formik.setFieldValue(
                                        "componentsMaterialSetsCombinations",
                                        formik.values.componentsMaterialSetsCombinations.map(
                                            combination => ({
                                                ...combination,
                                                components: [],
                                            }),
                                        ),
                                    );
                                }}
                            />
                        ) : (
                            viewSetOptions.find(
                                set => set.value === formik.values.viewSetId,
                            )?.label
                        )}
                        {formik.errors["viewSetId"] && (
                            <small className="text-danger">
                                {formik.errors["viewSetId"]}
                            </small>
                        )}
                    </Col>
                    <Col>
                        {editMode && !pairing.pairingId ? (
                            <Autocomplete
                                readOnly
                                value={formik.values.resolution}
                                items={resolutionOptions}
                                placeholder={"Resolution"}
                                onChange={selectedOption =>
                                    formik.setFieldValue(
                                        "resolution",
                                        selectedOption,
                                    )
                                }
                            />
                        ) : (
                            pairing.resolution
                        )}
                        {formik.errors["resolution"] && (
                            <small className="text-danger">
                                {formik.errors["resolution"]}
                            </small>
                        )}
                    </Col>
                    <Col>
                        {editMode && !pairing.pairingId ? (
                            <Autocomplete
                                readOnly
                                value={formik.values.outputFormat}
                                items={outputFormatOptions}
                                placeholder={"Output Format"}
                                onChange={selectedOption =>
                                    formik.setFieldValue(
                                        "outputFormat",
                                        selectedOption,
                                    )
                                }
                            />
                        ) : (
                            pairing.outputFormat
                        )}
                        {formik.errors["outputFormat"] && (
                            <small className="text-danger">
                                {formik.errors["outputFormat"]}
                            </small>
                        )}
                    </Col>
                    <Col>
                        {pairing.imageRequestDefinitionCount !== undefined &&
                            `Total renders: ${pairing.imageRequestDefinitionCount}`}
                    </Col>
                </Header>
                <Collapse isOpen={isOpen}>
                    <CombinationsTable
                        pairingIdx={offset}
                        singleMaterial={pairing.singleMaterial}
                        disabled={!editMode || pairing.pairingId !== undefined}
                        errors={formik.errors}
                        componentsMaterialSetsCombinations={
                            formik.values.componentsMaterialSetsCombinations
                        }
                        usedComponents={mapUsedComponents(
                            formik.values.componentsMaterialSetsCombinations,
                        )}
                        componentOptions={
                            viewSetsToComponents[formik.values.viewSetId]
                        }
                        materialSetOptions={materialSetOptions}
                        onChange={values => {
                            formik.setFieldValue(
                                "componentsMaterialSetsCombinations",
                                values,
                            );
                            setEditMode(true);
                        }}
                    />
                    {editMode &&
                        (!pairing.pairingId || pairing.isInvalid) &&
                        !pairing.singleMaterial && (
                            <Button
                                className={`w-100 text-secondary py-2`}
                                color="light-grey"
                                onClick={() => {
                                    const updated = formik.values
                                        .componentsMaterialSetsCombinations
                                        ? [
                                              ...formik.values
                                                  .componentsMaterialSetsCombinations,
                                          ]
                                        : [];
                                    updated.push({
                                        components: [],
                                        materialSets: [],
                                    });
                                    formik.setFieldValue(
                                        "componentsMaterialSetsCombinations",
                                        updated,
                                    );
                                    setEditMode(true);
                                }}
                            >
                                <Icon
                                    icon={Plus}
                                    className="mr-2"
                                    variant="secondary"
                                />
                                Add
                            </Button>
                        )}
                </Collapse>
            </InlineEdit>
        </div>
    );
};

export default PairingRow;
