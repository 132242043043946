//@flow

import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";

export const SetType = Object.freeze({
    SUBSCRIPTION: "SUBSCRIPTION",
    SELECTION: "SELECTION",
});

const useMaterialSets = (projectId: string): * => {
    const { addNotification } = useNotifications();

    const [indexSetToExtend, setIndexSetToExtend] = useState();
    const [indexNewSet, setIndexNewSet] = useState();

    const [sets, setSets] = useImmer([]);

    const [{ loading, data }, fetchMaterialSetData] = useAxios(
        `/ams-api/project/${projectId}/materialSets`,
        {
            useCache: false,
        },
    );

    const [{ loading: deleteSetLoading }, _deleteSet] = useAxios(
        {
            method: "DELETE",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const [{ loading: createSetLoading }, _createMaterialSet] = useAxios(
        {
            method: "POST",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    const [
        { data: updateSetData, loading: updateSetLoading },
        _updateMaterialSet,
    ] = useAxios(
        {
            method: "PUT",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    useEffect(() => {
        if (data?.materialSets?.length) {
            setSets(() =>
                data.materialSets.map(entry => ({
                    ...entry,
                    type: getType(entry),
                })),
            );
        }
    }, [data]);

    const _deleteMaterialSetFromState = (index: number): void => {
        setSets(draft => {
            draft.splice(index, 1);
            return draft;
        });
    };

    const addItemToMaterialSet = item => {
        _addItemToMaterialSet(item, indexSetToExtend);
    };

    const _addItemToMaterialSet = (item, setIndex) => {
        setSets(draft => {
            //TODO do something for new set
            if (!draft[setIndex].addedIds) {
                draft[setIndex].addedIds = new Set();
            }

            if (!draft[setIndex].removedIds) {
                draft[setIndex].removedIds = new Set();
            }

            if (draft[setIndex].removedIds.has(item.materialVersionId)) {
                draft[setIndex].removedIds.delete(item.materialVersionId);
            } else {
                draft[setIndex].addedIds.add(item.materialVersionId);
            }
            // TODO if set is new add item to materials
            return draft;
        });
        setIndexSetToExtend();
    };

    const _deleteItemFromMaterialSet = (
        materialVersionId: string,
        setIndex: number,
    ): void => {
        setSets(draft => {
            if (!draft[setIndex].removedIds) {
                draft[setIndex].removedIds = new Set();
            }

            if (!draft[setIndex].addedIds) {
                draft[setIndex].addedIds = new Set();
            }

            if (draft[setIndex].addedIds.has(materialVersionId)) {
                draft[setIndex].addedIds.delete(materialVersionId);
            } else {
                draft[setIndex].removedIds.add(materialVersionId);
            }
            // TODO if set is new delete item from materials
            return draft;
        });
    };

    const addItemsToChangedVersionIds = (
        setIndex: number,
        versions: {
            [string]: any,
        },
    ) => {
        setSets(draft => {
            if (!draft[setIndex].changedVersionIds) {
                draft[setIndex].changedVersionIds = {};
            }

            Object.keys(versions).forEach(oldMaterialVersionId => {
                draft[setIndex].changedVersionIds[oldMaterialVersionId] =
                    versions[oldMaterialVersionId].materialVersionId;
            });

            return draft;
        });
    };

    const updateMaterialSetName = (value: string, setIndex: number): void => {
        setSets(draft => {
            draft[setIndex].materialSetName = value;
            return draft;
        });
    };

    const updateMaterialSetQuery = (value: string, setIndex: number): void => {
        setSets(draft => {
            draft[setIndex].query = value;
            return draft;
        });
    };

    const addMaterialSet = (items = [], type = SetType.SELECTION) => {
        setIndexNewSet(sets.length);
        setSets(() => [
            ...sets,
            {
                materialSetName: "",
                type: type,
                materials: items,
                editMode: true,
                addedIds: new Set(items.map(item => item.materialVersionId)),
            },
        ]);
    };

    const deleteMaterialSet = async (url: string, setIndex: number) => {
        try {
            await _deleteSet({ url });
            _deleteMaterialSetFromState(setIndex);
            addNotification({
                type: "success",
                body: "MaterialSet successfully deleted",
                autoHide: true,
                timeout: 5000,
            });
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
        }
    };

    const cancelMaterialSetEdit = (index: number): void => {
        if (index === indexNewSet) {
            _deleteMaterialSetFromState(index);
        } else {
            setSets(draft => {
                draft[index] = {
                    ...data.materialSets[index],
                    type: getType(data.materialSets[index]),
                };
                return draft;
            });
        }
        setIndexNewSet();
    };

    const createMaterialSet = async (
        url: string,
        setData: { [string]: any },
    ): Promise<boolean> => {
        try {
            const { data } = await _createMaterialSet({ url, data: setData });
            setSets(draft => {
                draft[indexNewSet] = {
                    ...draft[indexNewSet],
                    materialSetId: data.materialSetId,
                };
                return draft;
            });
            setIndexNewSet();
            addNotification({
                type: "success",
                body: "Set successfully created",
                autoHide: true,
                timeout: 5000,
            });
            fetchMaterialSetData();
            return true;
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
            return false;
        }
    };

    const updateMaterialSet = async (
        url: string,
        data: { [string]: any },
    ): Promise<boolean> => {
        try {
            await _updateMaterialSet({ url, data });
            addNotification({
                type: "success",
                body: "Set successfully updated",
                autoHide: true,
                timeout: 5000,
            });
            fetchMaterialSetData();
            return true;
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
            return false;
        }
    };

    const deleteItemFormMaterialSet = (
        element: {
            [string]: any,
        },
        indexSet: number,
    ): void => {
        _deleteItemFromMaterialSet(element.materialVersionId, indexSet);
    };

    const changeMaterialVersionRotation = (
        indexSet: number,
        materialVersionId: string,
        rotation: number,
    ): void => {
        setSets(draft => {
            if (!draft[indexSet].changedRotations) {
                draft[indexSet].changedRotations = {};
            }

            draft[indexSet].changedRotations[materialVersionId] = rotation;

            return draft;
        });
    };

    useEffect(() => {
        if (
            sets.length &&
            (indexNewSet || indexNewSet === 0) &&
            typeof window !== "undefined"
        ) {
            window.scrollTo(0, document.body?.scrollHeight);
        }
    }, [sets.length, indexNewSet]);

    return [
        {
            sets,
            updateSetData,
            loading,
            indexNewSet,
            indexSetToExtend,
            deleteSetLoading,
            createSetLoading,
            updateSetLoading,
        },
        cancelMaterialSetEdit,
        setIndexSetToExtend,
        updateMaterialSetName,
        updateMaterialSetQuery,
        addItemToMaterialSet,
        addMaterialSet,
        deleteMaterialSet,
        createMaterialSet,
        updateMaterialSet,
        deleteItemFormMaterialSet,
        fetchMaterialSetData,
        changeMaterialVersionRotation,
        addItemsToChangedVersionIds,
    ];
};

const getType = set => (set.query ? SetType.SUBSCRIPTION : SetType.SELECTION);

export default useMaterialSets;
