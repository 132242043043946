import * as Yup from "yup";

export default (metaData, user) => ({
    initialValues:
        metaData && user
            ? {
                  ...metaData,
                  projectManager: `${user?.name} ${user?.family_name}`,
                  projectName: `Copy of ${metaData.projectName}`,
                  company: metaData?.companyId,
                  priority: 25,
                  copyMaterialSets: true,
                  copyViewSets: true,
                  copyMaterialVersions: false,
                  copyPairings: false,
                  description: metaData?.projectDescription,
              }
            : {
                  projectName: "",
                  company: "",
                  projectManager: "",
                  publishToAprimo: "false",
                  isQcProject: "false",
                  description: "",
                  priority: 25,
                  renderFilenameTemplate:
                      "{projectName}_{sceneName}_[materials1.skuId]_{materials1.brand}_{materials1.collection}_{materials1.design}_{materials1.colour}_{viewName}",
              },
    validationSchema: Yup.object({
        projectName: Yup.string().required("Project name field is required"),
        company: Yup.string().required("Company field is required"),
        projectManager: Yup.string().required(
            "Project manager field is required",
        ),
        publishToAprimo: Yup.string().required(
            "Publish to Aprimo field is required",
        ),
        description: Yup.string().required("Description field is required"),
        renderFilenameTemplate: Yup.string().required(
            "Filename template field is required",
        ),
    }),
});
