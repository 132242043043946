// @flow
import {
    Heading,
    Modal,
    useNotifications,
} from "@brutextiles/web-component-library";
import { useInterval } from "@restart/hooks";
import useAxios from "axios-hooks";
import React, { type Node, Fragment, useEffect, useState } from "react";

import { Permissions } from "../../api/permissions";
import { ErrorHandling, RenderDetails, RenderTable } from "../../components";
import defaultColumns from "../../components/RenderDetails/defaultColumns";
import { usePermissions } from "../../hooks";
import { dateTimeFormatter } from "../../utils/formatter";
import UpdateProjectHeading from "../UpdateProjectHeading";
import renderDetailsSettings from "./settings/render-details";
import tableSettings from "./settings/render-table";
import tableActions from "./settings/table-actions";

type Props = {
    projectName: string,
    projectId: string,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    readOnly: boolean,
};

const resolutionOptions = [
    { value: "EIGHT_K", label: "8K" },
    { value: "SIX_K", label: "6K" },
    { value: "FOUR_K", label: "4K" },
    { value: "TWO_K", label: "2K" },
    { value: "FULL_HD", label: "Full HD" },
];

const ProjectRenders = ({
    readOnly,
    projectName,
    projectId,
    isAutomaticRendering = false,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
}: Props): Node => {
    const { addNotification } = useNotifications();
    const [renderAllStatus, setRenderAllStatus] = useState();
    const [showRenderConfirmation, setShowRenderConfirmation] = useState(false);
    const [canManage, canRender, canDownload, canSeeErrorCause] =
        usePermissions([
            Permissions.PROJECT_MANAGE,
            Permissions.PROJECT_RENDER,
            Permissions.PROJECT_DOWNLOAD,
            Permissions.ERROR_CAUSE,
        ]);

    const [{ data: renderAllStatusData }, getRenderAllStatus] = useAxios(
        {
            url: `/ams-api/project/${projectId}/ird-creation-status`,
        },
        { useCache: false, manual: true },
    );

    useEffect(() => {
        setRenderAllStatus(renderAllStatusData?.status);

        if (renderAllStatusData?.status === "CREATING") {
            addNotification({
                type: "warning",
                body: "The image jobs are in progress",
                autoHide: true,
                timeout: 5000,
            });
        }
    }, [renderAllStatusData]);

    useInterval(() => {
        if (renderAllStatus !== "READY") getRenderAllStatus();
    }, 5000);

    const [{ data: todoData }, fetchTodoData] = useAxios(
        {
            url: `/ams-api/project/${projectId}/image-request-definition/TO_DO`,
        },
        { manual: true, useCache: false },
    );

    const [{ loading: renderProjectLoading }, renderProject] = useAxios(
        {
            url: `/ams-api/project/${projectId}/imageRequest`,
            method: "POST",
            params: {
                force: false,
            },
        },
        { manual: true, useCache: false },
    );

    const [{ loading: renderBatchLoading }, renderBatch] = useAxios(
        {
            url: `/ams-api/project/${projectId}/imageRequest/batch`,
            method: "POST",
            params: {
                force: false,
            },
        },
        {
            manual: true,
            useCache: false,
        },
    );

    const [{ loading: rerenderBatchLoading }, rerenderBatch] = useAxios(
        {
            url: `/ams-api/project/${projectId}/imageRequest/batch/retry`,
            method: "POST",
            params: {
                force: false,
            },
        },
        {
            manual: true,
            useCache: false,
        },
    );

    const [, downloadBatch] = useAxios(
        {
            url: `/ams-api/project/${projectId}/image-request-definition/archive/content`,
            method: "POST",
            params: {
                //@todo: remove once the backend can get this value from the token.
                subject: "test",
            },
        },
        {
            manual: true,
            useCache: false,
        },
    );

    const [, downloadProject] = useAxios(
        {
            url: `/ams-api/project/archive/content?projectId=${projectId}`,
            method: "POST",
            params: {
                subject: "test",
            },
        },
        {
            manual: true,
            useCache: false,
        },
    );

    const [{ data: fileData, loading: fileInfoLoading }, getFileUrl] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    const showSuccessMessage = (message: string): void => {
        addNotification({
            type: "success",
            body: message,
            autoHide: true,
            timeout: 5000,
        });
    };

    const showErrorNotification = () =>
        addNotification({
            type: "danger",
            body: "Something went wrong. Please try again later",
            autoHide: true,
            timeout: 5000,
        });

    useEffect(() => {
        if (fileData?.url) {
            window.open(fileData?.url);
        }
    }, [fileData]);

    const handleConfirm = async () => {
        try {
            await renderProject();
            showSuccessMessage(
                "The render process of all images will start soon",
            );
        } catch {
            showErrorNotification();
        } finally {
            setShowRenderConfirmation(false);
        }
    };

    const handleAction = async (action: string, selected: string[]) => {
        if (action === "render_all") {
            fetchTodoData();
            setShowRenderConfirmation(true);
        } else if (action === "render") {
            try {
                await renderBatch({
                    data: { imageRequestDefinitionIds: selected },
                });
                showSuccessMessage(
                    "The render process of the selected images will start soon.",
                );
            } catch {
                showErrorNotification();
            }
        } else if (action === "download") {
            try {
                await downloadBatch({
                    data: selected,
                });
                showSuccessMessage(
                    "You will soon receive a mail with a download link for the selected renders.",
                );
            } catch {
                showErrorNotification();
            }
        } else if (action === "download_all") {
            try {
                await downloadProject({
                    data: selected,
                });
                showSuccessMessage(
                    "You will soon receive a mail with a download for all renders.",
                );
            } catch {
                showErrorNotification();
            }
        } else if (action === "rerender") {
            await rerenderBatch({
                data: { imageRequestDefinitionIds: selected },
            });
            showSuccessMessage(
                "The re-render process of the selected images will start soon.",
            );
        }
    };

    return (
        <Fragment>
            <ErrorHandling />
            <Modal
                size={"lg"}
                confirmation
                open={showRenderConfirmation}
                title={
                    todoData?.toDoImageRequestDefinitionCount > 0
                        ? "confirmation"
                        : "notification"
                }
                onCancel={() => setShowRenderConfirmation(false)}
                actions={
                    todoData?.toDoImageRequestDefinitionCount > 0
                        ? [
                              {
                                  type: "primary",
                                  label: "Yes",
                                  action: handleConfirm,
                              },
                              {
                                  type: "secondary",
                                  label: "No",
                                  action: () =>
                                      setShowRenderConfirmation(false),
                              },
                          ]
                        : [
                              {
                                  type: "primary",
                                  label: "Close",
                                  action: () =>
                                      setShowRenderConfirmation(false),
                              },
                          ]
                }
            >
                {todoData?.toDoImageRequestDefinitionCount > 0 ? (
                    <>
                        Are you sure your want to render{" "}
                        <strong className="text-secondary">
                            {todoData?.toDoImageRequestDefinitionCount}
                        </strong>{" "}
                        items?
                    </>
                ) : (
                    "There are no items to render."
                )}
            </Modal>
            <UpdateProjectHeading
                readOnly={readOnly}
                toggleAutomaticRendering={handleToggleAutomaticRendering}
                isAutomaticRendering={isAutomaticRendering}
                projectName={projectName}
            />
            <Heading title={"Render table"} level={5} />
            <RenderTable
                settings={tableSettings}
                actions={({ itemsCount }) =>
                    tableActions(
                        canManage,
                        canRender,
                        canDownload,
                        renderAllStatus === "READY",
                        itemsCount > 0,
                    )
                }
                onAction={handleAction}
                fileInfoLoading={fileInfoLoading}
                onSelectDownload={(type, render) => {
                    if (type === ".jpeg") {
                        getFileUrl({
                            url: `/ams-api/rendition/image-request-definition/${render.imageRequestDefinitionId}/content`,
                        });
                    } else {
                        getFileUrl({
                            url: `/ams-api/rendition/image-request-definition/${render.imageRequestDefinitionId}/intermediate-renders?intermediateRenderType=${type}`,
                        });
                    }
                }}
                apiBase={`dap-search-service/project/${projectId}/image-request-definition`}
                idField="imageRequestDefinitionId"
                actionLoading={
                    renderProjectLoading ||
                    renderBatchLoading ||
                    rerenderBatchLoading
                }
                renderDetails={render => (
                    <ProjectRendersRenderDetails
                        render={render}
                        canSeeErrorCause={canSeeErrorCause}
                    />
                )}
            />
        </Fragment>
    );
};

type ProjectRendersRenderDetailsProps = {
    render: { [key: string]: string },
    canSeeErrorCause: boolean,
};

const ProjectRendersRenderDetails = ({
    render,
    canSeeErrorCause,
}: ProjectRendersRenderDetailsProps): Node => {
    const [
        { data: renderDetailsData, loading: renderDetailsLoading },
        loadRenderDetails,
    ] = useAxios({}, { manual: true, useCache: false });

    useEffect(() => {
        if (render?.imageRequestDefinitionId) {
            loadRenderDetails({
                url: `/ams-api/project/image-request-definition/${render?.imageRequestDefinitionId}`,
            });
        }
    }, [render]);

    return (
        <RenderDetails
            formData={{
                ...renderDetailsData,
                resolution:
                    renderDetailsData &&
                    resolutionOptions.find(
                        el =>
                            el.value === renderDetailsData.resolution ||
                            el.label === renderDetailsData.resolution,
                    )?.label,
                imageRequestDefinitionCreationTime: dateTimeFormatter(
                    renderDetailsData?.imageRequestDefinitionCreationTime,
                ),
            }}
            canSeeErrorCause={canSeeErrorCause}
            tableData={renderDetailsData?.components || []}
            formSettings={renderDetailsSettings}
            tableSettings={{
                columns: [
                    ...defaultColumns,
                    {
                        key: "materialVersion",
                        label: "Version",
                    },
                ],
                searchCategoryLimits: { min: 5, max: 50 },
            }}
            thumbnailUrl={renderDetailsData?.thumbnailUrl}
            loading={renderDetailsLoading}
        />
    );
};

export default ProjectRenders;
