//@flow

import { Button, Icon, Trash } from "@brutextiles/web-component-library";
import React, { type Node } from "react";
import { Col, Row } from "reactstrap";

import MultiSelectDropdown from "../MultiSelectDropdown";
import type { Option } from "../MultiSelectDropdown/types/option.d";
import style from "./style.module.scss";
import mapComponents from "./utils/map-components";
import mapMaterialSets from "./utils/map-material-sets";

type Props = {
    index?: number,
    pairingIdx?: number,
    errors?: any,
    singleMaterial: boolean,
    componentOptions: Option[],
    materialSetOptions: Option[],
    onChange: (string, any) => void,
    onRemove: () => void,
    combination: any,
    disabled?: boolean,
};

const CombinationRow = ({
    index = 0,
    pairingIdx = 0,
    errors = {},
    combination = { components: [], materialSets: [], allComponents: false },
    singleMaterial,
    componentOptions = [],
    materialSetOptions = [],
    onChange: handleChange,
    onRemove: handleRemove,
    disabled,
}: Props): Node => (
    <div className={style.pairingRow}>
        <Row>
            <Col className={"align-top"}>
                <MultiSelectDropdown
                    id={`pairings-components-${pairingIdx}-${index}`}
                    error={errors[`combination-${index}-components`]}
                    disabled={singleMaterial || disabled}
                    values={mapComponents(combination.components)}
                    onChange={items => handleChange("components", items)}
                    options={componentOptions}
                    placeholder={
                        singleMaterial ? "All components" : "Components"
                    }
                />
            </Col>
            <Col className={"align-top"}>
                <MultiSelectDropdown
                    id={`pairings-materialSets-${pairingIdx}-${index}`}
                    disabled={disabled}
                    error={errors[`combination-${index}-materialSets`]}
                    isMulti={false}
                    values={mapMaterialSets(combination.materialSets)}
                    onChange={item => handleChange("materialSets", [item])}
                    options={materialSetOptions}
                    placeholder={"Material sets"}
                />
            </Col>
            {!singleMaterial && (
                <Col xs="auto" className="mr-2">
                    <Button
                        disabled={disabled}
                        color="white"
                        outline
                        onClick={handleRemove}
                    >
                        <Icon variant="danger" icon={Trash} />
                    </Button>
                </Col>
            )}
        </Row>
    </div>
);

export default CombinationRow;
