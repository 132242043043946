// @flow
import { Modal, SideNav } from "@brutextiles/web-component-library";
import { Location } from "@reach/router";
import { useInterval } from "@restart/hooks";
import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import { enableMapSet } from "immer";
import React, {
    type Node,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Helmet } from "react-helmet";

import useAuth from "../../hooks/use-auth";
import useTenant, { findTenantId } from "../../hooks/use-tenant";
import logo from "../../images/twinbru_engine_logo.png";
import { FormContext } from "../../providers/form-provider";
import buildMenuItems from "./menu-items";
import style from "./shell.module.scss";

const qas = { hostName: "engine-qas.twinbru.com", color: "#fff7ec" };
const dev = { hostName: "dev.engine-qas.twinbru.com", color: "#f1f8e9" };

enableMapSet();

type Props = {
    search?: any,
    children: Node,
    path: string,
};

const Shell = ({ search, children, path }: Props): Node => {
    const [navigateTo, setNavigateTo] = useState();
    const { dirty, setDirty } = useContext(FormContext);
    const [, setTenant] = useTenant();

    const params = new URLSearchParams(search);
    const paramTenantId = params.get("tenantId");

    const [{ data: renderQueueData }, fetchRenderQueueSize] = useAxios(
        {
            url: "/ams-api/render-planner/render-batch-queue-size",
        },
        {
            useCache: false,
            manual: typeof window === `undefined`,
        },
    );

    useInterval(() => {
        fetchRenderQueueSize();
    }, 15 * 60 * 1000);

    const [{ data: tenantsData = [] }, fetchTenants] = useAxios(
        {
            url: "/auth/tenants",
        },
        {
            useCache: false,
            manual: true,
        },
    );

    useEffect(() => {
        if (paramTenantId) {
            setTenant(paramTenantId);
        }

        typeof window !== `undefined` && fetchTenants();
    }, []);

    const {
        state: { user, loggedIn },
        login,
        logout,
        hasPermission,
    } = useAuth();

    useEffect(() => {
        if (paramTenantId) {
            setTenant(paramTenantId);
        } else if (typeof window !== "undefined" && tenantsData) {
            const tenantId = findTenantId(tenantsData);
            if (tenantId) {
                setTenant(tenantId);
            }
        }
    }, [tenantsData]);

    const menuItems = useMemo(() => buildMenuItems(hasPermission), [user]);

    const currentPage =
        menuItems.find(menuItem => path.includes(menuItem.path))?.path || "";

    const logoImg = (
        <img src={logo} className={style.bruLogo} alt="Logo Twinbru" />
    );

    const envStyle = useMemo(() => {
        const envStyle = { backgroundColor: "transparent" };

        if (typeof window !== "undefined") {
            const hostName = window?.location?.hostname;
            if (hostName === dev.hostName) {
                envStyle.backgroundColor = dev.color;
            } else if (hostName === qas.hostName) {
                envStyle.backgroundColor = qas.color;
            }
        }

        return envStyle;
    }, []);

    if (!loggedIn) {
        login();
    }

    return (
        loggedIn && (
            <div className={"d-flex flex-nowrap"} style={envStyle}>
                <Helmet>
                    <title>Twinbru Engine</title>
                </Helmet>
                <div className={style.sideNav}>
                    <Location>
                        {({ location }) => (
                            <SideNav
                                menuItems={menuItems}
                                user={{
                                    firstName: user?.name,
                                    lastName: user?.family_name,
                                }}
                                logo={logoImg}
                                onPageSelect={path => {
                                    if (
                                        dirty &&
                                        location.pathname.match(
                                            /^\/(materials|scenes|noise-masks|companies|projects)\/(create|update|history)/,
                                        )
                                    ) {
                                        setNavigateTo(path);
                                    } else {
                                        navigate(path);
                                    }
                                }}
                                currentPage={currentPage}
                                onLogout={logout}
                                bottomElement={() => {
                                    if (tenantsData && tenantsData.length > 1) {
                                        const selected = tenantsData.find(
                                            item =>
                                                window.location.href.startsWith(
                                                    item.hostName,
                                                ),
                                        );

                                        return (
                                            <div
                                                className={
                                                    style.tenantAutocomplete
                                                }
                                            >
                                                <select
                                                    value={selected?.hostName}
                                                    onChange={event => {
                                                        if (
                                                            event.target.value
                                                        ) {
                                                            const entry =
                                                                tenantsData.find(
                                                                    item =>
                                                                        item.hostName ===
                                                                        event
                                                                            .target
                                                                            .value,
                                                                );

                                                            if (entry) {
                                                                setTenant(
                                                                    entry.tenantId,
                                                                );
                                                                window.location.href =
                                                                    event.target.value;
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {tenantsData.map(item => (
                                                        <option
                                                            key={item.hostName}
                                                            value={
                                                                item.hostName
                                                            }
                                                        >
                                                            {item.description}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        );
                                    }
                                }}
                            />
                        )}
                    </Location>
                </div>
                <div className={style.pageWrapper}>
                    <div className={style.content}>{children}</div>
                    <div className={style.renderJobs}>
                        Render jobs in queue: {renderQueueData?.queueSize || 0}
                    </div>
                </div>
                <Modal
                    confirmation
                    open={navigateTo}
                    title={"confirmation"}
                    onCancel={() => setNavigateTo()}
                    actions={[
                        {
                            type: "primary",
                            label: "Yes",
                            action: () => {
                                navigate(navigateTo);
                                setNavigateTo();
                                setDirty(false);
                            },
                        },
                        {
                            type: "secondary",
                            label: "No",
                            action: () => setNavigateTo(),
                        },
                    ]}
                >
                    Changes you made may not be saved. Do you want to continue?
                </Modal>
            </div>
        )
    );
};

export default Shell;
