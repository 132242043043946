// @flow

import React, { type Node, createContext, useReducer } from "react";

export type Permission = {
    id: string,
    endpoints: string[],
};

export type Auth = {
    user: ?{
        family_name: string,
        name: string,
        sub: string,
        email_verified: string,
        identities: string,
        email: string,
        permissions?: Permission[],
        roles?: string[],
        username: string,
    },
    loggedIn: boolean,
};

const DEFAULT_STATE = {
    user: null,
    loggedIn: false,
};

const reducer = (state, action): Auth => {
    if (action.type === "LOGIN") {
        return { user: action.payload, loggedIn: true };
    } else {
        return DEFAULT_STATE;
    }
};

export const AuthContext: React$Context<*> = createContext();

const AuthProvider = ({ children }: *): Node => (
    <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
        {children}
    </AuthContext.Provider>
);
export default AuthProvider;
