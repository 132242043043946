export default [
    {
        label: "Company",
        key: "companyId",
        placeholder: "Select company",
        inputType: "select",
        required: true,
    },
    {
        label: "Floor type",
        key: "floorType",
        placeholder: "Select floor type",
        allowCreate: true,
        createOnLeave: true,
        inputType: "select",
        required: true,
    },
    {
        label: "Scene name",
        key: "sceneName",
        inputType: "text",
        required: true,
    },
    {
        label: "Wall colour",
        key: "wallColour",
        placeholder: "Select wall colour",
        allowCreate: true,
        createOnLeave: true,
        inputType: "select",
        required: true,
    },
    {
        label: "Asset type",
        key: "assetType",
        placeholder: "Select asset type",
        allowCreate: true,
        createOnLeave: true,
        inputType: "select",
        required: true,
    },
    {
        label: "Light setting",
        key: "lightSetting",
        placeholder: "Select light setting",
        allowCreate: true,
        createOnLeave: true,
        inputType: "select",
        required: true,
    },
    {
        label: "Room type",
        key: "roomType",
        placeholder: "Select room type",
        allowCreate: true,
        createOnLeave: true,
        inputType: "select",
        required: true,
    },
];
