// @flow
import {
    DetailsForm,
    Modal,
    useNotifications,
} from "@brutextiles/web-component-library";
import { useFormik } from "formik";
import React, { type Node, useContext, useEffect, useState } from "react";

import { ErrorHandling } from "../../../../components";
import { useErrorHandlingWithForm, useSettings } from "../../../../hooks";
import useAuth from "../../../../hooks/use-auth";
import { FormContext } from "../../../../providers/form-provider";
import cloneFormSettings from "../../settings/create-form";
import validationSettings from "../../settings/validations";

const formKey = "clone-project-form";

type Props = {
    show: boolean,
    metaData: any,
    toggle: () => void,
    create: any => Promise<any>,
    createLoading: boolean,
    usersOptions: { value: string, label: string }[],
    companiesOptions: { value: string, label: string }[],
};

const CloneProject = (props: Props): Node => {
    const { addNotification } = useNotifications();
    const {
        show,
        toggle,
        metaData,
        createLoading,
        create,
        usersOptions,
        companiesOptions,
    } = props;
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);

    const {
        state: { user },
    } = useAuth();

    const { settings, updateSelectOptions, updateSettings } = useSettings(
        cloneFormSettings(true, true, true, true),
    );

    const formik = useFormik({
        ...validationSettings(metaData, user),
        enableReinitialize: true,
        onSubmit: () => {
            !createLoading &&
                create({
                    url: `/ams-api/project/${metaData.projectId}/clone`,
                    data: {
                        companyId: formik.values.company,
                        projectName: formik.values.projectName,
                        projectDescription: formik.values.description,
                        publishToAprimo: formik.values.publishToAprimo,
                        projectManager: formik.values.projectManager,
                        costCenter: formik.values.costCenter,
                        isQcProject: formik.values.isQcProject,
                        renderFilenameTemplate:
                            formik.values.renderFilenameTemplate,
                        priority: formik.values.priority,
                        copyViewSets: formik.values.copyViewSets,
                        copyMaterialSets: formik.values.copyMaterialSets,
                        copyMaterialVersions:
                            formik.values.copyMaterialVersions || false,
                        copyPairings: formik.values.copyPairings,
                    },
                }).then(response => {
                    if (response?.data?.projectId) {
                        toggle();
                        addNotification({
                            type: "success",
                            body: "Cloning process started successfully. New project will be available soon.",
                            autoHide: true,
                            timeout: 5000,
                        });
                    }

                    return response;
                });
        },
    });

    useErrorHandlingWithForm(formKey, formik.errors);

    useEffect(() => {
        updateSelectOptions("projectManager", usersOptions);
    }, [usersOptions]);

    useEffect(() => {
        updateSelectOptions("company", companiesOptions);
    }, [companiesOptions]);

    const { setDirty } = useContext(FormContext);

    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    const submit = () => {
        setShouldValidateOnChange(true);
        formik.handleSubmit();
    };

    return (
        <>
            {show && (
                <Modal
                    size={"lg"}
                    title={"Clone Project"}
                    open
                    onCancel={() => {
                        toggle();
                        formik.resetForm();
                    }}
                    actions={[
                        {
                            type: "primary",
                            label: "clone",
                            action: submit,
                            disabled: createLoading,
                        },
                        {
                            type: "secondary",
                            label: "cancel",
                            action: () => {
                                toggle();
                                formik.resetForm();
                            },
                        },
                    ]}
                    fullWidthActions
                >
                    <ErrorHandling
                        showOnly={[
                            // eslint-disable-next-line smells/no-complex-string-concat
                            "/ams-api/project/" + metaData.projectId + "/clone",
                            formKey,
                        ]}
                    />
                    <DetailsForm
                        settings={settings}
                        data={formik.values}
                        onChange={data => {
                            formik.setFieldValue(
                                data.key,
                                data.value,
                                shouldValidateOnChange,
                            );

                            if (data.key === "copyMaterialSets") {
                                updateSettings(
                                    cloneFormSettings(
                                        true,
                                        data.value,
                                        true,
                                        data.value &&
                                            formik.values.copyViewSets,
                                    ),
                                );
                            }

                            if (data.key === "copyViewSets") {
                                updateSettings(
                                    cloneFormSettings(
                                        true,
                                        formik.values.copyMaterialSets,
                                        true,
                                        formik.values.copyMaterialSets &&
                                            data.value,
                                    ),
                                );
                            }
                        }}
                        errors={formik.errors}
                    />
                </Modal>
            )}
        </>
    );
};

export default CloneProject;
