//@todo remove this line when the projectMangers can be fetched
export default readOnly => {
    return [
        {
            label: "Company ID",
            key: "companyId",
            inputType: "text",
            disabled: true,
        },
        {
            label: "Publish to Aprimo",
            key: "publishToAprimo",
            inputType: "checkbox",
            disabled: true,
        },
        {
            label: "Project name",
            key: "projectName",
            inputType: "text",
            disabled: readOnly,
        },
        {
            label: "Quality control project",
            key: "isQcProject",
            inputType: "checkbox",
            disabled: readOnly,
        },
        {
            label: "Project mananger",
            key: "projectManager",
            inputType: readOnly ? "text" : "select",
            selectOptions: [],
            disabled: readOnly,
        },
        {
            label: "Cost center",
            key: "costCenter",
            inputType: readOnly ? "text" : "select",
            disabled: readOnly,
            selectOptions: [
                { value: "Development", label: "Development" },
                { value: "Marketing", label: "Marketing" },
                { value: "Customer Projects", label: "Customer Projects" },
                { value: "Partner Projects", label: "Partner Projects" },
            ],
        },
        {
            label: "Priority",
            key: "priority",
            inputType: "select",
            width: 6,
            readOnly: true,
            required: true,
            selectOptions: [
                { value: 1, label: "Low" },
                { value: 25, label: "Medium" },
                { value: 50, label: "High" },
                { value: 100, label: "Urgent" },
            ],
        },
        {
            label: "Filename template",
            key: "renderFilenameTemplate",
            inputType: "text",
            disabled: readOnly,
            width: 12,
        },
        {
            label: "Description",
            key: "projectDescription",
            inputType: "textarea",
            disabled: readOnly,
            width: 12,
        },
    ];
};
