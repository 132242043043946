// @flow

import {
    ArrowLeftDown,
    ArrowRightUp,
    Check,
} from "@brutextiles/web-component-library";
import type { Element } from "react";
import React from "react";

import type { Action } from "../../../templates/OverviewTable/types/action.d";

export type State = {
    key: string,
    label: string,
};

export const Actions = {
    REJECT: "reject",
    APPROVE: "confirm_approval",
    SEND_FOR_APPROVAL: "send_for_approval",
    PUBLISH: "publish",
    UNPUBLISH: "unpublish",
};

export const workflowActions = (
    canManage: boolean,
    canApprove: boolean,
    canPublish: boolean,
): Action[] => [
    {
        label: "Send For Approval",
        action: Actions.SEND_FOR_APPROVAL,
        icon: Check,
        show: selected => {
            let states = getStates(selected);
            return states.has(States.DRAFT.label) && states.size === 1;
        },
        enable: {
            condition: () => canManage,
        },
    },
    {
        label: "Approve/Reject",
        action: Actions.APPROVE,
        icon: Check,
        show: selected => {
            let states = getStates(selected);
            return (
                states.has(States.AWAITING_APPROVAL.label) && states.size === 1
            );
        },
        enable: {
            condition: () => canApprove,
        },
    },
    {
        label: "Publish",
        action: Actions.PUBLISH,
        icon: ArrowRightUp,
        show: selected => {
            let states = getStates(selected);
            return states.has(States.APPROVED.label) && states.size === 1;
        },
        enable: {
            condition: () => canPublish,
        },
    },
    {
        label: "Unpublish",
        action: Actions.UNPUBLISH,
        icon: ArrowLeftDown,
        show: selected => {
            let states = getStates(selected);
            return states.has(States.PUBLISHED.label) && states.size === 1;
        },
        enable: {
            condition: () => canPublish,
        },
    },
];

export const States = {
    INIT: { label: "Init" },
    PREPARING: { label: "Preparing", color: "#AAC8FD" },
    DRAFT: { label: "Draft", color: "#0960FB" },
    AWAITING_REVIEW: { label: "Awaiting review" },
    REVIEW_REJECTED: { label: "Review rejected" },
    AWAITING_APPROVAL: { label: "Awaiting approval", color: "#FF7C1C" },
    APPROVAL_REJECTED: { label: "Approval rejected" },
    APPROVED: { label: "Approved", color: "#FFBD12" },
    REJECTED: { label: "Rejected", color: "#EE5C5C" },
    AWAITING_PUBLICATION: { label: "Awaiting publication" },
    PUBLICATION_REJECTED: { label: "Publication rejected" },
    PUBLISHED: { label: "Published", color: "#2BC044" },
};

export const getStates = (selected: { [key: string]: any }): Set<string> =>
    selected?.reduce(
        (acc, row) => acc.add(row?.state?.props?.children),
        new Set(),
    ) || new Set();

export const mapState = (state?: string): any =>
    (state && States[state]) || "Unknown";

export const getStateBadge = (value?: string): Element<"span"> => {
    const state = mapState(value);
    return (
        <span
            className="badge"
            style={{ background: state.color || "#B2B2B2", color: "white" }}
        >
            {state.label}
        </span>
    );
};
