//@flow

import type { Option } from "../../MultiSelectDropdown/types/option.d";

export default (results: any): Option[] =>
    results?.map(({ sceneVersionId, sceneName, components }) => ({
        label: sceneName,
        options: components.map(({ componentId, componentDescription }) => ({
            label: `${sceneName}: ${componentDescription}`,
            value: `${sceneVersionId}#${componentId}`,
            componentId,
            sceneVersionId,
        })),
    })) || [];
