//@flow

import { Icon, Modal, Plus, Trash } from "@brutextiles/web-component-library";
import React, { type Node, Fragment } from "react";
import { Button, Col, Input, InputGroup, Row } from "reactstrap";
import { useImmer } from "use-immer";

import ErrorHandling from "../ErrorHandling";
import style from "./style.module.scss";

type Props = {
    errorKey: string,
    columns: {
        key: string,
        label: string,
        meta?: boolean,
    }[],
    onClose: () => void,
    onSubmit: (string[]) => Promise<void>,
    loading: boolean,
};

const ColumnCustomizer = ({
    errorKey,
    columns: _columns,
    onClose: handleClose,
    onSubmit: handleSubmit,
    loading,
}: Props): Node => {
    const [columns, setColumns] = useImmer(_columns);

    const updateColumn = (index: number, value: string): void => {
        setColumns(draft => {
            draft[index].key = value;
            draft[index].label = value;
            return draft;
        });
    };

    const deleteColumn = (index: number): void => {
        setColumns(draft => {
            draft.splice(index, 1);
            return draft;
        });
    };

    const addColumn = (): void => {
        setColumns(draft => {
            draft.push({
                key: "",
                label: "",
                meta: true,
                new: true,
            });
            return draft;
        });
    };

    const submit = (): void => {
        handleSubmit(
            columns
                .filter(column => column.meta && column.key)
                .map(column => column.key),
        );
    };

    return (
        <Fragment>
            <Modal
                open
                size={"lg"}
                fullWidthActions
                title="customize column"
                onCancel={handleClose}
                actions={[
                    {
                        type: "primary",
                        label: "save",
                        action: submit,
                        disable: loading,
                    },
                    {
                        type: "secondary",
                        label: "cancel",
                        action: handleClose,
                    },
                ]}
            >
                <ErrorHandling showOnly={[errorKey]} />
                {columns.map((column, index) => (
                    <Row key={index} className="mb-2">
                        <Col>
                            <InputGroup>
                                <Input
                                    color="red"
                                    onChange={event =>
                                        updateColumn(index, event.target.value)
                                    }
                                    disabled={!column.meta}
                                    value={column.label}
                                ></Input>
                                {column.meta && (
                                    <Button
                                        size="sm"
                                        color="light-grey"
                                        className={style.deleteButton}
                                        outline
                                        onClick={() => deleteColumn(index)}
                                    >
                                        <Icon icon={Trash} variant="danger" />
                                    </Button>
                                )}
                            </InputGroup>
                        </Col>
                    </Row>
                ))}
                <Button
                    onClick={addColumn}
                    color="light-grey"
                    className={`w-100 ${style.addButton}`}
                    disabled={loading}
                >
                    <Icon
                        className="mb-1 mr-2"
                        variant="secondary"
                        icon={Plus}
                    />
                    Add column
                </Button>
            </Modal>
        </Fragment>
    );
};

export default ColumnCustomizer;
