//@flow

import { Heading } from "@brutextiles/web-component-library";
import React, { type Node, Fragment } from "react";
import { Input } from "reactstrap";

type Props = {
    comment: string,
    error?: string,
    onChange: string => void,
};

const Comment = ({ comment, onChange: handleChange, error }: Props): Node => (
    <Fragment>
        <Heading
            title={`Comment`}
            rightContent={`${comment?.length || 0}/255`}
            level={5}
        />
        <Input
            style={{ height: "10rem" }}
            type="textarea"
            placeholder="Type comment here"
            onChange={({ target: { value } }) => handleChange(value)}
            value={comment}
            maxLength="255"
        />
        {error && <div className="text-danger mt-1">{error}</div>}
    </Fragment>
);

export default Comment;
