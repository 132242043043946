import {
    ArrowRightUp,
    ArrowUp,
    Trash,
} from "@brutextiles/web-component-library";

export const materialTableActions = disabled => [
    {
        label: "Remove",
        value: "REMOVE",
        icon: Trash,
        enable: {
            key: "enabled",
            condition: () => !disabled,
        },
    },
    {
        label: "Update to latest",
        value: "UPDATE_TO_LATEST",
        icon: ArrowUp,
    },
    {
        label: "Update to latest published",
        value: "UPDATE_TO_LATEST_PUBLISHED",
        icon: ArrowRightUp,
    },
];
