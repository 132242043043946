//@flow
import { Thumbnail } from "@brutextiles/web-component-library";
import React from "react";

import defaultThumbnail from "../../../images/default_render_thumbnail.jpg";
import { getBadge } from "../../../utils";
import { dateTimeFormatter } from "../../../utils/formatter";

export default (results: { [string]: any }[]): { [string]: any }[] =>
    results.map(result => ({
        ...result,
        thumbnailUrl: (
            <Thumbnail imageUrl={result.thumbnailUrl || defaultThumbnail} />
        ),
        published: getBadge.publish(!!result.published),
        modificationTime: dateTimeFormatter(result.modificationTime),
    }));
