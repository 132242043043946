// @flow

import React, { type Node, Fragment } from "react";
import { Helmet } from "react-helmet";

import { Permissions } from "../../api/permissions";
import { ErrorHandling } from "../../components";
import { usePermissions } from "../../hooks";
import OverviewTable from "../../templates/OverviewTable";
import actions from "./actions";
import tableSettings from "./table-settings";
import { mapResults } from "./utils";

const SceneOverview = (): Node => {
    const [canManage, canRead, canDownload, canPublish] = usePermissions([
        Permissions.SCENE_MANAGE,
        Permissions.SCENE_READ,
        Permissions.SCENE_DOWNLOAD,
        Permissions.SCENE_PUBLISH,
    ]);

    const props = {
        title: "Scene Overview",
        apiBase: "dap-search-service/scene",
        actionApiBase: "ams-api/scene",
        canManage,
        createPageLink: "/scenes/create",
        createButtonLabel: "create scene",
        tableSettings,
        mapResults,
        actions: actions(canRead, canPublish, canDownload, canManage),
        identifiers: ["sceneId"],
        customFilter: { latestVersion: "true" },
        updateLinkBase: "scenes",
        refresh: true,
    };

    return (
        <Fragment>
            <Helmet>
                <title>Twinbru Engine - Scenes</title>
            </Helmet>
            <ErrorHandling />
            {canRead && <OverviewTable {...props} />}
        </Fragment>
    );
};

export default SceneOverview;
