// @flow
import React, { type Node } from "react";

import { SceneEdit } from "..";
import { useCreate } from "../../hooks";
import formSettings from "./settings/form-settings";
import validationSettings from "./settings/validation-settings";

const CreateScene = (): Node => {
    const submit = (
        values: { [string]: string },
        frameDefinitions: { [string]: string },
        componentDefinitions: [],
        s3FileUrl: string,
    ) => {
        create({
            url: `/ams-api/scene`,
            data: {
                comment: values["comment"],
                s3FileUrl: s3FileUrl,
                sceneType: "VRSCENE4MAX",
                materialCount: 1,
                companyId: values["companyId"],
                sceneName: values["sceneName"],
                sceneDescription: {
                    assetType: values["assetType"],
                    roomType: values["roomType"],
                    floorType: values["floorType"],
                    wallColour: values["wallColour"],
                    lightSetting: values["lightSetting"],
                },
                frameDefinitions,
                componentDefinitions,
            },
        });
    };

    const [{ createLoading }, create] = useCreate({
        overviewUrl: "/scenes",
    });

    return (
        <SceneEdit
            title="Create Scene"
            type="Create"
            onSubmit={submit}
            loading={createLoading}
            validationSettings={validationSettings}
            formSettings={formSettings}
        />
    );
};

export default CreateScene;
