//@flow

import type { Option } from "../../MultiSelectDropdown/types/option.d";

export default (results: any): Option[] =>
    results
        ? results.map(result => ({
              ...result,
              value: result.value || result.materialSetId,
              label: result.label || result.materialSetName,
          }))
        : [];
