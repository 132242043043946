//@flow

import React, { type Node } from "react";

import type { Combination } from "../../../../components/Pairing/types/pairing.d";
import CombinationRow from "../CombinationRow";
import type { Option } from "../MultiSelectDropdown/types/option.d";
import style from "./style.module.scss";

type Props = {
    pairingIdx?: number,
    errors: any,
    singleMaterial: boolean,
    componentsMaterialSetsCombinations: Combination[],
    componentOptions: Option[],
    usedComponents: Set<string>,
    materialSetOptions: Option[],
    onChange: (Combination[]) => void,
    disabled?: boolean,
};

const CombinationsTable = ({
    pairingIdx = 0,
    errors,
    singleMaterial,
    componentsMaterialSetsCombinations = [],
    componentOptions,
    usedComponents,
    materialSetOptions,
    onChange: handleChange,
    disabled = false,
}: Props): Node => (
    <div className={style.pairingTable}>
        {componentsMaterialSetsCombinations.map((combination, idx) => (
            <CombinationRow
                key={idx}
                index={idx}
                errors={errors}
                singleMaterial={singleMaterial}
                pairingIdx={pairingIdx}
                disabled={disabled && combination.combinationId !== undefined}
                combination={combination}
                componentOptions={mapOptions(componentOptions, usedComponents)}
                materialSetOptions={materialSetOptions}
                onChange={(field, values) => {
                    const updated = [...componentsMaterialSetsCombinations];
                    updated[idx][field] = values;
                    handleChange(updated);
                }}
                onRemove={() => {
                    const updated = [...componentsMaterialSetsCombinations];
                    updated.splice(idx, 1);
                    handleChange(updated);
                }}
            />
        ))}
    </div>
);

const mapOptions = (componentOptions = [], usedComponents = new Set()) => {
    return componentOptions.map((section: any) => {
        return {
            ...section,
            options: section.options.map(option => ({
                ...option,
                isDisabled: usedComponents.has(
                    `${option.componentId}:${option.sceneVersionId}`,
                ),
            })),
        };
    });
};

export default CombinationsTable;
