// @flow

import axios from "axios";
import { useContext } from "react";

import { type Auth, AuthContext } from "../providers/auth-provider";
import { buildFrontendUrl, findTenantId } from "./use-tenant";

const useAuth = (): ({|
    login: () => void,
    logout: () => void,
    state: Auth,
    unauthorized: () => void,
    hasPermission: (permission: string) => boolean,
|}) => {
    const [state, dispatcher] = useContext(AuthContext);

    const hasPermission = (permissionCode: string): boolean => {
        return (
            state?.user?.permissions.find(
                permission => permission.id === permissionCode,
            ) !== undefined
        );
    };

    const logout = (): void => {
        axios.get(`/api/auth/logout`).then(() => {
            dispatcher({ type: "LOGOUT" });
        });
    };

    const login = (): void => {
        typeof window !== `undefined` &&
            axios
                .get(`/api/auth/tenants`, {
                    headers: {
                        "frontend-url": buildFrontendUrl(),
                    },
                })
                .then(payload => {
                    const tenantId = findTenantId(payload.data);
                    window.localStorage.setItem("tenant", tenantId);

                    axios
                        .get(`/api/auth/user`, {
                            headers: {
                                "tenant-id": tenantId,
                                "frontend-url": buildFrontendUrl(),
                            },
                        })
                        .then(payload => {
                            dispatcher({
                                type: "LOGIN",
                                payload: payload.data,
                            });
                        });
                })
                .catch(() => {
                    typeof window !== `undefined` &&
                        buildLoginUrl().then(
                            loginUrl => (window.location.href = loginUrl),
                        );
                });
    };

    const unauthorized = (): void => {
        typeof window !== `undefined` &&
            buildLoginUrl().then(loginUrl => (window.location.href = loginUrl));
    };

    return { state, login, logout, unauthorized, hasPermission };
};

async function buildLoginUrl() {
    const payload = await axios.get(`/api/auth/pkce`);
    if (payload) {
        let date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie =
            // eslint-disable-next-line smells/no-complex-string-concat
            "pkce_verifier=" +
            payload.data.codeVerifier +
            "; " +
            expires +
            "; path=/";

        return `${
            process.env.GATSBY_ADB2C_AUTH_URL || ""
        }/authorize?p=B2C_1_dap_sign_in&client_id=${
            process.env.GATSBY_ADB2C_CLIENT_ID || ""
        }&nonce=defaultNonce&response_type=code&scope=openid&prompt=login&code_challenge=${
            payload.data.codeChallenge
        }&code_challenge_method=S256&redirect_uri=${
            typeof window !== `undefined` ? window.location.origin : ""
        }/api/auth/callback`;
    }
}

export default useAuth;
