export const pageSizeOptions = [
    { label: "12", value: "12" },
    { label: "24", value: "24" },
    { label: "48", value: "48" },
    { label: "96", value: "96" },
    { label: "498", value: "498" },
    { label: "1002", value: "1002" },
];

export default {
    columns: [],
    rowId: "renditionId",
    searchLabel: "Search SKU ID, company, etc.",
    itemsPerPage: 24,
    pageSizeOptions,
    searchCategoryLimits: { min: 5, max: 50 },
};
