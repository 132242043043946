export const Permissions = {
    TEST: "test",
    MATERIAL_READ: "material_read",
    NOISEMASK_READ: "noisemask_read",
    SCENE_READ: "scene_read",
    COMPANY_READ: "company_read",
    PROJECT_READ: "project_read",
    PROJECT_COSTS: "project_costs",
    RENDER_READ: "render_read",
    MATERIAL_APPROVAL: "material_approval",
    MATERIAL_MANAGE: "material_manage",
    NOISEMASK_MANAGE: "noisemask_manage",
    SCENE_MANAGE: "scene_manage",
    COMPANY_MANAGE: "company_manage",
    PROJECT_MANAGE: "project_manage",
    MATERIAL_DOWNLOAD: "material_download",
    NOISEMASK_DOWNLOAD: "noisemask_download",
    SCENE_DOWNLOAD: "scene_download",
    PROJECT_DOWNLOAD: "project_download",
    RENDER_DOWNLOAD: "render_download",
    MATERIAL_PUBLISH: "material_publish",
    NOISEMASK_PUBLISH: "noisemask_publish",
    SCENE_PUBLISH: "scene_publish",
    PROJECT_ARCHIVE: "project_archive",
    PROJECT_RENDER: "project_render",
    ERROR_CAUSE: "error_cause",
    AUTH: "auth",
};
