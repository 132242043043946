//@flow

import type { Option } from "../../MultiSelectDropdown/types/option.d";

export default (results: any): Option[] =>
    results.map(result => ({
        ...result,
        label:
            result.label ||
            `${result.sceneName}: ${result.componentDescription}`,
        value: result.value || `${result.sceneVersionId}#${result.componentId}`,
    }));
