// @flow
import {
    Button,
    Datatable,
    Heading,
    Icon,
    Lightbox,
    LoadIndicator,
    Search,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import React, { type Node, useEffect, useState } from "react";
import { Container } from "reactstrap";

import { ErrorHandling } from "../../components";
import actions from "./actions";
import versionItemTableSettings from "./settings/version-items-table-settings";
import versionTableSettings from "./settings/version-table-settings";
import style from "./style.module.scss";
import mapVersionHistoryResults from "./utils/map-version-history-results";
import mapVersionResults from "./utils/map-version-results";

type Props = {
    id: string,
    label: string,
    backUrl: string,
    base: string,
    canDownload: boolean,
};

const History = ({ label, base, id, backUrl, canDownload }: Props): Node => {
    const [imageToShow, setImageToShow] = useState();
    const [{ data: historyData, loading }, getHistoryData] = useAxios(
        { url: `ams-api/${base}/${id}/version/history` },
        { manual: true, useCache: false },
    );

    useEffect(() => {
        if (id) {
            getHistoryData();
        }
    }, [id]);

    const [{ data: fileData }, downloadBatch] = useAxios(
        {
            method: "GET",
        },
        {
            manual: true,
            useCache: false,
        },
    );

    useEffect(() => {
        if (fileData?.url) {
            window.open(fileData?.url);
        }
    }, [fileData]);

    const handleAction = async (
        action: string,
        selected: { [key: string]: any },
    ) => {
        if (action === "download") {
            await downloadBatch({
                url: `/ams-api/${base}/version/${selected?.versionId}/content`,
            });
        } else if (action === "refresh") {
            getHistoryData();
        }
    };

    return (
        <Container fluid>
            <ErrorHandling />
            {!historyData || loading ? (
                <div className="mb-5 w-25">
                    <LoadIndicator cols={1} rows={1} />
                </div>
            ) : (
                <Heading
                    title={label + historyData?.identifier}
                    rightContent={
                        <Button onClick={() => navigate(backUrl)}>
                            Return
                        </Button>
                    }
                />
            )}
            <Datatable
                settings={versionTableSettings}
                dataIsLoading={loading}
                hideNavigation
                disableSort
                data={
                    historyData?.assetVersions
                        ? mapVersionResults(historyData?.assetVersions)
                        : []
                }
                onExpandRow={({ versionHistory }) => (
                    <Datatable
                        settings={versionItemTableSettings}
                        disableRowSelect
                        hideNavigation
                        disableSort
                    >
                        <tbody>
                            {mapVersionHistoryResults(versionHistory)?.map(
                                (element, index) => (
                                    <tr key={index}>
                                        {versionItemTableSettings.columns.map(
                                            column => {
                                                if (column.key === "picture") {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            width={column.width}
                                                        >
                                                            {element["details"]
                                                                .length > 0 && (
                                                                <HistoryImage
                                                                    url={
                                                                        element[
                                                                            "details"
                                                                        ][0]
                                                                            .value
                                                                    }
                                                                    onClickGallery={() =>
                                                                        setImageToShow(
                                                                            element[
                                                                                "details"
                                                                            ][0]
                                                                                .value,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td
                                                            key={column.key}
                                                            width={column.width}
                                                        >
                                                            {
                                                                element[
                                                                    column.key
                                                                ]
                                                            }
                                                        </td>
                                                    );
                                                }
                                            },
                                        )}
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Datatable>
                )}
                isInitialExpanded={({ versionId }) =>
                    versionId === historyData?.assetVersions[0]?.versionId
                }
                actions={actions(canDownload)}
                onAction={handleAction}
            />
            <div style={{ zIndex: 10000 }}>
                <Lightbox
                    position={0}
                    onPositionChange={() => {}}
                    isOpen={imageToShow !== undefined}
                    onOpenChanged={() => setImageToShow(undefined)}
                >
                    <Lightbox.Slide>
                        <img src={imageToShow} alt={"rejection"} />
                    </Lightbox.Slide>
                </Lightbox>
            </div>
        </Container>
    );
};

type HistoryImageProps = {
    url: string,
    onClickGallery: () => void,
};

export const HistoryImage = ({
    url,
    onClickGallery: handleClickGallery,
}: HistoryImageProps): Node => {
    const [isShown, setIsShown] = useState(false);
    return (
        <div
            className={style.historyItem}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            <div
                className={style.historyItemTile}
                style={{
                    backgroundImage: `url(${url})`,
                }}
            />
            {isShown && (
                <div className={style.historyItemTileActionRow}>
                    <Button
                        className={style.historyItemTileActionButton}
                        onClick={handleClickGallery}
                    >
                        <Icon
                            className={style.historyItemTileActionButtonIcon}
                            icon={Search}
                        />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default History;
