//@flow
import { useImmer } from "use-immer";

export interface Settings {
    label: string;
    key: string;
    loading?: boolean;
    selectOptions?: {
        label: string,
        value: any,
    }[];
}

type Props = {
    settings: Settings[],
    updateSelectOptions: (
        key: string,
        options: { label: string, value: string }[],
    ) => void,
    updateLoading: (key: string, loading: boolean) => void,
    updateSettings: (newSettings: any) => void,
};

const useSettings = (_settings: Settings[]): Props => {
    const [settings, setSettings] = useImmer(_settings);

    const findSettingIdxAndUpdate = (
        key: string,
        apply: (idx: number) => void,
    ) => {
        const indexRowToUpdate = settings.findIndex(row => row.key === key);
        if (indexRowToUpdate > -1) {
            apply(indexRowToUpdate);
        }
    };

    const updateSelectOptions = (
        key: string,
        options: { label: string, value: string }[],
    ): void =>
        findSettingIdxAndUpdate(key, idx =>
            setSettings(draft => {
                const setting = draft[idx];
                if (setting) {
                    setting.selectOptions = options.map(option => ({
                        ...option,
                    }));
                }
            }),
        );

    const updateLoading = (key: string, loading: boolean): void =>
        findSettingIdxAndUpdate(key, idx =>
            setSettings(draft => {
                const setting = draft[idx];
                if (setting) {
                    setting.loading = loading;
                }
            }),
        );

    const updateSettings = (newSettings: any) => {
        setSettings(draft => {
            newSettings.map(newSetting => {
                let found = draft.find(
                    oldSetting => oldSetting.key === newSetting.key,
                );

                if (found?.selectOptions) {
                    newSetting.selectOptions = found.selectOptions;
                }
            });

            return newSettings;
        });
    };

    return { settings, updateSelectOptions, updateLoading, updateSettings };
};

export default useSettings;
