//@flow
import { Datatable, Modal } from "@brutextiles/web-component-library";
import React, { type Node, useState } from "react";

import { useSuggestions, useTableData } from "../../hooks";
import publishFilterOptions from "./publish-filter-options";
import style from "./style.module.scss";

type Props = {
    title: string,
    apiBase: string,
    onCancel: () => void,
    onConfirm: ({ [string]: any }[]) => void,
    tableSettings: { [string]: any },
    resultsMapper: ({ [string]: any }[]) => { [string]: any }[],
    multiSelect: boolean,
    params?: { [string]: any },
    selected: string[],
};

const FramesSelection = ({
    title,
    apiBase,
    onCancel: handleCancel,
    onConfirm: handleConfirm,
    tableSettings,
    resultsMapper,
    multiSelect,
    selected,
    params = {},
}: Props): Node => {
    const [selectedRows, setSelectedRows] = useState([]);
    const customFilter = { latestVersion: true };
    const [
        { tableData, tableDataLoading, page },
        changeSorting,
        changeFilters,
        search,
        setCustomSearchFilter,
        searchSelected,
        pageInfoChange,
        setFullTextSearchQuery,
    ] = useTableData(
        {
            size: tableSettings.itemsPerPage || 10,
            customFilter,
            params,
        },
        apiBase,
    );

    const [
        { suggestions, suggestionsLoading },
        setSuggestionFilters,
        setFilterQuery,
        setCustomSuggestionFilter,
        setSelectedItemsForSuggestionSearch,
    ] = useSuggestions(apiBase, { ...customFilter, ...params });

    const setFilters = (filters: Array<{ [string]: string }>): void => {
        changeFilters(filters);
        setSuggestionFilters(filters);
    };

    const setVersionFilter = (filter: {
        field: string,
        value: string,
    }): void => {
        if (filter.field !== "allVersions") {
            setCustomSearchFilter({ [filter.field]: filter.value });
            setCustomSuggestionFilter({
                [filter.field]: filter.value,
                ...params,
            });
        } else {
            setCustomSearchFilter({});
            setCustomSuggestionFilter({ ...params });
        }
    };

    const handleSearchSelected = ids => {
        searchSelected(ids);
        setSelectedItemsForSuggestionSearch(ids);
    };

    return (
        <Modal
            open
            size={"lg"}
            style={{ maxWidth: "85%", width: "95%" }}
            title={title}
            className={style.modal}
            onCancel={handleCancel}
            actions={[
                {
                    type: "primary",
                    label: "select",
                    action: () => handleConfirm(selectedRows),
                    disable: !selectedRows?.length,
                },
                {
                    type: "secondary",
                    label: "cancel",
                    action: handleCancel,
                },
            ]}
        >
            <div className={style.modalContent}>
                <Datatable
                    settings={{
                        ...tableSettings,
                        customFilters: publishFilterOptions,
                    }}
                    data={
                        tableData?.results
                            ? resultsMapper(tableData.results)
                            : []
                    }
                    multiSort={true}
                    onMultiSortChange={changeSorting}
                    onPageChange={() => {}}
                    onPageInfoChange={pageInfoChange}
                    searchResults={suggestions?.result}
                    onSearchChange={setFilterQuery}
                    onRefresh={search}
                    onFilterChange={setFilters}
                    searchIsLoading={suggestionsLoading}
                    dataIsLoading={tableDataLoading}
                    totalResults={tableData?.totalResults}
                    page={page}
                    multiSelect={multiSelect}
                    onCustomFilterChange={setVersionFilter}
                    onShowSelectedItems={handleSearchSelected}
                    onSelectedItemsChange={setSelectedRows}
                    onQuery={setFullTextSearchQuery}
                    onSelectAll={selectAll => {
                        if (selectAll) {
                            setSelectedRows(
                                tableData?.results
                                    .filter(row => !row.inUse)
                                    .map(row => row[tableSettings.rowId]),
                            );
                        } else {
                            setSelectedRows([]);
                        }
                    }}
                    showSelectedToggle
                    disableRowCheck={row =>
                        row?.inUse ||
                        selected?.includes(row[tableSettings.rowId])
                    }
                />
            </div>
        </Modal>
    );
};

export default FramesSelection;
