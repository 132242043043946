//@flow

import classNames from "classnames";
import React, { type Node, Fragment } from "react";
import { UncontrolledTooltip } from "reactstrap";

const States = {
    UP_TO_DATE: { label: "latest version", color: "#2BC044" },
    NEW_VERSION_AVAILABLE: {
        label: "a newer version exists",
        color: "#FFBD12",
    },
    NEW_PUBLISHED_VERSION_AVAILABLE: {
        label: "a newer published version exists",
        color: "#EE5C5C",
    },
};

type VersionCellProps = {
    version?: string,
    cellId: string,
    versionIndicator?: string,
};

const VersionCell = ({
    version,
    cellId,
    versionIndicator,
}: VersionCellProps): Node => {
    const state = versionIndicator && States[versionIndicator];
    return (
        <Fragment>
            <UncontrolledTooltip placement="top" target={cellId}>
                {state?.label || "unknown version status"}
            </UncontrolledTooltip>
            <span
                id={cellId}
                className={classNames("badge", "text-white")}
                style={{
                    background: state?.color || "#B2B2B2",
                }}
            >
                {version}
            </span>
        </Fragment>
    );
};

export default VersionCell;
