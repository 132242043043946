export default (
    showCopyMaterialSets,
    showCopyMaterialVersions,
    showCopyViewSets,
    showCopyPairings,
) => {
    const fields = [
        {
            label: "Project name",
            key: "projectName",
            inputType: "text",
            required: true,
            width: 12,
        },
        {
            label: "Company",
            key: "company",
            inputType: "select",
            readOnly: true,
            required: true,
            selectOptions: [],
            width: 12,
        },
        {
            label: "Project manager",
            key: "projectManager",
            inputType: "select",
            readOnly: true,
            selectOptions: [],
            required: true,
            width: 12,
        },
        {
            label: "Cost center",
            key: "costCenter",
            inputType: "select",
            readOnly: true,
            selectOptions: [
                { value: "Development", label: "Development" },
                { value: "Marketing", label: "Marketing" },
                { value: "Customer Projects", label: "Customer Projects" },
                { value: "Partner Projects", label: "Partner Projects" },
            ],
            width: 12,
            required: true,
        },
        {
            label: "Priority",
            key: "priority",
            inputType: "select",
            selectOptions: [
                { value: 1, label: "Low" },
                { value: 25, label: "Medium" },
                { value: 50, label: "High" },
                { value: 100, label: "Urgent" },
            ],
            width: 12,
            readOnly: true,
            required: true,
        },
        {
            label: "Publish to Aprimo",
            key: "publishToAprimo",
            inputType: "checkbox",
            inline: true,
            required: true,
            width: 12,
        },
        {
            label: "Quality control project",
            key: "isQcProject",
            inputType: "checkbox",
            inline: true,
            required: true,
            width: 12,
        },
        {
            label: "Filename template",
            key: "renderFilenameTemplate",
            inputType: "text",
            width: 12,
        },
        {
            label: "Description",
            key: "description",
            inputType: "textarea",
            required: true,
            width: 12,
        },
    ];

    if (showCopyMaterialSets) {
        fields.push({
            label: "Material sets",
            key: "copyMaterialSets",
            inputType: "checkbox",
            inline: true,
            width: showCopyMaterialVersions ? 6 : 12,
        });
    }

    if (showCopyMaterialVersions) {
        fields.push({
            label: "Include materials",
            key: "copyMaterialVersions",
            inputType: "checkbox",
            inline: true,
            width: 6,
        });
    }

    if (showCopyViewSets) {
        fields.push({
            label: "View sets",
            key: "copyViewSets",
            inputType: "checkbox",
            inline: true,
            width: 12,
        });
    }

    if (showCopyPairings) {
        fields.push({
            label: "Pairings",
            key: "copyPairings",
            inputType: "checkbox",
            inline: true,
            width: 12,
        });
    }

    return fields;
};
