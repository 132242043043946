// @flow
import {
    Building,
    Bullseye,
    Chart,
    Mask,
    Menu,
    Refresh,
} from "@brutextiles/web-component-library";

import { Permissions } from "../../api/permissions";

export default (hasPermission: (permission: string) => boolean): any[] => [
    {
        path: "/companies",
        label: "Companies",
        icon: Building,
        disabled: !hasPermission(Permissions.COMPANY_READ),
    },
    {
        path: "/materials",
        label: "Materials",
        icon: Chart,
        disabled: !hasPermission(Permissions.MATERIAL_READ),
    },
    {
        path: "/noise-masks",
        label: "Noise Masks",
        icon: Mask,
        disabled: !hasPermission(Permissions.NOISEMASK_READ),
    },
    {
        path: "/scenes",
        label: "Scenes",
        icon: Menu,
        disabled: !hasPermission(Permissions.SCENE_READ),
    },
    {
        path: "/projects",
        label: "Projects",
        icon: Bullseye,
        disabled: !hasPermission(Permissions.PROJECT_READ),
    },
    {
        path: "/renders",
        label: "Renders",
        icon: Refresh,
        disabled: !hasPermission(Permissions.RENDER_READ),
    },
];
