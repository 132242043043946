// @flow

import {
    Button,
    Card,
    LoadIndicator,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, { type Node, Fragment, useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";

import { axiosInstance } from "../../api";
import { ErrorHandling } from "../../components";
import { dateTimeFormatter } from "../../utils/formatter";
import UpdateProjectHeading from "../UpdateProjectHeading";
import style from "./report.module.scss";
import { mapResults } from "./utils";

const validateStatus = (status, getMetaData) => {
    if (status === 403) {
        getMetaData();
        return false;
    } else {
        return status >= 200 && status < 400;
    }
};

type Props = {
    projectId: string,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    readOnly: boolean,
};

const ProjectReport = ({
    readOnly,
    projectId,
    isAutomaticRendering = false,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
}: Props): Node => {
    const [reportIsEmpty, setReportIsEmpty] = useState(false);
    const [tableState, setTableState] = useState();
    const [reportData, setReportData] = useState();

    const [metaData, setMetaData] = useState();
    const [metaLoading, setMetaLoading] = useState();

    const getMetaData = data => {
        setMetaLoading(true);
        axiosInstance
            .get(`/ams-api/project/${projectId}/report`, JSON.stringify(data))
            .then(resp => {
                if (resp.status === 200) {
                    setMetaLoading(false);
                    return resp.data;
                }

                if (resp.status === 404) {
                    setReportIsEmpty(true);
                    setMetaLoading(false);
                    return true;
                } else {
                    setMetaLoading(false);
                    return resp.status >= 200 && resp.status < 400;
                }
            })
            .then(data => setMetaData(data));
    };

    const [{ data: rawReportData, loading: reportLoading }, getReportData] =
        useAxios(
            {
                withCredentials: false,
            },
            { manual: true, useCache: false },
        );

    useEffect(() => {
        setReportData(mapResults(rawReportData));
    }, [rawReportData]);

    useEffect(() => {
        getMetaData();
    }, []);

    useEffect(() => {
        if (metaData) {
            getReportData({
                url: metaData.reportUrl,
                validateStatus: status => validateStatus(status, getMetaData),
            });
        }
    }, [metaData]);

    useEffect(() => {
        if (reportData && tableState) {
            setTableState(draft => {
                if (draft) {
                    draft.data = reportData;
                }
                return draft;
            });
        }
    }, [reportData]);

    const refreshButton = (
        <Button
            disabled={metaLoading || reportLoading}
            onClick={() => getMetaData()}
        >
            Refresh
        </Button>
    );

    return (
        <Fragment>
            <ErrorHandling />
            <UpdateProjectHeading
                readOnly={readOnly}
                toggleAutomaticRendering={handleToggleAutomaticRendering}
                isAutomaticRendering={isAutomaticRendering}
                projectName={
                    metaData?.modificationTime
                        ? `Report ${dateTimeFormatter(
                              metaData?.modificationTime,
                          )}`
                        : "Report"
                }
                rightContent={refreshButton}
            />
            <div>
                <Card className={"mb-0"}>
                    {!reportData && (reportLoading || metaLoading) && (
                        <LoadIndicator cols={1} rows={1} />
                    )}
                    {reportIsEmpty && (
                        <span className={style.noReportMessage}>
                            There is no report in this project
                        </span>
                    )}
                    {reportData && reportData.length > 0 && (
                        <PivotTableUI
                            data={reportData}
                            onChange={setTableState}
                            {...tableState}
                        />
                    )}
                </Card>
            </div>
        </Fragment>
    );
};

export default ProjectReport;
