//@flow
import {
    Autocomplete,
    FileUpload,
    Radio,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import type { Node } from "react";
import React, { Fragment, useEffect } from "react";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import { Input } from "reactstrap";

import { useUpload } from "../../../../hooks";
import styles from "./style.module.scss";

type Props = {
    size: number,
    onChange: (key: string, value: string) => void,
    action: ?string,
    errors?: any,
};

const ApprovalForm = ({
    size,
    action,
    onChange: handleChange,
    errors,
}: Props): Node => {
    const [{ data: reasonsData }] = useAxios(
        {
            url: "/ams-api/material/list/rejection_reasons",
        },
        {
            useCache: false,
        },
    );

    const [
        {
            progress: uploadProgress,
            done: uploadFinished,
            loading: uploadLoading,
            uploadData,
            errorOnUpload,
            fileName,
        },
        uploadFile,
        resetUpload,
    ] = useUpload("/ams-api/material/rejected");

    useEffect(() => {
        handleChange("s3FileUrl", uploadData?.Location);
    }, [uploadData]);

    return (
        <Form>
            <FormGroup>
                <FormLabel>
                    {size > 1
                        ? "Would you like to approve or reject the selected materials?"
                        : "Would you like to approve or reject the selected material?"}
                </FormLabel>
                <Radio
                    className={styles.approvalOption}
                    onChange={() => handleChange("action", "approve")}
                    id={"rb2"}
                    name={"rb"}
                    checked={action === "approve"}
                    label="Approve"
                />
                <Radio
                    className={styles.approvalOption}
                    onChange={() => handleChange("action", "reject")}
                    id={"rb1"}
                    name={"rb"}
                    label="Reject"
                />
                <ValidationError message={errors && errors["action"]} />
            </FormGroup>
            {action === "reject" && (
                <Fragment>
                    <FormGroup>
                        <FormLabel>Reason</FormLabel>
                        <Autocomplete
                            allowCreate
                            createOnLeave
                            onChange={selectedOption =>
                                handleChange("reason", selectedOption)
                            }
                            items={reasonsData?.rejectionReasons.map(
                                option => ({
                                    value: option,
                                    label: option,
                                }),
                            )}
                        />
                        <ValidationError message={errors && errors["reason"]} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Description</FormLabel>
                        <Input
                            onChange={e =>
                                handleChange("description", e.target.value)
                            }
                            type={"textarea"}
                            rows={5}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Image</FormLabel>
                        <FileUpload
                            onAddFile={uploadFile}
                            infoContent={"You can attach an image"}
                            progress={uploadProgress}
                            isUploading={uploadLoading}
                            uploadFinished={uploadFinished}
                            onReset={resetUpload}
                            error={errorOnUpload}
                            fileName={fileName}
                        />
                    </FormGroup>
                </Fragment>
            )}
        </Form>
    );
};

type ValidationErrorProps = {
    message?: string,
};

const ValidationError = ({ message }: ValidationErrorProps): Node => {
    return <div className="text-danger mt-1">{message}</div>;
};

export default ApprovalForm;
