// @flow
import { Modal } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import React, { type Node, useEffect, useState } from "react";

import { requestChain } from "../MaterialOverview/utils";
import ApprovalForm from "./subComponents/ApprovalForm";
import { Actions } from "./type/material-state.d";

type Props = {
    action: {
        type: string,
        data: { [string]: any } | { [string]: any }[],
        fetchResults: () => Function,
    },
    onHide: () => void,
};

const MaterialWorkflowForm = ({ action, onHide }: Props): Node => {
    const { type, data, fetchResults } = action;
    const [loading, setLoading] = useState<boolean>(false);

    const formik = useFormik({
        validate: values => {
            const errors = {};

            if (!values.action) {
                errors.action = "Action is a required field";
            }

            if (values.action === Actions.REJECT && !values.reason) {
                errors.reason = "Reason is a required field";
            }

            return errors;
        },
        initialValues: {
            action: "approve",
            reason: undefined,
            description: undefined,
            s3FileUrl: undefined,
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [action]);

    const [, executeWorkflowAction] = useAxios(
        {},
        {
            manual: true,
            useCache: false,
        },
    );

    const handleConfirmWorkflowAction = (actionType: string, form: any) => {
        setLoading(true);
        if (
            [
                Actions.SEND_FOR_APPROVAL,
                Actions.PUBLISH,
                Actions.UNPUBLISH,
            ].includes(actionType)
        ) {
            requestChain(data, row =>
                executeWorkflowAction({
                    url: `ams-api/material/${row.companyId}/${row.skuId}/${actionType}`,
                    method: "PUT",
                }),
            ).then(() => {
                fetchResults();
                onHide();
                setLoading(false);
            });
        } else if (actionType === Actions.APPROVE) {
            if (form.action === Actions.REJECT) {
                requestChain(data, row =>
                    executeWorkflowAction({
                        url: `ams-api/material/${row.companyId}/${row.skuId}/reject_approval`,
                        method: "PUT",
                        data: {
                            reason: form.reason,
                            description: form.description,
                            s3FileUrl: form.s3FileUrl,
                        },
                    }),
                ).then(() => {
                    fetchResults();
                    onHide();
                    setLoading(false);
                });
            } else {
                requestChain(data, row =>
                    executeWorkflowAction({
                        url: `ams-api/material/${row.companyId}/${row.skuId}/confirm_approval`,
                        method: "PUT",
                    }),
                ).then(() => {
                    fetchResults();
                    onHide();
                    setLoading(false);
                });
            }
        }
    };

    return (
        <Modal
            open={type}
            title={"confirmation"}
            onCancel={() => onHide()}
            actions={[
                {
                    type: loading ? "secondary" : "primary",
                    label: "confirm",
                    disabled:
                        loading ||
                        (type === Actions.APPROVE && !formik.isValid),
                    action: () => {
                        handleConfirmWorkflowAction(type, formik.values);
                    },
                },
                {
                    type: "secondary",
                    label: "cancel",
                    action: () => onHide(),
                },
            ]}
        >
            {type === Actions.APPROVE && (
                <ApprovalForm
                    size={Array.isArray(data) ? data.length : 1}
                    onChange={(key, value) => formik.setFieldValue(key, value)}
                    action={formik.values.action}
                    errors={formik.errors}
                />
            )}
            <div>
                {confirmationLabel(type, Array.isArray(data) ? data.length : 1)}
            </div>
        </Modal>
    );
};

const confirmationLabel = (type: string, size: number) => {
    if (type === Actions.SEND_FOR_APPROVAL)
        return size > 1
            ? `Do you want to send ${size} materials for approval?`
            : "Do you want to send the selected material for approval?";

    if (type === Actions.PUBLISH)
        return size > 1
            ? `Do you want to publish ${size} materials?`
            : "Do you want to publish the selected material?";

    if (type === Actions.UNPUBLISH) {
        return size > 1
            ? `Do you want to unpublish ${size} materials?`
            : "Do you want to unpublish the selected material?";
    }
};

export default MaterialWorkflowForm;
