//@flow

import React, { type Node } from "react";
import Select from "react-select";

import styles from "./select.module.scss";
import type { Option } from "./types/option.d";

type Props = {
    id?: string,
    options: Option[],
    values: Option[],
    isMulti?: boolean,
    error?: string,
    placeholder?: string,
    onChange: () => void,
    disabled?: boolean,
};

const customStyles = {
    control: provided => ({
        ...provided,
        border: "1px solid lightgray",
        borderRadius: "0px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
};

const MultiSelectDropdown = ({
    id = "multiselect-dropdown",
    options,
    values = [],
    error,
    placeholder,
    isMulti = true,
    onChange: handleChange,
    disabled = false,
}: Props): Node => (
    <Select
        className={error && styles.error}
        id={id}
        isDisabled={disabled}
        value={values}
        onChange={handleChange}
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        styles={customStyles}
        isMulti={isMulti}
        options={options}
    />
);

export default MultiSelectDropdown;
