//@flow
import { useState } from "react";
import { useImmer } from "use-immer";

type Props = {
    data?: {}[],
    idField: string,
};

const useSelect = ({ idField, data: initData = [] }: Props): * => {
    const [data, setData] = useState(initData);
    const [selected, setSelected] = useImmer([]);

    const selectItem = (row: { [string]: any }) => {
        setSelected(draft => {
            const indexSelectedItem = selected.findIndex(
                selectedRow => selectedRow[idField] === row[idField],
            );
            if (indexSelectedItem > -1) {
                draft.splice(indexSelectedItem, 1);
                return draft;
            } else {
                draft.push(row);
                return draft;
            }
        });
    };

    const selectPage = (select: boolean) => {
        data.forEach(item => {
            if (!select) {
                setSelected(draft =>
                    draft.filter(row => row[idField] !== item[idField]),
                );
            } else if (!selected.find(row => row[idField] === item[idField])) {
                setSelected(draft => [...draft, item]);
            }
        });
    };

    return [{ selected }, setData, selectItem, selectPage, setSelected];
};

export default useSelect;
