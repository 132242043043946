//@flow

import _ from "lodash";

import type { Pairing } from "../../../../../components/Pairing/types/pairing.d";

export default (pairing: Pairing): Pairing => {
    return _.cloneDeep({
        ...pairing,
        editMode: false,
        componentsMaterialSetsCombinations:
            pairing.componentsMaterialSetsCombinations?.map(combination => ({
                ...combination,
            })) || [],
    });
};
