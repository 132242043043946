//@flow

import { dateTimeFormatter } from "../../../utils/formatter";
import type { MaterialVersion } from "../types/material-versions.d";

export default (results: MaterialVersion[]): MaterialVersion[] =>
    results?.map(result => ({
        ...result,
        lastModifiedAt: dateTimeFormatter(result.lastModifiedAt),
    }));
