//@flow

import { Autocomplete, DropdownList } from "@brutextiles/web-component-library";
import classnames from "classnames";
import type { Node } from "react";
import React from "react";

import { SetType } from "../../../../hooks/use-material-sets";
import TableCell from "../../../ViewSet/subComponents/TableCell";
import type { Actions, Settings } from "../../index";
import type { MaterialVersion } from "../../types/material-version.d";
import style from "./style.module.scss";

type Props = {
    index: number,
    setIndex: number,
    removed?: boolean,
    added?: boolean,
    versionChanged?: boolean,
    setType: string,
    row: {
        [string]: any,
    },
    rotation: number,
    settings: Settings,
    actions: Actions[],
    editMode: boolean,
    onChangeRotation: (value: number) => void,
    onAction: (action: string, row: MaterialVersion) => void,
    readOnly?: boolean,
};

const rotationOptions = [
    { value: 0, label: "0°" },
    { value: 90, label: "90°" },
    { value: 180, label: "180°" },
    { value: 270, label: "270°" },
];

const MateriaSetTableBodyRow = ({
    index,
    setIndex,
    setType,
    removed = false,
    added = false,
    versionChanged = false,
    row,
    rotation,
    settings,
    editMode,
    actions = [],
    readOnly,
    onAction: handleAction,
    onChangeRotation: handleChangeRotation,
}: Props): Node => (
    <tr
        className={classnames(
            style.tableRow,
            removed && style.removed,
            added && style.added,
            versionChanged && style.versionChanged,
        )}
    >
        {settings.columns.map((column, columnIndex) => (
            <td key={column.key}>
                {editMode && column.key === "rotation" ? (
                    <Autocomplete
                        disabled={removed || versionChanged}
                        readOnly
                        value={rotation}
                        items={rotationOptions}
                        onChange={handleChangeRotation}
                    />
                ) : (
                    <TableCell
                        content={row[column.key] || row.metadata?.[column.key]}
                        maxLength={row[column.key] ? 16 : 5}
                        formatter={column.formatter}
                        context={column.key}
                        index={`${setIndex}_${columnIndex}_${index}`}
                    />
                )}
            </td>
        ))}
        {setType === SetType.SELECTION && !readOnly && (
            <td className={style.actionCell}>
                {!added && !removed && !versionChanged && (
                    <DropdownList
                        listItems={actions}
                        onSelect={action => {
                            handleAction(action, row);
                        }}
                    />
                )}
            </td>
        )}
    </tr>
);

export default MateriaSetTableBodyRow;
