//@flow
import {
    ChevronDown,
    ChevronUp,
    Icon,
} from "@brutextiles/web-component-library";
import React, { type Node } from "react";
import { Badge, Button } from "reactstrap";

import style from "./style.module.scss";

type Props = {
    order: number,
    toggle: () => void,
    isOpen: boolean,
};

const ToggleButton = ({ order, toggle: handleToggle, isOpen }: Props): Node => (
    <Button className="ml-1 w-auto" color="none" onClick={handleToggle}>
        <Badge
            color={"light-grey"}
            className={`${style.sequence} text-secondary mr-3`}
            pill
        >
            {order}
        </Badge>
        <Icon icon={isOpen ? ChevronDown : ChevronUp} />
    </Button>
);

export default ToggleButton;
