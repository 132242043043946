import { useNotifications } from "@brutextiles/web-component-library";
import _axios from "axios";
import { configure } from "axios-hooks";
import qs from "qs";
import { useContext } from "react";

import useAuth from "../hooks/use-auth";
import { buildFrontendUrl } from "../hooks/use-tenant";
import { ErrorHandlingContext } from "../providers/error-handling-provider";

const REFRESH_COUNTER_KEY = "REFRESH_COUNTER";

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json;charset=utf-8",
};

const instance = _axios.create({
    baseURL: "/api",
    headers,
    withCredentials: true,
    validateStatus: status => status >= 200 && status < 400,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),
});

const AxiosInterceptor = () => {
    const { putErrorMessages, clearErrorMessages } =
        useContext(ErrorHandlingContext);
    const { addNotification } = useNotifications();
    const { unauthorized } = useAuth();

    instance.interceptors.request.use(config => {
        const tenant =
            typeof window !== "undefined" &&
            window.localStorage.getItem("tenant");
        if (tenant) {
            config.headers["tenant-id"] = tenant;
        }

        if (typeof window !== "undefined") {
            config.headers["frontend-url"] = buildFrontendUrl();
        }

        return config;
    });

    instance.interceptors.response.use(
        response => {
            clearErrorMessages(response.config.url);
            return response;
        },
        error => {
            const status = error?.response?.status;
            if (status === 409) {
                putErrorMessages(error.config.url, error.response.data);
                addNotification({
                    type: "danger",
                    body: "Something went wrong. Please check error messages.",
                    autoHide: true,
                    timeout: 5000,
                });
            } else if (error.message && error.message.includes("403")) {
                if (error.config.url.includes("/auth/refresh")) {
                    unauthorized();
                } else {
                    return instance.get("/auth/refresh").then(() => {
                        const counter = Number(
                            localStorage.getItem(REFRESH_COUNTER_KEY),
                        );
                        if (counter <= 3) {
                            localStorage.setItem(
                                REFRESH_COUNTER_KEY,
                                counter + 1,
                            );
                            return instance.request(error.config);
                        } else {
                            localStorage.setItem(REFRESH_COUNTER_KEY, 0);
                            return Promise.reject({
                                message: "Exceeded the number of executions",
                            });
                        }
                    });
                }
            } else if (error.message) {
                addNotification({
                    type: "danger",
                    body: "Something went wrong. Please try again later.",
                    autoHide: true,
                    timeout: 5000,
                });
            }
            return Promise.reject(error);
        },
    );
    return null;
};

const getUploadStatus = (url, data, onValidation, onData) =>
    instance
        .post(url, JSON.stringify(data))
        .then(resp => {
            if (resp.status === 200) {
                return resp.data;
            }

            if (resp.status === 409) {
                onValidation(false);
            }
        })
        .then(data => onData(data));

export default AxiosInterceptor;
export { instance as axiosInstance, getUploadStatus };

configure({
    axios: instance,
});
