// @flow
import { Check, Close, Menu } from "@brutextiles/web-component-library";

import {
    downloadAction,
    publishAction,
    unpublishAction,
} from "../../templates/OverviewTable/actions";
import type { Action } from "../../templates/OverviewTable/types/action.d";

export const Actions = {
    ARCHIVE: "archive",
    UNARCHIVE: "unarchive",
};

const versionHistoryAction = (canRead: boolean): Action => ({
    label: "Version History",
    action: "history",
    icon: Menu,
    enable: {
        condition: selected => selected?.length === 1 && canRead,
    },
});

export default (
    canRead: boolean,
    canPublish: boolean,
    canDownload: boolean,
    canManage: boolean,
): Action[] => [
    downloadAction(canDownload),
    publishAction(canPublish),
    unpublishAction(canPublish),
    versionHistoryAction(canRead),
    ...workflowActions(canManage),
];

export const workflowActions = (canManage: boolean): Action[] => [
    {
        label: "Archive",
        action: Actions.ARCHIVE,
        icon: Close,
        show: selected => {
            const collect = getArchived(selected);
            return canManage && collect.size === 1 && collect.has(false);
        },
        enable: {
            condition: () => canManage,
        },
    },
    {
        label: "Unarchive",
        action: Actions.UNARCHIVE,
        icon: Check,
        show: selected => {
            const collect = getArchived(selected);
            return canManage && collect.size === 1 && collect.has(true);
        },
        enable: {
            condition: () => canManage,
        },
    },
];

export const getArchived = (selected: { [key: string]: any }): Set<boolean> =>
    selected?.reduce((acc, row) => acc.add(row?.archived), new Set()) ||
    new Set();
