//@flow

import { Autocomplete, Input } from "@brutextiles/web-component-library";
import React, { type Node } from "react";

import style from "./style.module.scss";

type Props = {
    type: "Create" | "Update",
    components: any[],
    onChange: (any, number) => void,
    descriptions: string[],
    errors?: { [string]: string },
    readOnly?: boolean,
};

const ComponentDescriptionsBody = ({
    descriptions,
    components,
    onChange: handleChange,
    errors,
    readOnly = false,
}: Props): Node => (
    <tbody className={style.componentDescriptions}>
        {components.map((component, index) => (
            <tr key={index}>
                <td className={style.idColumn}>{component.componentId}</td>
                <td className={style.nameColumn}>{component.name}</td>
                <td className={style.descriptionColumn}>
                    {readOnly ? (
                        <Input
                            type={"text"}
                            value={component.description}
                            disabled={true}
                        />
                    ) : (
                        <Autocomplete
                            key={"description" + index}
                            allowCreate
                            createOnLeave
                            onChange={selectedOption =>
                                handleChange(selectedOption, index)
                            }
                            value={component.description}
                            items={descriptions}
                        />
                    )}
                    {errors && errors[`component-${index}-description`] && (
                        <div className="text-danger mt-1">
                            {errors[`component-${index}-description`]}
                        </div>
                    )}
                </td>
            </tr>
        ))}
    </tbody>
);

export default ComponentDescriptionsBody;
