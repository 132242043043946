export default {
    columns: [
        {
            key: "companyId",
            label: "Company ID",
        },
        {
            key: "designId",
            label: "Design ID",
        },
        {
            key: "launch",
            label: "Launch",
        },
        {
            key: "brand",
            label: "Brand",
        },
        {
            key: "collection",
            label: "Collection",
        },
        {
            key: "design",
            label: "Design",
            width: "10%",
        },
        {
            key: "noiseMaskType",
            label: "Type",
            width: "5%",
        },
        {
            key: "width",
            label: "Width",
            width: "5%",
        },
        {
            key: "height",
            label: "Height",
            width: "5%",
        },
        {
            key: "noiseAddFactor",
            label: "Noise Add Factor",
            width: "8%",
        },
        {
            key: "modificationTime",
            label: "Last modified",
            defaultSortOrder: "desc",
            width: "8%",
        },
        {
            key: "version",
            label: "Version",
            width: "5%",
        },
        {
            key: "published",
            label: "Status",
        },
    ],
    customSearchLabels: {
        noiseMaskId: "Noise Mask ID",
    },
    rowId: "versionId",
    searchLabel: "Search Design ID, Company etc.",
    sortOrder: "desc",
    itemsPerPage: 50,
    pageSizeOptions: [
        { label: "10", value: "10" },
        { label: "25", value: "25" },
        { label: "50", value: "50" },
        { label: "100", value: "100" },
    ],
    searchCategoryLimits: { min: 5, max: 50 },
};
