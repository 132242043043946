export default [
    {
        label: "Created",
        key: "renditionTime",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Scene",
        key: "sceneName",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Frame",
        key: "frameLabel",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Height",
        key: "height",
        inputType: "text",
        disabled: true,
        width: 12,
    },
    {
        label: "Width",
        key: "width",
        inputType: "text",
        disabled: true,
        width: 12,
    },
];
