// @flow

import {
    Button,
    Card,
    DetailsForm,
    Heading,
    InlineEdit,
    LoadIndicator,
    useSettings,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useFormik } from "formik";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";

import { ErrorHandling } from "../../components";
import { useErrorHandlingWithForm, useUpdate } from "../../hooks";
import { FormContext } from "../../providers/form-provider";
import type { ProjectMetaData } from "../UpdateProject/types/project.d";
import UpdateProjectHeading from "../UpdateProjectHeading";
import formSettings from "./settings/form";
import validationSettings from "./settings/validation";

type Props = {
    metaData: ProjectMetaData,
    error: any,
    loading: boolean,
    reloadMetaData: () => void,
    readOnly?: boolean,
};

const ProjectSummary = ({
    metaData,
    error,
    loading,
    reloadMetaData,
    readOnly = false,
}: Props): Node => {
    const [editMode, setEditMode] = useState(false);
    const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false);
    const [{ settings }, updateSelectOptions] = useSettings(
        formSettings(readOnly),
    );

    const [{ data: users }] = useAxios(`/auth/users`, {
        useCache: false,
    });

    const [, update] = useUpdate({});

    const resetForm = () => {
        if (metaData) {
            formik.resetForm({
                values: metaData,
            });
        }
    };

    useEffect(() => {
        if (users?.userNames?.length) {
            updateSelectOptions(
                "projectManager",
                users.userNames.map(userName => ({
                    value: userName,
                    label: userName,
                })),
            );
        }
    }, [users]);

    const formik = useFormik({
        ...validationSettings,
        initialValues: metaData,
        onSubmit: async () =>
            await update({
                url: `/ams-api/project/${metaData.projectId}/metadata`,
                data: formik.values,
            }).then(() => {
                setShouldValidateOnChange(false);
                setEditMode(false);
                setDirty(false);
                formik.setSubmitting(false);
            }),
    });

    const { setDirty } = useContext(FormContext);
    useEffect(() => setDirty(formik.dirty), [formik.dirty]);

    useErrorHandlingWithForm("project-summary-form", formik.errors);

    const updateFormData = data => {
        if (!editMode) {
            setEditMode(true);
        }
        formik.setFieldValue(data.key, data.value, shouldValidateOnChange);
    };

    const submit = () => {
        setShouldValidateOnChange(true);
        formik.submitForm();
    };

    return (
        <Fragment>
            <ErrorHandling />
            <UpdateProjectHeading
                toggleAutomaticRendering={metaData.toggleAutomaticRendering}
                isAutomaticRendering={metaData.isAutomaticRendering}
                projectName={metaData.projectName}
                readOnly={readOnly}
            />
            <Heading title={"Project summary"} level={5} />
            <div>
                <InlineEdit
                    onSave={submit}
                    onCancel={() => {
                        resetForm();
                        setEditMode(false);
                    }}
                    isSubmitting={formik.isSubmitting}
                    active={editMode}
                >
                    <Card className={"mb-0"}>
                        {loading ? (
                            <LoadIndicator cols={2} rows={3} />
                        ) : error ? (
                            <span className="text-danger">
                                Error while retrieving the data
                                <div>
                                    <Button
                                        onClick={reloadMetaData}
                                        className="mt-2"
                                    >
                                        Try loading data again
                                    </Button>
                                </div>
                            </span>
                        ) : (
                            <DetailsForm
                                settings={settings}
                                onChange={updateFormData}
                                errors={formik.errors}
                                data={{
                                    ...formik.values,
                                    isQcProject: formik.values.isQcProject,
                                    publishToAprimo:
                                        formik.values.publishToAprimo,
                                    renderFilenameTemplate:
                                        formik.values.renderFilenameTemplate,
                                    priority: formik.values.priority,
                                }}
                            />
                        )}
                    </Card>
                </InlineEdit>
            </div>
        </Fragment>
    );
};

export default ProjectSummary;
