// @flow
import { Bullseye, Menu } from "@brutextiles/web-component-library";

import { downloadAction } from "../../templates/OverviewTable/actions";
import type { Action } from "../../templates/OverviewTable/types/action.d";
import { workflowActions } from "../MaterialWorkflowForm/type/material-state.d";

const VRAY = "VRAY_MATERIAL";

const versionHistoryAction = (canRead: boolean): Action => ({
    label: "Version History",
    action: "history",
    icon: Menu,
    enable: {
        condition: selected => selected?.length === 1 && canRead,
    },
});

const convertAction = (canDownload: boolean): Action => ({
    label: "Convert",
    action: "convert",
    icon: Bullseye,
    enable: {
        condition: selected =>
            selected?.length > 0 &&
            !selected.find(entry => entry.materialType === VRAY) &&
            canDownload,
    },
});

export default (
    canRead: boolean,
    canManage: boolean,
    canDownload: boolean,
    canApprove: boolean,
    canPublish: boolean,
): Action[] => [
    downloadAction(canDownload),
    convertAction(canDownload),
    versionHistoryAction(canRead),
    ...workflowActions(canManage, canApprove, canPublish),
];
