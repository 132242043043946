//@flow

import "./src/styles/index.scss";

import { NotificationsProvider } from "@brutextiles/web-component-library";
import { Shell } from "@containers";
import { Location } from "@reach/router";
import React from "react";

import AxiosInterceptor from "./src/api";
import { ErrorHandlingProvider } from "./src/providers";
import AuthProvider from "./src/providers/auth-provider";
import FormProvider from "./src/providers/form-provider";

export const wrapRootElement = ({ element }: *): * => (
    <AuthProvider>
        <NotificationsProvider position={"bottomRight"}>
            <ErrorHandlingProvider>
                <FormProvider>
                    {element}
                    <AxiosInterceptor />
                </FormProvider>
            </ErrorHandlingProvider>
        </NotificationsProvider>
    </AuthProvider>
);

export const wrapPageElement = ({ element }: *): * => (
    <Location>
        {({ location }) => (
            <Shell path={location.pathname} search={location.search}>
                {element}
            </Shell>
        )}
    </Location>
);
