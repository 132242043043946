// @flow
import {
    Button,
    ChevronDown,
    ChevronUp,
    Heading,
    Icon,
    LoadIndicator,
    Modal,
    Plus,
    Radio,
} from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import React, {
    type Node,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { Button as Btn, ButtonGroup } from "reactstrap";

import { ErrorHandling, FramesSelection, MaterialSet } from "../../components";
import { useCollapse, useMaterialSets } from "../../hooks";
import { SetType } from "../../hooks/use-material-sets";
import { FormContext } from "../../providers/form-provider";
import sharedStyles from "../../styles/shared.module.scss";
import UpdateProjectHeading from "../UpdateProjectHeading";
import { materialTableActions } from "./settings/material-table-actions";
import materialTableSettings from "./settings/material-table-settings";
import style from "./style.module.scss";
import { mapSearchResults, mapSetResults } from "./utils";

type Props = {
    projectId: string,
    projectName: string,
    isAutomaticRendering: boolean,
    toggleAutomaticRendering: boolean => void,
    readOnly: boolean,
};

const ProjectMaterials = ({
    projectId,
    projectName,
    isAutomaticRendering = false,
    toggleAutomaticRendering: handleToggleAutomaticRendering,
    readOnly,
}: Props): Node => {
    const [showSelectionType, setShowSelectionType] = useState(false);
    const [materialSetId, setMaterialSetId] = useState();
    const [showFrameSelection, setShowFramesSelection] = useState();
    const [addedMaterialsData, setAddedMaterialsData] = useState({});
    const [type, setType] = useState(SetType.SELECTION);
    const toggleType = () =>
        setType(
            type === SetType.SUBSCRIPTION
                ? SetType.SELECTION
                : SetType.SUBSCRIPTION,
        );

    const {
        addCollapsible,
        collapseAll,
        expandAll,
        collapsed,
        removeCollapsible,
        toggleCollapse,
    } = useCollapse();

    const [
        {
            sets: materialSets,
            loading,
            indexNewSet,
            indexSetToExtend,
            deleteSetLoading,
            createSetLoading,
            updateSetLoading,
        },
        cancelEdit,
        setIndexSetToExtend,
        updateSetName,
        updateSetQuery,
        addItemToSet,
        addSet,
        deleteSet,
        createMaterialSet,
        updateMaterialSet,
        deleteItemFromMaterialSet,
        ,
        changeMaterialVersionRotation,
        addItemsToChangedVersionIds,
    ] = useMaterialSets(projectId);

    const [{ loading: materialsLoading, data: materialsData }, loadMaterials] =
        useAxios(
            {
                url: `/ams-api/material/version/retrieveVersionDetails`,
                method: "POST",
            },
            {
                useCache: false,
                manual: true,
            },
        );

    useEffect(() => {
        materialSets.forEach(set =>
            addCollapsible(
                set.materialSetId,
                collapsed(set.materialSetId) !== undefined
                    ? collapsed(set.materialSetId)
                    : set.materialSetId !== undefined,
            ),
        );
    }, [materialSets]);

    useEffect(() => {
        if (materialsData?.results?.length) {
            if (indexSetToExtend || indexSetToExtend === 0) {
                materialsData.results.forEach(frame => {
                    addItemToSet({
                        ...frame,
                        version: frame.materialVersion,
                        viewName: "",
                    });
                });
            } else {
                addSet(materialsData?.results, SetType.SELECTION);
            }

            setAddedMaterialsData(draft => {
                materialsData.results.map(row => {
                    draft[row.materialVersionId] = row;
                });

                return draft;
            });
        }
    }, [materialsData]);

    const getMaterials = (selectedMaterials: { [string]: any }[]) => {
        setMaterialSetId(undefined);
        setShowFramesSelection(false);
        loadMaterials({
            data: { materialVersions: selectedMaterials },
        });
    };

    const deleteMaterialSet = (
        materialSetId: string,
        setIndex: number,
    ): void => {
        if (indexNewSet || indexNewSet === 0) {
            cancelEdit(setIndex);
        } else {
            deleteSet(
                `/ams-api/project/${projectId}/materialSet/${materialSetId}`,
                setIndex,
            );
            removeCollapsible(materialSetId);
            setDirty(false);
        }
    };

    const { setDirty } = useContext(FormContext);
    const saveChanges = (set: { [string]: any }): Promise<boolean> => {
        const { type } = set;

        if (type === SetType.SUBSCRIPTION) {
            const data = {
                materialSetName: set.materialSetName,
                query: set.query,
            };
            setDirty(false);
            if (indexNewSet || indexNewSet === 0) {
                return createMaterialSet(
                    `/ams-api/project/${projectId}/subscriptionBasedMaterialSet`,
                    data,
                );
            } else {
                return updateMaterialSet(
                    `/ams-api/project/${projectId}/subscriptionBasedMaterialSet/${set.materialSetId}`,
                    data,
                );
            }
        } else {
            setDirty(false);
            if (indexNewSet || indexNewSet === 0) {
                return createMaterialSet(
                    `/ams-api/project/${projectId}/materialSet`,
                    {
                        materialSetName: set.materialSetName,
                        materials: set.addedIds
                            ? Array.from(set.addedIds).map(versionId => ({
                                  versionId,
                                  rotation:
                                      (set.changedRotations &&
                                          set.changedRotations[versionId]) ||
                                      0,
                              }))
                            : {},
                    },
                );
            } else {
                const addedMaterialVersionIds = [];
                const removedMaterialVersionIds = [];

                if (set.addedIds?.size > 0) {
                    addedMaterialVersionIds.push(...Array.from(set.addedIds));
                }

                if (set.removedIds?.size > 0) {
                    removedMaterialVersionIds.push(
                        ...Array.from(set.removedIds),
                    );
                }

                if (
                    set.changedVersionIds &&
                    Object.keys(set.changedVersionIds).length > 0
                ) {
                    addedMaterialVersionIds.push(
                        ...Object.values(set.changedVersionIds),
                    );
                    removedMaterialVersionIds.push(
                        ...Object.keys(set.changedVersionIds),
                    );
                }

                return updateMaterialSet(
                    `/ams-api/project/${projectId}/materialSet/${set.materialSetId}`,
                    {
                        materialSetName: set.materialSetName,
                        addedMaterialVersionIds,
                        removedMaterialVersionIds,
                        changedMaterials: set.changedRotations
                            ? Object.keys(set.changedRotations).map(
                                  versionId => ({
                                      versionId,
                                      rotation: set.changedRotations[versionId],
                                  }),
                              )
                            : [],
                    },
                );
            }
        }
    };

    const actions = (
        <Fragment>
            <Btn color="none" onClick={() => collapseAll()}>
                <Icon icon={ChevronUp} className="mr-2 pb-1" /> Collapse all
            </Btn>
            <Btn color="none" onClick={() => expandAll()}>
                <Icon icon={ChevronDown} className="mr-2 pb-1" /> Expand all
            </Btn>
        </Fragment>
    );

    return (
        <Fragment>
            <ErrorHandling />
            <UpdateProjectHeading
                readOnly={readOnly}
                toggleAutomaticRendering={handleToggleAutomaticRendering}
                isAutomaticRendering={isAutomaticRendering}
                projectName={projectName}
                rightContent={
                    <Button
                        color="light-blue"
                        onClick={() => {
                            setShowSelectionType(true);
                            setMaterialSetId(undefined);
                            setShowFramesSelection(false);
                        }}
                        disabled={isAutomaticRendering}
                    >
                        ADD MATERIAL SET
                    </Button>
                }
            />
            <Heading
                title={
                    materialSets?.length || !isAutomaticRendering
                        ? "Material sets"
                        : ""
                }
                level={5}
                rightContent={!!materialSets?.length && actions}
            />
            {loading ? (
                <LoadIndicator cols={1} rows={1} />
            ) : (
                !materialSets?.length &&
                !materialsLoading &&
                !isAutomaticRendering &&
                !readOnly && (
                    <div className={sharedStyles.addContainer}>
                        <Button
                            color="link"
                            onClick={() => {
                                setShowSelectionType(true);
                                setMaterialSetId(undefined);
                                setShowFramesSelection(false);
                            }}
                        >
                            <Icon
                                className="mb-1 mr-2"
                                variant="secondary"
                                icon={Plus}
                            />
                            <span className="text-secondary">
                                Add material set
                            </span>
                        </Button>
                    </div>
                )
            )}
            {!!materialSets.length &&
                materialSets.map((set, index) => (
                    <MaterialSet
                        projectId={projectId}
                        set={set}
                        order={index + 1}
                        key={index}
                        loadMaterials={materialsLoading}
                        addedMaterials={Object.values(addedMaterialsData)}
                        deleteConfirmationLabel={
                            "Are you sure you want to delete this material set?"
                        }
                        queryTitle={"Material query"}
                        onDelete={() => {
                            deleteMaterialSet(set.materialSetId, index);
                            setDirty(false);
                        }}
                        actions={materialTableActions(set.query)}
                        open={collapsed(set.materialSetId) === false}
                        enableEdit={indexNewSet === index}
                        onCancel={() => cancelEdit(index)}
                        onAddItem={row => {
                            setIndexSetToExtend(index);
                            setMaterialSetId(row.materialSetId);
                            setShowFramesSelection(true);
                            setDirty(true);
                        }}
                        onUpdateQuery={value => {
                            updateSetQuery(value, index);
                            setDirty(true);
                        }}
                        onUpdateTitle={value => {
                            updateSetName(value, index);
                            setDirty(true);
                        }}
                        onDeleteItem={item =>
                            deleteItemFromMaterialSet(item, index)
                        }
                        onChangeRotation={(materialVersionId, rotation) =>
                            changeMaterialVersionRotation(
                                index,
                                materialVersionId,
                                rotation,
                            )
                        }
                        onUpdateMaterialVersion={versions => {
                            Object.keys(versions).forEach(
                                oldMaterialVersionId => {
                                    const newMaterial =
                                        versions[oldMaterialVersionId];

                                    if (
                                        oldMaterialVersionId !==
                                        newMaterial.materialVersionId
                                    ) {
                                        setAddedMaterialsData(draft => {
                                            draft[
                                                newMaterial.materialVersionId
                                            ] = newMaterial;
                                            return draft;
                                        });
                                    }
                                },
                            );

                            addItemsToChangedVersionIds(index, versions);
                        }}
                        onSave={() => saveChanges(set)}
                        onToggle={() => toggleCollapse(set.materialSetId)}
                        resultsMapper={mapSetResults}
                        readOnly={isAutomaticRendering || readOnly}
                        loading={
                            deleteSetLoading ||
                            createSetLoading ||
                            updateSetLoading
                        }
                    />
                ))}
            {!!materialsLoading && (!!indexNewSet || indexNewSet === 0) && (
                <LoadIndicator rows={4} cols={4} />
            )}
            {showFrameSelection && (
                <FramesSelection
                    params={{
                        materialSetId,
                    }}
                    tableSettings={materialTableSettings}
                    resultsMapper={mapSearchResults}
                    apiBase={"dap-search-service/material"}
                    onConfirm={getMaterials}
                    onCancel={() => {
                        setMaterialSetId(undefined);
                        setShowFramesSelection(false);
                        indexSetToExtend === undefined && addSet();
                        setIndexSetToExtend();
                    }}
                    title="Select materials for material set"
                    multiSelect
                    selected={calcSelected(materialSets, indexSetToExtend)}
                />
            )}
            <Modal
                size={"lg"}
                open={showSelectionType}
                title={"Choose material set type"}
                onCancel={() => {
                    setShowSelectionType();
                    setType(SetType.SELECTION);
                }}
            >
                <div>
                    <div>
                        Do you want to add a subscription based or a selection
                        based material set?
                    </div>
                    <div className={style.selectionContainer}>
                        <div className={style.radioGroup}>
                            <div className={style.entry}>
                                <Radio
                                    checked={type === SetType.SELECTION}
                                    onClick={toggleType}
                                    id="materialSet"
                                    type="radio"
                                    label="Selection based material set (default)"
                                />
                            </div>
                            <div className={style.entry}>
                                <Radio
                                    checked={type === SetType.SUBSCRIPTION}
                                    onClick={toggleType}
                                    id="materialSet"
                                    type="radio"
                                    label="Subscription based material set (advanced)"
                                />
                            </div>
                        </div>
                        <ButtonGroup
                            size={"lg"}
                            className={style.selectionButtons}
                        >
                            <Button
                                color={"light"}
                                onClick={() => {
                                    setShowSelectionType();
                                    setType(SetType.SELECTION);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    if (type === SetType.SELECTION) {
                                        setShowFramesSelection(true);
                                    } else {
                                        addSet([], SetType.SUBSCRIPTION);
                                    }
                                    setShowSelectionType();
                                    setType(SetType.SELECTION);
                                }}
                            >
                                Ok
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

const calcSelected = (sets, indexSetToExtend) => {
    const set = sets[indexSetToExtend];

    const resultSet = new Set();

    set?.materials?.forEach(material =>
        resultSet.add(material.materialVersionId),
    );

    set?.removedIds?.forEach(removedId => resultSet.delete(removedId));
    set?.addedIds?.forEach(addedId => resultSet.add(addedId));

    return Array.from(resultSet);
};

export default ProjectMaterials;
