import * as Yup from "yup";

import validationSettings from "../../CreateScene/settings/validation-settings";

export default {
    initialValues: {
        ...validationSettings.initialValues,
        comment: "",
    },
    validationSchema: validationSettings.validationSchema.shape({
        uploadStatus: Yup.string().test(
            "valid",
            "Please upload a valid file",
            value => !value || value === "VALID",
        ),
        fileUrl: Yup.string(),
    }),
};
