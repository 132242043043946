import React from "react";

export const mapDataForCreate = (
    sku,
    transparent,
    overrideTransparency,
    noiseMaskData,
    exposureCorrectionFactor,
    useVelvetShader,
    displacementMultiplier,
    overrideDisplacement,
    normalNoiseMaskMultiplier,
    overrideNormalNoiseMask,
) => ({
    ...sku,
    transparent: transparent ? "TRANSPARENT" : "OPAQUE",
    overrideTransparency,
    noiseMaskVersion: noiseMaskData?.noiseMaskVersion,
    noiseAddFactor: noiseMaskData?.noiseAddFactor,
    exposureCorrectionFactor,
    useVelvetShader,
    displacementMultiplier,
    overrideDisplacement,
    normalNoiseMaskMultiplier,
    overrideNormalNoiseMask,
});

export const mapDataForUpdate = (data, companyId, skuId, values) => ({
    ...data,
    companyId,
    skuId,
    overrideNoiseAddFactor: values.overrideNoiseAddFactor,
    noiseAddFactor: values.noiseMaskVersion
        ? values.overrideNoiseAddFactor
            ? values.noiseAddFactor
            : values.defaultNoiseAddFactor
        : undefined,
    exposureCorrectionFactor: values.exposureCorrectionFactor,
    transparent: values.transparent ? "TRANSPARENT" : "OPAQUE",
    overrideTransparency: values.overrideTransparency,
    overrideDisplacement: values.overrideDisplacement,
    displacementMultiplier: values.displacementMultiplier,
    useVelvetShader: values.useVelvetShader,
    normalNoiseMaskMultiplier: values.overrideNormalNoiseMask
        ? values.normalNoiseMaskMultiplier
        : values.defaultNormalNoiseMaskMultiplier,
    overrideNormalNoiseMask: values.overrideNormalNoiseMask,
});

export const updateTabs = ["Main", "Metadata", "Adjustments"].map(
    (item, index) => ({
        id: index,
        label: <span>{item}</span>,
    }),
);

export const createTabs = ["Main", "Adjustments"].map((item, index) => ({
    id: index,
    label: <span>{item}</span>,
}));

export const mainDetailsSettings = () => [
    {
        label: "Brand",
        key: "brand",
        inputType: "text",
        disabled: true,
    },
    {
        label: "Design",
        key: "design",
        inputType: "text",
        disabled: true,
    },
    {
        label: "Collection",
        key: "collection",
        inputType: "text",
        disabled: true,
    },

    {
        label: "Colour",
        key: "colour",
        inputType: "text",
        disabled: true,
    },
    {
        label: "Company ID",
        key: "companyId",
        inputType: "text",
        disabled: true,
    },
    {
        label: "SKU ID",
        key: "skuId",
        inputType: "text",
        disabled: true,
        width: 3,
    },
    {
        label: "Launch",
        key: "launch",
        inputType: "text",
        disabled: true,
        width: 3,
    },
];

export const metadataSettings = () => [
    {
        label: "Tile Width",
        key: "tileSizeWidth",
        inputType: "number",
        inputAddon: {
            type: "append",
            label: "mm",
        },
        default: 0,
        disabled: true,
        width: 12,
    },
    {
        label: "Tile Height",
        key: "tileSizeHeight",
        inputType: "number",
        inputAddon: {
            type: "append",
            label: "mm",
        },
        default: 0,
        disabled: true,
        width: 12,
    },
    {
        label: "Height Range",
        key: "heightRange",
        inputType: "number",
        inputAddon: {
            type: "append",
            label: "mm",
        },
        default: 0,
        disabled: true,
        width: 12,
    },
];

export const adjustmentSettings = (
    readOnly,
    overrideNoiseAddFactor,
    overrideTransparency,
    overrideDisplacement,
    noiseMaskVersion,
    useVelvetShader,
    normalNoiseMaskMultiplier,
    overrideNormalNoiseMask,
) => {
    const fields = [];

    fields.push(
        {
            label: "Exposure Correction Factor",
            key: "exposureCorrectionFactor",
            inputType: "number",
            disabled: readOnly,
            width: 5,
        },
        {
            label: overrideTransparency
                ? "Overridden Transparency"
                : "Transparency",
            key: "transparent",
            inputType: "select",
            disabled: !overrideTransparency,
            readOnly: true,
            selectOptions: [
                { value: "TRANSPARENT", label: "Transparent" },
                { value: "OPAQUE", label: "Opaque" },
            ],
            width: 5,
        },
        {
            label: "Override Transparency",
            key: "overrideTransparency",
            inputType: "checkbox",
            width: 2,
        },
    );

    if (useVelvetShader) {
        fields.push(
            {
                label: overrideNormalNoiseMask
                    ? "Overridden Normal Noise Mask Multiplier"
                    : "Normal Noise Mask Multiplier",
                key: "normalNoiseMaskMultiplier",
                inputType: "number",
                disabled: !overrideNormalNoiseMask,
                width: 5,
                offset: 5,
            },
            {
                label: "Override Normal Noise Mask",
                key: "overrideNormalNoiseMask",
                inputType: "checkbox",
                width: 2,
            },
        );
    } else {
        fields.push(
            {
                label: "Noise Mask Version",
                key: "noiseMaskVersion",
                inputType: "number",
                disabled: true,
                width: 5,
            },
            {
                label: overrideNoiseAddFactor
                    ? "Overridden Noise Add Factor"
                    : "Noise Add Factor",
                key: "noiseAddFactor",
                inputType: "number",
                disabled: !noiseMaskVersion || !overrideNoiseAddFactor,
                width: 5,
            },
            {
                label: "Override Noise Add Factor",
                key: "overrideNoiseAddFactor",
                inputType: "checkbox",
                disabled: !noiseMaskVersion,
                width: 2,
            },
        );
    }

    fields.push(
        {
            label: "Use Velvet Shader",
            key: "useVelvetShader",
            inputType: "checkbox",
            width: 5,
        },
        {
            label: overrideDisplacement
                ? "Overridden Displacement"
                : "Displacement",
            key: "displacementMultiplier",
            inputType: "number",
            min: 0,
            inputAddon: {
                type: "append",
                label: "%",
            },
            default: null,
            disabled: !overrideDisplacement,
            width: 5,
        },
        {
            label: "Override Displacement",
            key: "overrideDisplacement",
            inputType: "checkbox",
            width: 2,
        },
    );

    return fields;
};
