//@flow

import { dateTimeFormatter } from "../../../utils/formatter";
import type { VersionHistoryItem } from "../types/material-versions.d";

const Actions = {
    PUBLISH: "Publish",
    UNPUBLISH: "Unpublilsh",
    UPDATE: "Update",
    APPROVE: "Approve",
    SEND_FOR_APPROVAL: "Send for approval",
    VERIFY: "Verify",
    CREATE: "Create",
    REJECT: "Reject",
};

export default (results: VersionHistoryItem[]): VersionHistoryItem[] =>
    results.map(result => ({
        ...result,
        modifiedAt: dateTimeFormatter(result.modifiedAt),
        action: Actions[result.action] || result.action,
    }));
