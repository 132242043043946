// @flow
import { Download, Refresh } from "@brutextiles/web-component-library";

import type { Action } from "../../../templates/OverviewTable/types/action.d";

export default (
    canManage: boolean,
    canRender: boolean,
    canDownload: boolean,
    canRenderAll: boolean,
    haveItemsToDownload: boolean,
): Action[] => [
    {
        label: "Download Selected",
        action: "download",
        icon: Download,
        enable: {
            condition: (selected: any): boolean =>
                canDownload &&
                !!selected.find(row => row.status === "FULFILLED"),
        },
    },
    {
        label: "Download All",
        action: "download_all",
        icon: Download,
        show: () => canDownload,
        alwaysEnabled: canDownload && haveItemsToDownload,
    },
    {
        label: "Render Selected",
        action: "render",
        icon: Refresh,
        show: () => canManage,
        enable: {
            condition: (selected: any): boolean => {
                const statuses = getStatuses(selected);

                return (
                    canRender && statuses.size === 1 && statuses.has("TO DO")
                );
            },
        },
    },
    {
        label: "Render All",
        action: "render_all",
        icon: Refresh,
        show: () => canManage,
        alwaysEnabled: canManage && canRender && canRenderAll,
    },
    {
        label: "Re-render Selected",
        action: "rerender",
        icon: Refresh,
        show: () => canManage,
        enable: {
            condition: (selected: any): boolean => {
                const statuses = getStatuses(selected);

                return (
                    canRender && statuses.size === 1 && statuses.has("FAILED")
                );
            },
        },
    },
];

const getStatuses = (selected: any) =>
    selected.reduce((acc, row) => acc.add(row.status), new Set());
