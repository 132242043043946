//@flow
import { Icon, Pencil, Trash } from "@brutextiles/web-component-library";
import classNames from "classnames";
import React, { type Node, Fragment } from "react";
import { Button, Col, Container, Row } from "reactstrap";

import styles from "../../../../containers/ProjectPairings/subComponents/PairingRow/pairingrow.module.scss";
import ToggleButton from "../../../ToggleButton";
import style from "./style.module.scss";

type Props = {
    order: number,
    onToggle: () => void,
    isOpen: boolean,
    editMode: boolean,
    onDelete: () => void,
    onEnableEditMode: () => void,
    loading: boolean,
    children?: Node,
    leftAction?: Node,
    rightAction?: Node,
    readOnly?: boolean,
    disabled?: boolean,
};

const Header = ({
    order,
    onToggle: handleToggle,
    isOpen,
    editMode,
    onDelete: handleDelete,
    onEnableEditMode: handleEnableEditMode,
    loading,
    children,
    leftAction,
    rightAction,
    readOnly = false,
    disabled = false,
}: Props): Node => (
    <Container
        fluid
        className={classNames(style.header, disabled && styles.pairingDisabled)}
    >
        <Row className={`py-2 align-items-center`}>
            <Col xs="auto">
                <ToggleButton
                    order={order}
                    toggle={handleToggle}
                    isOpen={isOpen}
                />
            </Col>
            {children}
            {!readOnly && (
                <Fragment>
                    <Col xs="auto" className="mr-2">
                        <div className={style.actionsRow}>
                            {leftAction}
                            {!editMode && (
                                <Button
                                    color="white"
                                    outline
                                    onClick={() => {
                                        handleEnableEditMode();
                                        !isOpen && handleToggle();
                                    }}
                                >
                                    <Icon icon={Pencil} />
                                </Button>
                            )}
                            <Button
                                disabled={loading}
                                color="white"
                                outline
                                onClick={handleDelete}
                            >
                                <Icon variant="danger" icon={Trash} />
                            </Button>
                            {rightAction}
                        </div>
                    </Col>
                </Fragment>
            )}
        </Row>
    </Container>
);

export default Header;
