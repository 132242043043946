//@flow
import { useNotifications } from "@brutextiles/web-component-library";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";

import type { Pairing } from "../components/Pairing/types/pairing.d";
import { PairingType } from "../containers/ProjectPairings/types/pairing.d";

type Props = {
    apiBase: string,
};

const resolutionOptions = [
    { value: "FULL_HD", label: "Full HD" },
    { value: "FOUR_K", label: "4K" },
    { value: "SIX_K", label: "6K" },
    { value: "EIGHT_K", label: "8K" },
];

const viewResolutionOptions = [
    { value: "EIGHT_K", label: "8K" },
    { value: "SIX_K", label: "6K" },
    { value: "FOUR_K", label: "4K" },
    { value: "TWO_K", label: "2K" },
    { value: "FULL_HD", label: "Full HD" },
];

const outputFormatOptions = [
    { value: "JPEG", label: "JPEG" },
    { value: "PNG", label: "PNG" },
    { value: "TIFF", label: "TIFF" },
];

const usePairings = ({ apiBase }: Props): * => {
    const { addNotification } = useNotifications();
    const [pairings, setPairings] = useImmer([]);
    const [hasInvalidPairing, setHasInvalidPairing] = useImmer(false);
    const [materialSetOptions, setMaterialSetOptions] = useState([]);
    const [viewSetOptions, setViewSetOptions] = useState([]);
    const [totalRenders, setTotalRenders] = useState();

    const [{ loading: viewSetsLoading, data: viewSetsData }] = useAxios(
        `${apiBase}/viewSets`,
        {
            useCache: false,
        },
    );

    const [{ loading: materialSetsLoading, data: materialSetsData }] = useAxios(
        `${apiBase}/materialSets`,
        {
            useCache: false,
        },
    );

    const [{ loading: pairingsLoading, data: pairingsData }, getPairings] =
        useAxios(`${apiBase}/pairings`, { useCache: false });

    const [{ data: updatePairingData }, updatePairing] = useAxios(
        { method: "PUT" },
        { manual: true, useCache: false },
    );

    const [{ data: createPairingData }, createPairing] = useAxios(
        { url: `${apiBase}/pairing`, method: "POST" },
        { manual: true, useCache: false },
    );

    const [{ loading: isDeleting }, removePairing] = useAxios(
        { method: "DELETE" },
        { manual: true, useCache: false },
    );

    useEffect(() => {
        if (pairingsData?.pairings) {
            setPairings(() => pairingsData?.pairings);
            setTotalRenders(pairingsData.totalImageRequestDefinitionCount);
            setHasInvalidPairing(() => pairingsData?.hasInvalidPairing);
        }
    }, [pairingsData]);

    useEffect(() => {
        if (updatePairingData || createPairingData) {
            // FIXME dirty crutch, bcs backend is slow here
            setTimeout(() => getPairings(), 500);
        }
    }, [updatePairingData, createPairingData]);

    useEffect(() => {
        if (viewSetsData?.viewSets?.length) {
            setViewSetOptions(
                viewSetsData.viewSets.map(set => ({
                    value: set.viewSetId,
                    label: set.viewSetName,
                })),
            );
        }
    }, [viewSetsData]);

    useEffect(() => {
        if (materialSetsData?.materialSets?.length) {
            setMaterialSetOptions(
                materialSetsData.materialSets.map(set => ({
                    value: set.materialSetId,
                    label: set.materialSetName,
                })),
            );
        }
    }, [materialSetsData]);

    const mapCombinations = combinations =>
        combinations.map(({ combinationId, components, materialSets }) => ({
            combinationId,
            components: components.map(({ componentId, sceneVersionId }) => ({
                componentId,
                sceneVersionId,
            })),
            materialSetIds: materialSets.map(
                ({ value, materialSetId }) => value || materialSetId,
            ),
        }));

    const saveChanges = async (pairing: Pairing): Promise<boolean> => {
        try {
            // if the pairing contains a pairing id that means we should update the existing pariring
            if (pairing.pairingId) {
                await updatePairing({
                    url: `${apiBase}/pairing/${pairing.pairingId}`,
                    data: {
                        ...pairing,
                        resolution: viewResolutionOptions.find(
                            el =>
                                el.value === pairing.resolution ||
                                el.label === pairing.resolution,
                        )?.value,
                        componentsMaterialSetsCombinations: mapCombinations(
                            pairing.componentsMaterialSetsCombinations,
                        ),
                    },
                });
                addNotification({
                    type: "success",
                    body: "Pairing successfully updated",
                    autoHide: true,
                    timeout: 5000,
                });
                return true;
            } else {
                const {
                    data: { pairingId, componentsMaterialSetsCombinations },
                } = await createPairing({
                    data: {
                        ...pairing,
                        componentsMaterialSetsCombinations: mapCombinations(
                            pairing.componentsMaterialSetsCombinations,
                        ),
                    },
                });

                setPairings(draft => {
                    draft[draft.length - 1] = {
                        ...pairing,
                        pairingId,
                        componentsMaterialSetsCombinations:
                            pairing.componentsMaterialSetsCombinations.map(
                                (combination, idx) => ({
                                    ...combination,
                                    combinationId:
                                        componentsMaterialSetsCombinations[idx]
                                            .combinationId,
                                }),
                            ),
                    };
                    return draft;
                });
                return true;
            }
        } catch {
            addNotification({
                type: "danger",
                body: "Something went wrong. Please try again later",
                autoHide: true,
                timeout: 5000,
            });
            return false;
        }
    };

    const addPairing = (type): void => {
        setPairings(draft => {
            draft.push({
                editMode: true,
                singleMaterial: type === PairingType.SINGLE,
                outputFormat: "JPEG",
                componentsMaterialSetsCombinations: [
                    { components: [], materialSetIds: [] },
                ],
            });
            return draft;
        });
        if (typeof window !== "undefined") {
            window.scrollTo(0, document.body?.scrollHeight);
        }
    };

    const deletePairing = async (index: number): Promise<void> => {
        if (pairings[index].pairingId) {
            try {
                await removePairing({
                    url: `${apiBase}/pairing/${pairings[index].pairingId}`,
                });
                setPairings(draft => {
                    draft.splice(index, 1);
                    return draft;
                });
                addNotification({
                    type: "success",
                    body: "Pairing successfully deleted",
                    autoHide: true,
                    timeout: 5000,
                });
            } catch {
                addNotification({
                    type: "danger",
                    body: "Something went wrong. Please try again later",
                    autoHide: true,
                    timeout: 5000,
                });
            }
        } else {
            setPairings(draft => {
                draft.splice(index, 1);
                return draft;
            });
        }
    };

    return [
        {
            dataLoading:
                pairingsLoading || viewSetsLoading || materialSetsLoading,
            isDeleting,
            pairings: pairings.map(pairing => ({
                ...pairing,
                resolution:
                    viewResolutionOptions.find(
                        el => el.value === pairing.resolution,
                    )?.label || pairing.resolution,
            })),
            materialSetOptions,
            viewSetOptions,
            resolutionOptions,
            outputFormatOptions,
            totalRenders,
            hasInvalidPairing,
        },
        saveChanges,
        addPairing,
        deletePairing,
        getPairings,
    ];
};

export default usePairings;
