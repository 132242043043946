//@flow
import {
    Bookmark,
    Button,
    Download,
    DropdownList,
    Icon,
    Information,
    Lightbox,
    Search,
    Spinner,
    ZoomableImage,
} from "@brutextiles/web-component-library";
import React, { type Node, useState } from "react";

import defaultThumbnail from "../../images/default_render_thumbnail.jpg";
import style from "./style.module.scss";
import type { RenderEntry } from "./types/render.d";

type Props = {
    isOpen: boolean,
    showDetails: boolean,
    position: number,
    setShowDetails: boolean => void,
    renders: RenderEntry[],
    onHide: () => void,
    loading: boolean,
    fileInfoLoading: boolean,
    onPositionChange: number => void,
    showNext: boolean,
    showPrev: boolean,
    renderDetails?: (render: RenderEntry) => Node,
    onSelectDownload: (type: string, render: RenderEntry) => void,
};

const RendersGallery = ({
    isOpen,
    position,
    showDetails,
    setShowDetails,
    renders = [],
    fileInfoLoading,
    loading,
    onPositionChange: handlePositionChange,
    showNext,
    showPrev,
    onHide,
    renderDetails,
    onSelectDownload: handleSelectDownload,
}: Props): Node => (
    <div style={{ zIndex: 10000 }}>
        <Lightbox
            position={position}
            onPositionChange={newPosition => handlePositionChange(newPosition)}
            isOpen={isOpen}
            hasNext={showNext}
            hasPrev={showPrev}
            disabled={loading}
            useArrows
            onOpenChanged={onHide}
            closeOnClickOutside={false}
            footer={() => {
                const hasRender =
                    renders[position]?.originalUrl !== undefined &&
                    renders[position]?.originalUrl.trim().length > 0;
                return (
                    <RendersGalleryFooter
                        render={renders[position]}
                        showInfo={!showDetails}
                        hasRender={hasRender}
                        loading={fileInfoLoading}
                        onClickDownload={handleSelectDownload}
                        onClickShowInfo={() => setShowDetails(!showDetails)}
                    />
                );
            }}
        >
            {renders.map((render, index) => (
                <Lightbox.Slide key={index}>
                    {(showDetails || !render?.originalUrl) && (
                        <div className={style.renderDetails}>
                            {index === position &&
                                renderDetails &&
                                renderDetails(renders[position])}
                        </div>
                    )}
                    {!loading &&
                        !showDetails &&
                        isOpen &&
                        render?.originalUrl &&
                        index === position && (
                            <RenderImage
                                onClickDownload={handleSelectDownload}
                                render={render}
                            />
                        )}
                </Lightbox.Slide>
            ))}
        </Lightbox>
    </div>
);

type RenderImageProps = {
    render: RenderEntry,
    onClickDownload: (type: string, render: RenderEntry) => void,
};

const RenderImage = ({ render, onClickDownload }: RenderImageProps): Node => {
    const [hasError, setHasError] = useState();

    const url = render?.originalUrl || render?.thumbnailUrl || defaultThumbnail;

    return (
        <div className={style.imageWrapper}>
            {hasError && (
                <div className={style.imageWrapper__error}>
                    <div>
                        Preview not available, please{" "}
                        <button
                            className={style.imageWrapper__downloadButton}
                            onClick={() => onClickDownload(".jpeg", render)}
                        >
                            download
                        </button>{" "}
                        the file.
                    </div>
                </div>
            )}
            {!hasError && (
                <ZoomableImage
                    src={url}
                    maxZoom={4.0}
                    stepSize={1.0}
                    onLoad={() => setHasError(false)}
                    onError={() => setHasError(true)}
                />
            )}
        </div>
    );
};

type RendersGalleryFooterProps = {
    render: RenderEntry,
    showInfo: boolean,
    loading: boolean,
    hasRender?: boolean,
    onClickDownload: (type: string, render: RenderEntry) => void,
    onClickShowInfo: () => void,
};

const RendersGalleryFooter = ({
    render,
    loading,
    showInfo,
    hasRender,
    onClickDownload: handleClickDownload,
    onClickShowInfo: handleClickShowInfo,
}: RendersGalleryFooterProps): Node => {
    if (!hasRender) {
        return <></>;
    }
    return (
        <div className={style.sliderFooter}>
            <DropdownList
                direction={"up"}
                listItems={[
                    {
                        label: "final render",
                        value: ".jpeg",
                        icon: Bookmark,
                    },
                    {
                        label: "original render",
                        value: "RENDERING",
                        icon: Bookmark,
                    },
                    {
                        label: "denoised render",
                        value: "DENOISING",
                        icon: Bookmark,
                    },
                ]}
                onSelect={type => !loading && handleClickDownload(type, render)}
            >
                <Button className={style.footerButton} disabled={loading}>
                    {loading ? (
                        <Spinner size="sm" />
                    ) : (
                        <Icon variant="primary" icon={Download} />
                    )}
                </Button>
            </DropdownList>
            <Button
                className={style.footerButton}
                disabled={!render || loading}
                onClick={() => handleClickShowInfo()}
            >
                {showInfo ? (
                    <Icon variant="primary" icon={Information} />
                ) : (
                    <Icon variant="primary" icon={Search} />
                )}
            </Button>
        </div>
    );
};

export default RendersGallery;
