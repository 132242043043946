// @flow
import { Download, Refresh } from "@brutextiles/web-component-library";

import type { Action } from "../../templates/OverviewTable/types/action.d";

export default (canDownload: boolean): Action[] => [
    {
        label: "",
        action: "refresh",
        icon: Refresh,
        alwaysEnabled: true,
    },
    {
        label: "Download",
        action: "download",
        icon: Download,
        enable: {
            condition: (): boolean => canDownload,
        },
    },
];
