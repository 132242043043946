// @flow

import s3Client, { type Credentials } from "./s3Client";

export default (
    file: File,
    credentials: Credentials,
    callback: (loaded: number) => void,
): * => {
    const request = s3Client(credentials).upload({
        Body: file,
        Bucket: credentials.bucket,
        Key: credentials.key,
    });

    return {
        cancel: () => request.abort(),
        result: request
            .on("httpUploadProgress", ({ loaded }) => callback(loaded))
            .promise(),
    };
};
